import { useState } from "react";
import { motion } from "framer-motion";
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

const ExpandableBox = ({ heading, balance, list }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className="absolute z-[1000] top-0 right-0 bg-white shadow-sm rounded-lg p-2 md:p-4 overflow-hidden 
      w-40 md:w-48"
    >
      <div className="flex flex-col">
        <div className="text-text-dark text-[10px] md:text-xs font-medium">
          {heading}
        </div>
        <div className="flex justify-between items-center mt-2">
          <span className="text-lg md:text-base font-bold">${balance}</span>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-text-light hover:text-text-dark"
          >
            {isExpanded ? (
              <MdOutlineKeyboardArrowUp size={22} />
            ) : (
              <MdOutlineKeyboardArrowDown size={22} />
            )}
          </button>
        </div>
      </div>

      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: isExpanded ? "auto" : 0,
          opacity: isExpanded ? 1 : 0,
        }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="overflow-hidden mt-2"
      >
        {list.map((item, index) => (
          <div
            key={index}
            className="text-xs sm:text-[10px] text-gray-600 mb-1"
          >
            <span>{item.name}</span>: ${item.value ?? 0}
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default ExpandableBox;
