import React, { useEffect, useState } from "react";
import "../../InvoiceModal.css";
import { addCharge, updateInvoice } from "../../../../services/admin.services";
import AddImage from "../../../../assets/images/add-image.png";
import NewTax from "../NewCharge/NewTax";

import Snackbar from "../../../SnackBar/SnackBar";
import CircularLoader from "../../../CircularLoader/CircularLoader";
import AdditionalCharge from "../NewCharge/AdditionalCharge";

import ShipmentChargesTable from "./BillingTable";

const compareCharges = (previousCharge, newCharge) => {
  const prev = parseFloat(previousCharge) || 0;
  const current = parseFloat(newCharge) || 0;
  const difference = parseFloat(Math.abs(current - prev).toFixed(2));

  return {
    difference: difference || "-",
    type: difference === 0 ? "equal" : current < prev ? "low" : "high",
  };
};

const ChargeDifference = ({ previousCharge, newCharge }) => {
  const [type, setType] = useState("");
  const [value, setValue] = useState("-");

  useEffect(() => {
    const { type, difference } = compareCharges(previousCharge, newCharge);
    setType(type);
    setValue(difference);
  }, [previousCharge, newCharge]);

  const colorMap = { low: "tomato", high: "teal" };
  const signMap = { low: "-", high: "+" };

  return (
    <span style={{ color: colorMap[type] || "" }}>
      {signMap[type] || ""}
      {value}
    </span>
  );
};

const BillingAdmin = ({ data, setData, setShipments }) => {
  const [taxCharges, setTaxCharges] = useState([]);
  const [additionalCharges, setAdditionalCharges] = useState([]);
  const [additionalChargesData, setAdditionalChargesData] = useState([]);
  const [newCharge, setNewCharge] = useState();
  const [newChargeData, setNewChargeData] = useState({});
  const [newTaxData, setNewTaxData] = useState({});
  const [modifyInvoice, setModifyInvoice] = useState();
  const [chargeDescription, setChargeDescription] = useState();
  const [savingCharge, setSavingCharge] = useState();
  const [finalTotal, setFinalTotal] = useState({});
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [newTaxModal, setNewTaxModal] = useState();
  const [additionalChargeModal, setAdditionalChargeModal] = useState();
  const [newlyAddedCharges, setNewlyAddedCharges] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const [finaltotaldata, setfinaltotaldata] = useState(0);
  const [finaltotaldifference, setfinaltotaldifference] = useState(0);

  function checkNumber(str) {
    if (!str) return null;
    if (typeof str === "number") return str;

    str = str.trim();
    const numericStr = str.replace(/[^0-9.eE+-]/g, ""); // Keep only valid numeric characters

    return /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/.test(numericStr)
      ? parseFloat(numericStr)
      : null;
  }

  const createChargeObject = (inputArray) =>
    Object.fromEntries(
      inputArray.map(({ NameEn, Charge }) => [NameEn, Charge])
    );

  const handleChargeChange = (preVal, e) => {
    const { name, value } = e.target;
    const updatedCharge = modifyInvoice
      ? value
      : Number((checkNumber(value) + checkNumber(preVal)).toFixed(2));

    setNewChargeData((prev) => ({ ...prev, [name]: value }));
    setFinalTotal((prev) => ({ ...prev, [name]: updatedCharge }));
  };

  const handleAttachmentChange = (e) => {
    setSelectedAttachment(Array.from(e.target.files));
  };

  const handleTaxChange = (preVal, e) => {
    const { name, value } = e.target;
    setNewTaxData((prev) => ({ ...prev, [name]: value }));

    if (!modifyInvoice) {
      const taxCharge = data?.shipment_charges
        ?.at(-1)
        ?.total_updated_charges?.tax_charges?.find(
          (charge) => charge?.NameEn === name
        )?.Charge;

      setFinalTotal((prev) => ({
        ...prev,
        [name]: Number(
          (checkNumber(value) + checkNumber(taxCharge)).toFixed(2)
        ),
      }));
    } else {
      setFinalTotal((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAdditionalChargeChange = (preVal, e) => {
    const { name, value } = e.target;
    setAdditionalChargesData((prev) => ({ ...prev, [name]: value }));

    if (!modifyInvoice) {
      const taxCharge = data?.shipment_charges
        ?.at(-1)
        ?.total_updated_charges?.additional_charges?.find(
          (charge) => charge?.NameEn === name
        )?.Charge;

      setFinalTotal((prev) => ({
        ...prev,
        [name]: Number(
          (checkNumber(value) + checkNumber(taxCharge)).toFixed(2)
        ),
      }));
    } else {
      setFinalTotal((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleChargeClose = () => {
    setNewCharge(false);
    setModifyInvoice(false);
    setNewChargeData({});
    setNewTaxData({});
    setFinalTotal({});
    setChargeDescription(false);
  };

  function convertPayloadToFormData(payload) {
    const formData = new FormData();

    Object.entries(payload).forEach(([key, value]) => {
      if (key === "file") {
        if (Array.isArray(value)) {
          value.forEach((file, index) =>
            formData.append(`file[${index}]`, file)
          );
        } else if (value instanceof File) {
          formData.append("file[0]", value);
        }
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          Object.entries(item).forEach(([subKey, subValue]) => {
            formData.append(`${key}[${index}][${subKey}]`, subValue);
          });
        });
      } else if (typeof value === "object" && value !== null) {
        Object.entries(value).forEach(([subKey, subValue]) => {
          formData.append(`${key}[${subKey}]`, subValue);
        });
      } else {
        formData.append(key, value);
      }
    });

    return formData;
  }

  const handleSaveCharge = async (id) => {
    setSavingCharge("add");

    const tax_charges =
      taxCharges
        ?.filter((tax) => newTaxData[tax.NameEn])
        .map((tax) => ({ ...tax, Charge: newTaxData[tax.NameEn] })) || [];

    const additional_charges =
      additionalCharges
        ?.filter((charge) => additionalChargesData[charge.NameEn])
        .map((charge) => ({
          ...charge,
          Charge: additionalChargesData[charge.NameEn],
        })) || [];

    const response = await addCharge(
      convertPayloadToFormData({
        ...newChargeData,
        file: selectedAttachment,
        tax_charges,
        additional_charges,
      }),
      id
    );

    setSelectedAttachment(null);
    setSavingCharge(false);

    if (!response.error) {
      const updatedCharge = response.data?.result;
      setData((prevData) => ({
        ...prevData,
        Total: updatedCharge?.total,
        shipment_charges: [
          ...(prevData?.shipment_charges ?? []),
          updatedCharge,
        ],
      }));

      setShipments((prevShipments) => ({
        ...prevShipments,
        data: prevShipments?.data?.map((shipment) =>
          shipment.id === data.id
            ? {
                ...shipment,
                Total: updatedCharge?.total,
                shipment_charges: [
                  ...(shipment?.shipment_charges ?? []),
                  updatedCharge,
                ],
              }
            : shipment
        ),
      }));

      setSnack((prevSnack) => ({
        ...prevSnack,
        open: true,
        text: "Charge Added Successfully",
        severity: "success",
      }));

      handleChargeClose();
    } else {
      setSnack((prevSnack) => ({
        ...prevSnack,
        open: true,
        text: "Something went wrong",
        severity: "error",
      }));
    }
  };

  const handleUpdateCharge = async (id) => {
    setSavingCharge("update");

    const tax_charges =
      taxCharges
        ?.filter((tax) => newTaxData[tax.NameEn])
        .map((tax) => ({ ...tax, Charge: newTaxData[tax.NameEn] })) || [];

    const payload = {
      base_price: 0,
      fuel_charge: 0,
      freight_charge: 0,
      sub_total: 0,
      PickupCharge: 0,
      residential_charge: 0,
      signature_charge: 0,
      description: "",
      additional_price: 0,
      file: selectedAttachment,
      ...newChargeData,
    };

    const response = await updateInvoice(
      convertPayloadToFormData({ ...payload, tax_charges }),
      id
    );

    setSelectedAttachment(null);
    setSavingCharge(false);

    if (!response.error) {
      const updatedCharge = response?.data?.result;

      setData((prevData) => ({
        ...prevData,
        Total: updatedCharge?.total,
        shipment_charges: [
          ...(prevData?.shipment_charges ?? []),
          updatedCharge,
        ],
      }));

      setShipments((prevShipments) => ({
        ...prevShipments,
        data: prevShipments?.data?.map((shipment) =>
          shipment.id === data.id
            ? {
                ...shipment,
                Total: updatedCharge?.total,
                shipment_charges: [
                  ...(shipment?.shipment_charges ?? []),
                  updatedCharge,
                ],
              }
            : shipment
        ),
      }));

      setSnack((prevSnack) => ({
        ...prevSnack,
        open: true,
        text: "Invoice Updated Successfully",
        severity: "success",
      }));

      handleChargeClose();
    } else {
      setSnack((prevSnack) => ({
        ...prevSnack,
        open: true,
        text: "Something went wrong",
        severity: "error",
      }));
    }
  };

  const findChargeByNameEn = (nameEn, index, type) => {
    const charges = data?.shipment_charges[index]?.[type];
    return charges?.find((charge) => charge?.NameEn === nameEn) || false;
  };

  const findTaxChargeByNameEn = (nameEn, index) =>
    findChargeByNameEn(nameEn, index, "tax_charges");

  const findAdditionalChargeByNameEn = (nameEn, index) =>
    findChargeByNameEn(nameEn, index, "additional_charges");

  useEffect(() => {
    const getUniqueCharges = (type) =>
      data?.shipment_charges
        ?.flatMap((item) => item?.[type] || [])
        ?.filter(
          (charge, index, self) =>
            charge &&
            index === self.findIndex((c) => c?.NameEn === charge?.NameEn)
        );

    setTaxCharges(getUniqueCharges("tax_charges"));
    setAdditionalCharges(getUniqueCharges("additional_charges"));
  }, [data]);

  useEffect(() => {
    const Size = data?.shipment_charges?.length || 0;
    const prevTotal =
      Size > 0 ? parseFloat(data?.shipment_charges[Size - 1]?.total) || 0 : 0;

    const calculateTotal = (dataObj) =>
      Object.entries(dataObj || {}).reduce(
        (sum, [key, value]) =>
          key !== "description" && !isNaN(parseFloat(value)) && value !== ""
            ? sum + parseFloat(value)
            : sum,
        0
      );

    const total =
      calculateTotal(newChargeData) +
      calculateTotal(newTaxData) +
      calculateTotal(additionalChargesData);

    setfinaltotaldifference(total);
    setfinaltotaldata(total + prevTotal);
  }, [
    finalTotal,
    newChargeData,
    newCharge,
    data,
    newTaxData,
    additionalChargesData,
  ]);

  const calculateServiceChargePercentage = (total = 0, serviceCharge = 0) => {
    const totalFloat = parseFloat(total) || 0;
    const serviceChargeFloat = parseFloat(serviceCharge) || 0;

    const originalPrice = totalFloat - serviceChargeFloat;
    return originalPrice > 0
      ? ((serviceChargeFloat / originalPrice) * 100).toFixed(1)
      : "0.0";
  };

  return (
    <div className="invoice__billing_admin_wrap">
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <NewTax
        open={newTaxModal}
        setOpen={setNewTaxModal}
        taxCharges={taxCharges}
        setTaxCharges={setTaxCharges}
        setNewTaxData={setNewTaxData}
        setFinalTotal={setFinalTotal}
        setNewlyAddedCharges={setNewlyAddedCharges}
      />
      <AdditionalCharge
        open={additionalChargeModal}
        setOpen={setAdditionalChargeModal}
        additionalCharges={additionalCharges}
        setAdditionalCharges={setAdditionalCharges}
        setAdditionalChargesData={setAdditionalChargesData}
        setFinalTotal={setFinalTotal}
        setNewlyAddedCharges={setNewlyAddedCharges}
      />

      <div className="invmodal__shipment_detailshding">
        Billing information{" "}
        <div className="flex gap-2 my-1">
          {modifyInvoice || newCharge ? (
            <span
              className="px-2 py-1 text-[10px] md:text-xxs lg:text-xs xl:text-sm font-medium text-white bg-red-500 rounded cursor-pointer hover:bg-red-600"
              onClick={() => {
                if (newCharge) {
                  setTaxCharges((charges) =>
                    charges?.filter(
                      (charge) => !newlyAddedCharges.includes(charge?.Id)
                    )
                  );
                  setAdditionalCharges((charges) =>
                    charges?.filter(
                      (charge) => !newlyAddedCharges.includes(charge?.Id)
                    )
                  );
                  setNewChargeData({});
                  setNewlyAddedCharges([]);
                }
                handleChargeClose();
              }}
            >
              Cancel
            </span>
          ) : (
            <>
              <span
                className="px-2 py-1 text-xxs text-white bg-blue-500 rounded cursor-pointer hover:bg-blue-600"
                onClick={() => {
                  setModifyInvoice({
                    data: data?.shipment_charges[
                      data?.shipment_charges?.length - 1
                    ],
                    index: data?.shipment_charges?.length - 1,
                  });

                  setChargeDescription(
                    data?.shipment_charges[data?.shipment_charges?.length - 1]
                      ?.id
                  );
                  setNewChargeData(
                    data?.shipment_charges[data?.shipment_charges?.length - 1]
                      ?.total_updated_charges
                  );
                  setFinalTotal({
                    ...data?.shipment_charges[
                      data?.shipment_charges?.length - 1
                    ]?.total_updated_charges,
                    ...createChargeObject(
                      data?.shipment_charges[data?.shipment_charges?.length - 1]
                        ?.total_updated_charges?.tax_charges
                    ),
                    ...createChargeObject(
                      data?.shipment_charges[data?.shipment_charges?.length - 1]
                        ?.total_updated_charges?.additional_charges
                    ),
                  });
                  setNewTaxData({
                    ...createChargeObject(
                      data?.shipment_charges[data?.shipment_charges?.length - 1]
                        ?.total_updated_charges?.tax_charges
                    ),
                    ...createChargeObject(
                      data?.shipment_charges[data?.shipment_charges?.length - 1]
                        ?.total_updated_charges?.additional_charges
                    ),
                  });
                }}
              >
                Modify Invoice
              </span>
              <span
                className="px-2 py-1 text-xxs text-white bg-green-500 rounded cursor-pointer hover:bg-green-600"
                onClick={() => {
                  setChargeDescription(data?.id);
                  setNewCharge({
                    data: data?.shipment_charges[
                      data?.shipment_charges?.length - 1
                    ]?.total_updated_charges,
                    index: data?.shipment_charges?.length - 1,
                  });
                }}
              >
                Add Charge
              </span>
            </>
          )}
        </div>
      </div>

      <ShipmentChargesTable
        data={data}
        taxCharges={taxCharges}
        additionalCharges={additionalCharges}
        newCharge={newCharge}
        setNewTaxModal={setNewTaxModal}
        setAdditionalChargeModal={setAdditionalChargeModal}
        ChargeDifference={ChargeDifference}
        newChargeData={newChargeData}
        newTaxData={newTaxData}
        additionalChargesData={additionalChargesData}
        finaltotaldifference={finaltotaldifference}
        handleChargeChange={handleChargeChange}
        handleAdditionalChargeChange={handleAdditionalChargeChange}
        finalTotal={finalTotal}
        finaltotaldata={finaltotaldata}
        handleTaxChange={handleTaxChange}
        modifyInvoice={modifyInvoice}
        findTaxChargeByNameEn={findTaxChargeByNameEn}
        findAdditionalChargeByNameEn={findAdditionalChargeByNameEn}
        calculateServiceChargePercentage={calculateServiceChargePercentage}
      />

      {newCharge && chargeDescription && (
        <>
          <div className="update__charge_reasonbox">
            <div className="update__charge_reasonboxlft">
              <textarea
                className="invmodal__charge_desc"
                placeholder="Write a Note about updated charge."
                name="description"
                onChange={(e) => {
                  setNewChargeData({
                    ...newChargeData,
                    description: e.target.value,
                  });
                }}
                value={newChargeData?.description ?? ""}
              ></textarea>
            </div>
            <div className="update__charge_reasonboxrt">
              <label
                for="update_charge_attachment"
                className="update_charge_attachment"
              >
                <input
                  type="file"
                  multiple
                  hidden
                  id="update_charge_attachment"
                  onChange={handleAttachmentChange}
                />
                <img src={AddImage} className="update_charge_atchmentimg" />
                <span>
                  {selectedAttachment?.length > 0
                    ? `${selectedAttachment?.length} file${
                        selectedAttachment?.length > 1 ? "s" : ""
                      } selected`
                    : "Upload Attachment For Client"}
                </span>
              </label>
            </div>
          </div>
          <div className="update__charge_rblftbtns">
            <button
              className="update__charge_rblftbtncncl"
              onClick={() => handleChargeClose()}
            >
              Cancel
            </button>
            <button
              className="update__charge_rblftbtnsave"
              onClick={() => handleSaveCharge(chargeDescription)}
            >
              {savingCharge === "add" ? (
                <CircularLoader size={16} color="grey" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </>
      )}
      {modifyInvoice && chargeDescription && (
        <>
          <div className="update__charge_reasonbox">
            <div className="update__charge_reasonboxlft">
              <textarea
                className="invmodal__charge_desc"
                placeholder="Write a Note about updated charge."
                name="description"
                onChange={(e) => {
                  setNewChargeData({
                    ...newChargeData,
                    description: e.target.value,
                  });
                }}
                value={newChargeData?.description ?? ""}
              ></textarea>
            </div>
            <div className="update__charge_reasonboxrt">
              <label
                for="update_charge_attachment"
                className="update_charge_attachment"
              >
                <input
                  type="file"
                  multiple
                  hidden
                  id="update_charge_attachment"
                  onChange={handleAttachmentChange}
                />
                <img src={AddImage} className="update_charge_atchmentimg" />
                <span>
                  {selectedAttachment?.length > 0
                    ? `${selectedAttachment?.length} file${
                        selectedAttachment?.length > 1 ? "s" : ""
                      } selected`
                    : "Upload Attachment"}
                </span>
              </label>
            </div>
          </div>
          <div className="update__charge_rblftbtns">
            <button
              className="update__charge_rblftbtncncl"
              onClick={() => handleChargeClose()}
            >
              Cancel
            </button>
            <button
              className="update__charge_rblftbtnsave"
              onClick={() => handleUpdateCharge(chargeDescription)}
            >
              {savingCharge === "update" ? (
                <CircularLoader size={16} color="grey" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default BillingAdmin;
