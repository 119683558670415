import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice.js";
import snackBarReducer from "./common/snackBarSlice.js";
import uiReducer from "./common/uiSlice.js";

const store = configureStore({
  reducer: {
    user: userReducer,
    snackBar: snackBarReducer,
    ui: uiReducer
  },
  devTools: true,
});
export default store;
