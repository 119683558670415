import React, { useRef, useState } from "react";
import "./AddProduct.css";
import { BiArrowBack } from "react-icons/bi";
import InfoSection from "./Sections/InfoSection";
import MediaSection from "./Sections/MediaSection";
import PricingSection from "./Sections/PricingSection";
import VariantSection from "./Sections/VariantsSection";
import TableOfContent from "../Components/TableOfContent/TableOfContent";
import StatusSection from "./Sections/StatusSection";
import CategorySection from "./Sections/CategorySection";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IdentifiersSection from "./Sections/IdentifiersSection";
import DimensionsSection from "./Sections/DimensionsSection";
import { useEffect } from "react";
import ComboSection from "./Sections/ComboSection";
import { useSelector } from "react-redux";
import {
  fetchRawProductData,
  updateProduct,
} from "../../../services/product.service";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "../../../Components/SnackBar/SnackBar";
const initialContentList = [
  {
    heading: "Table of Content",
    menus: [
      {
        name: "Basic Information",
        id: "addproduct_basic_info",
      },
      {
        name: "Product's Media",
        id: "addproduct_media",
      },
      {
        name: "Product's Pricing",
        id: "addproduct_pricing",
      },
      {
        name: "Dimensions",
        id: "addproduct_dimensions",
      },
      {
        name: "Identifiers",
        id: "addproduct_identifiers",
      },
      {
        name: "Product's Combo",
        id: "addproduct_combosection",
      },
      {
        name: "Variants",
        id: "addproduct_variants",
      },
    ],
  },
];
const marketplaces = [
  { id: 1, title: "Amazon" },
  { id: 2, title: "Woocommerce" },
  { id: 3, title: "Ebay" },
  { id: 4, title: "Walmart" },
  { id: 5, title: "Shopify" },
  { id: 6, title: "Best Buy" },
];
const initialProductIdentifier = { marketplace: "", sku: "", slug: "" };
const EditProduct = () => {
  const [attributes, setAttributes] = useState([]);
  const [variationAttributes, setVarationAttributes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productBrands, setProductBrands] = useState([]);
  const [productSuppliers, setProductSuppliers] = useState([]);
  const [variants, setVariants] = useState();
  const [contentList, setContentList] = useState(initialContentList);
  const [errors, setErrors] = useState({});
  const [savingProduct, setSavingProduct] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [packageDetails, setPackageDetails] = useState([
    { length: "", width: "", height: "", weight: "", description: "" },
  ]);
  const [productIdentifiers, setProductIdentifiers] = useState([
    initialProductIdentifier,
  ]);
  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [supplierSections, setSupplierSections] = useState([]);
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = useState({});

  function transformMediaObject(inputObject) {
    const transformedObject = { ...inputObject };

    delete transformedObject.created_at;
    delete transformedObject.updated_at;
    delete transformedObject.product_id;

    for (const key in transformedObject) {
      if (transformedObject[key] === "true") {
        transformedObject[key] = true;
      } else if (transformedObject[key] === "false") {
        transformedObject[key] = false;
      }
    }

    return transformedObject;
  }

  function navigateToSection(data) {
    let sectionId = null;

    if (data.title || data.description) {
      sectionId = "addproduct_basic_info";
    } else if (data.regular_price || data.sales_price) {
      sectionId = "addproduct_pricing";
    } else if (
      data.length ||
      data.width ||
      data.height ||
      data.weight ||
      data.unit
    ) {
      sectionId = "addproduct_dimensions";
    } else if (data.category || data.sub_category || data.type) {
      sectionId = "addproduct_basic_category";
    } else if (data.status) {
      sectionId = "addproduct_basic_status";
    } else if (data.warehouse_ids) {
      sectionId = "addproduct_basic_category";
    }

    if (sectionId) {
      // Change the URL hash to navigate to the desired section
      window.location.hash = sectionId;
    }

    return sectionId;
  }
  const fieldsToCheck = [
    "title",
    "code",
    "category",
    "brand",
    "dimension_unit",
    "weight_unit",
    "media",
    "regular_price",
    "warehouse_ids",
    "sku",
  ];
  // function checkEmptyFields(inputObject, fieldsToValidate) {
  //   const emptyFields = {};

  //   function checkFieldsRecursive(obj, path = "") {
  //     for (const key in obj) {
  //       const fieldPath = path ? `${path}.${key}` : key;
  //       const value = obj[key];

  //       // Check if the current path is in the list of fields to validate
  //       if (fieldsToValidate.includes(fieldPath)) {
  //         if (
  //           value === null ||
  //           value === undefined ||
  //           value === "" ||
  //           value?.length === 0
  //         ) {
  //           emptyFields[fieldPath] = {
  //             msg: `Field is required.`,
  //           };
  //         }
  //       }

  //       // Uncomment and update the following if nested object validation is needed
  //       // else if (typeof value === "object" && !Array.isArray(value) && value !== null) {
  //       //   checkFieldsRecursive(value, fieldPath);
  //       // }
  //     }
  //   }

  //   checkFieldsRecursive(inputObject);
  //   navigateToSection(emptyFields); // Assuming this function is defined elsewhere
  //   setErrors(emptyFields); // Assuming this function is defined elsewhere
  //   if (Object.keys(emptyFields).length > 0) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }
  function checkEmptyFields(inputObject, fieldsToValidate) {
    const emptyFields = {};

    function checkFieldsRecursive(obj, path = "") {
      for (const key in obj) {
        const fieldPath = path ? `${path}.${key}` : key;
        const value = obj[key];

        // Check if the current path is in the list of fields to validate
        if (fieldsToValidate.includes(fieldPath)) {
          if (
            value === null ||
            value === undefined ||
            value === "" ||
            value?.length === 0
          ) {
            emptyFields[fieldPath] = {
              msg: `Field is required.`,
            };
          }
        }

        // Additional check for children array
        if (key === "children" && Array.isArray(value)) {
          value.forEach((child, index) => {
            const childPath = `${fieldPath}[${index}]`;
            if (!child.code) {
              emptyFields[`${childPath}.code`] = {
                msg: "SKU is required for each variant.",
              };
            }
            // Recursively check the children
            checkFieldsRecursive(child, childPath);
          });
        } else if (typeof value === "object" && value !== null) {
          // Recursively check other nested objects
          checkFieldsRecursive(value, fieldPath);
        }
      }
    }

    checkFieldsRecursive(inputObject);
    navigateToSection(emptyFields); // Assuming this function is defined elsewhere
    setErrors(emptyFields); // Assuming this function is defined elsewhere
    if (Object.keys(emptyFields).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  const createFormData = (payload) => {
    const formData = new FormData();

    const replaceUndefinedWithEmptyString = (value) => {
      return value === undefined ? "" : value;
    };

    const appendToFormData = (key, value) => {
      value = replaceUndefinedWithEmptyString(value);

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (typeof item === "object") {
            for (const itemKey in item) {
              formData.append(
                `${key}[${index}][${itemKey}]`,
                replaceUndefinedWithEmptyString(item[itemKey])
              );
            }
          } else {
            formData.append(
              `${key}[${index}]`,
              replaceUndefinedWithEmptyString(item)
            );
          }
        });
      } else if (typeof value === "object" && value !== null) {
        for (const nestedKey in value) {
          formData.append(
            `${key}[${nestedKey}]`,
            replaceUndefinedWithEmptyString(value[nestedKey])
          );
        }
      } else {
        formData.append(key, value);
      }
    };

    for (const key in payload) {
      if (key === "product_variations") {
        payload[key].forEach((variation, index) => {
          for (const variationKey in variation) {
            appendToFormData(
              `product_variations[${index}][${variationKey}]`,
              variation[variationKey]
            );
          }
        });
      } else {
        appendToFormData(key, payload[key]);
      }
    }

    return formData;
  };

  const handleSaveProduct = async () => {
    let valid =
      formData?.variants?.length < 1
        ? checkEmptyFields(formData, fieldsToCheck)
        : true;
    if (formData?.variants?.length > 0) {
      let variantError = false;
      formData?.variants.forEach((variant) => {
        if (["", null, undefined].includes(variant?.code)) {
          variantError = "Variants SKU is Required";
        }
      });
      if (variantError) {
        setSnack({
          ...snack,
          open: true,
          text: variantError || "Something went wrong",
          severity: "error",
          vertical: "top",
          horizontal: "right",
        });
        return;
      }
    }

    if (!valid) {
      return;
    }
    const payload = {
      product_sku: formData?.sku,
      code: formData?.code,
      name: formData?.title,
      description: formData?.description,
      product_variation_attribute_id: "null",
      full_name: formData?.title,
      category_id: formData?.category?.value ?? "",
      subcategory_id: formData?.sub_category?.value ?? "",
      type: formData?.type?.value ?? "",
      length: formData?.length,
      width: formData?.width,
      height: formData?.height,
      weight: formData?.weight,
      // warehouse_ids: JSON.stringify(formData?.warehouse_ids),
      warehouse_ids: formData?.warehouse_ids,
      product_type_id: formData?.type?.value ?? "",
      product_unit_id: "",
      brand_id: formData?.brand?.value ?? "",
      regular_price: formData?.regular_price,
      sale_price: formData?.sales_price,
      deletedVariationIds: formData?.deletedVariationIds,
      deletedAttributeIds: formData?.deletedAttributeIds,
      currency: formData?.currency,
      additional_information: formData?.additional_information,
      deletedMediaIds: formData?.deletedMediaIds ?? [],
      deletedSuppliersIds: formData?.deletedSuppliersIds ?? [],
      dimension_unit: formData?.dimension_unit?.value ?? "",
      package_details: JSON.stringify(formData?.package_details),
      weight_unit: formData?.weight_unit?.value ?? "",
      fulfillment_cost: "",
      has_variants: "true",
      attributes,
      product_identifiers: formData?.identifiers?.map((identifier) => {
        return {
          market_place_id: identifier.marketplace.id,
          sku: identifier.sku,
          slug: identifier.slug,
        };
      }),
      media: formData?.media?.map((image) => {
        return transformMediaObject(image);
      }),
      product_suppliers: formData?.suppliers?.map((supplier) => {
        return {
          cost: supplier.cost,
          supplier_id: supplier.supplier?.value ?? "",
        };
      }),
      product_combo: [],
      has_variants: `${formData.variants?.length > 0}`,
      product_variations:
        formData.variants?.length > 0
          ? formData.variants?.map((variant) => {
              return {
                code: variant?.code,
                product_sku: variant?.sku,
                id: variant?.id ?? "",
                name: variant.variant,
                full_name: `${formData.title ? formData.title + " - " : ""}${
                  variant.variant
                }`,
                category_id: formData?.category?.value,
                subcategory_id: "",
                length: variant.length,
                width: variant.width,
                height: variant.height,
                weight: variant.weight,
                dimension_unit:
                  variant.dimension_unit?.value ??
                  formData?.dimension_unit?.value,
                deletedMediaIds: variant?.deletedMediaIds ?? [],
                deletedSuppliersIds: variant?.deletedSuppliersIds ?? [],
                weight_unit:
                  variant?.weight_unit?.value ?? formData?.weight_unit?.value,
                // warehouse_ids: JSON.stringify(formData?.warehouse_ids),
                warehouse_ids: formData?.warehouse_ids,
                product_type_id: "",
                product_unit_id: "",
                brand_id: formData?.brand?.value ?? "",
                regular_price: variant.regular_price,
                sale_price: variant.sales_price,
                fulfillment_cost: "",
                product_identifiers: variant?.product_identifiers?.map(
                  (identifier) => {
                    return {
                      market_place_id: identifier?.marketplace?.id,
                      sku: identifier.sku,
                      slug: identifier.slug,
                    };
                  }
                ),
                media: variant?.media?.map((image) => {
                  return transformMediaObject(image);
                }),
                product_suppliers: variant?.suppliers?.map((supplier) => {
                  return {
                    cost: supplier.unit_cost,
                    supplier_id: supplier.supplier?.value,
                  };
                }),
              };
            })
          : [],
    };
    // console.log(payload);
    const formDataPayload = createFormData(payload);
    try {
      setSavingProduct(true);
      const response = await updateProduct(formDataPayload, formData?.id);
      if (!response?.error) {
        navigate("/products");
        setSnack({
          ...snack,
          open: true,
          text: "Product Updated Successfully",
          severity: "success",
          vertical: "top",
          horizontal: "right",
        });
        navigate("/products");
        setSavingProduct(false);
      } else {
        setSavingProduct(false);
        setSnack({
          ...snack,
          open: true,
          text:
            response?.error?.response?.data?.message || "Something went wrong",
          severity: "error",
          vertical: "top",
          horizontal: "right",
        });
      }
    } catch (error) {
      setSnack({
        ...snack,
        open: true,
        text: "Something went wrong",
        severity: "error",
        vertical: "top",
        horizontal: "right",
      });
    }
  };

  const fetchInitialData = async () => {
    try {
      const response = await fetchRawProductData();
      if (!response.error) {
        setVarationAttributes(
          response?.data?.result?.allVariations?.map((attr) => {
            return { value: attr.id, title: attr.name };
          })
        );
        setCategories(
          response?.data?.result?.categories?.map((category) => {
            return { value: category.id, title: category.name };
          })
        );
        setProductTypes(
          response?.data?.result?.productTypes?.map((type) => {
            return { value: type.id, title: type.name };
          })
        );
        setProductBrands(
          response?.data?.result?.brands?.map((brand) => {
            return { value: brand.id, title: brand.name };
          })
        );
        setProductSuppliers(
          response?.data?.result?.suppliers?.map((supplier) => {
            return { value: supplier.id, title: supplier.name };
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    if (formData?.type?.id === 2) {
      setContentList(initialContentList);
    } else {
      setContentList((prevList) => {
        const menus = prevList[0].menus?.filter(
          (item) => item?.id !== "addproduct_combosection"
        );
        return [
          {
            ...prevList[0],
            menus,
          },
        ];
      });
    }
  }, [formData?.type]);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const previousData = location.state;
  useEffect(() => {
    const previousData = location.state;
    if (previousData) {
      setFormData({
        sku: previousData?.product_sku ?? "",
        id: previousData?.id,
        category: {
          value: previousData?.category?.id,
          title: previousData?.category?.name,
        },
        attributes: [],
        code: previousData?.code ?? "",
        title: previousData?.name ?? "",
        description: previousData?.description ?? "",
        media: previousData?.media ?? [],
        main_image: {},
        regular_price: previousData?.regular_price ?? 0,
        sales_price: previousData?.sale_price ?? 0,
        suppliers: [],
        length: previousData?.length ?? "",
        width: previousData?.width ?? "",
        height: previousData?.height ?? "",
        weight: previousData?.weight ?? "",
        additional_information: previousData?.additional_informations ?? "",
        deletedMediaIds: [],
        deletedSuppliersIds: "",
        product_identifiers: [],
        status: previousData?.status ?? "",
        type: previousData?.type
          ? { title: previousData?.type?.name, value: previousData?.type?.id }
          : "",
        brand: {
          value: previousData?.brand?.id,
          title: previousData?.brand?.name,
        },
        currency: "cad",
        warehouse_ids: (previousData?.product_warehouse || []).flatMap(
          (warehouse) => {
            const warehouseId = warehouse?.warehouse_id;

            if (typeof warehouseId === "string") {
              return warehouseId.split(",");
            } else if (
              typeof warehouseId === "number" ||
              typeof warehouseId === "string"
            ) {
              return [String(warehouseId)];
            }

            return [];
          }
        ),
        totalQuantity: (previousData?.product_warehouse || []).reduce(
          (total, warehouse) => total + (parseInt(warehouse?.quantity) || 0),
          0
        ),
        package_details: previousData?.package_details
          ? Object.values(previousData?.package_details)
          : [],
        dimension_unit: previousData?.dimension_unit
          ? {
              title:
                previousData?.dimension_unit === "in"
                  ? "Inch (in)"
                  : "Centemeter (cm)",
              value: previousData?.dimension_unit,
            }
          : "",
        weight_unit: previousData?.weight_unit
          ? {
              title:
                previousData?.weight_unit === "kg"
                  ? "Kilogram (kg)"
                  : "Pound (lb)",
              value: previousData?.weight_unit,
            }
          : "",
        variants: previousData?.children?.map((variant) => {
          return {
            category: {
              value: previousData?.category?.id,
              title: previousData?.category?.name,
            },
            id: variant?.id ?? "",
            code: variant?.code ?? "",
            sku: variant?.product_sku ?? "",
            attributes: [],
            title: variant?.name ?? "",
            variant: variant?.name ?? "",
            description: variant?.description ?? "",
            media: variant?.media ?? [],
            regular_price: variant?.regular_price ?? 0,
            sales_price: variant?.sale_price ?? 0,
            suppliers: [],
            length: variant?.length ?? "",
            width: variant?.width ?? "",
            height: variant?.height ?? "",
            weight: variant?.weight ?? "",
            deletedMediaIds: [],
            product_identifiers: variant?.product_skus
              ? variant?.product_skus?.map((ident) => {
                  return {
                    ...ident,
                    marketplace: marketplaces.find(
                      (item) => `${item.id}` === ident.market_place_id
                    ),
                  };
                })
              : [],
            status: variant?.status ?? "",
            type: variant?.type ?? "",
            // brand: variant?.brand ?? "",
            brand: {
              value: variant?.brand?.id,
              title: variant?.brand?.name,
            },
            currency: "cad",
            dimension_unit: variant?.dimension_unit
              ? {
                  title:
                    variant?.dimension_unit === "in"
                      ? "Inch (in)"
                      : "Centemeter (cm)",
                  value: variant?.dimension_unit,
                }
              : "",
            weight_unit: variant?.weight_unit
              ? {
                  title:
                    variant?.weight_unit === "kg"
                      ? "Kilogram (kg)"
                      : "Pound (lb)",
                  value: variant?.weight_unit,
                }
              : "",
          };
        }),
      });
      setVariants(
        previousData?.children?.map((variant) => {
          return {
            category: {
              value: previousData?.category?.id,
              title: previousData?.category?.name,
            },
            id: variant?.id ?? "",
            attributes: [],
            title: variant?.name ?? "",
            code: variant?.code ?? "",
            sku: variant?.product_sku ?? "",
            variant: variant?.name ?? "",
            description: variant?.description ?? "",
            media: variant?.media ?? [],
            regular_price: variant?.regular_price ?? 0,
            sales_price: variant?.sale_price ?? 0,
            suppliers: [],
            length: variant?.length ?? "",
            width: variant?.width ?? "",
            height: variant?.height ?? "",
            weight: variant?.weight ?? "",
            deletedMediaIds: [],
            product_identifiers: variant?.product_skus
              ? variant?.product_skus?.map((ident) => {
                  return {
                    ...ident,
                    marketplace: marketplaces.find(
                      (item) => `${item.id}` === ident.market_place_id
                    ),
                  };
                })
              : [],
            status: variant?.status ?? "",
            type: variant?.type ?? "",
            // brand: variant?.brand ?? "",
            brand: {
              value: variant?.brand?.id,
              title: variant?.brand?.name,
            },
            currency: "cad",
            dimension_unit: variant?.dimension_unit
              ? {
                  title:
                    variant?.dimension_unit === "in"
                      ? "Inch (in)"
                      : "Centemeter (cm)",
                  value: variant?.dimension_unit,
                }
              : "",
            weight_unit: variant?.weight_unit
              ? {
                  title:
                    variant?.weight_unit === "kg"
                      ? "Kilogram (kg)"
                      : "Pound (lb)",
                  value: variant?.weight_unit,
                }
              : "",
          };
        })
      );
      setPackageDetails(
        previousData?.package_details
          ? Object.values(previousData?.package_details)
          : []
      );
      previousData?.attributes &&
        setAttributes(
          previousData?.attributes?.map((attribute) => {
            return {
              ...attribute,
              value: attribute?.value.split(","),
            };
          })
        );
      previousData?.category &&
        setSelectedCategory({
          title: previousData?.category?.name,
          value: previousData?.category?.id,
        });
      previousData?.brand &&
        setSelectedBrand({
          title: previousData?.brand?.name,
          value: previousData?.brand?.id,
        });
      previousData?.subcategory &&
        setSelectedSubCategory({
          title: previousData?.subcategory?.name,
          value: previousData?.subcategory?.id,
        });
      previousData?.type &&
        setSelectedType({
          title: previousData?.type?.name,
          value: previousData?.type?.id,
        });
      setUploadedImages(previousData?.media ?? []);
      if (previousData?.product_skus?.length > 0) {
        setProductIdentifiers(
          previousData?.product_skus
            ? previousData?.product_skus?.map((ident) => {
                return {
                  ...ident,
                  marketplace: marketplaces.find(
                    (item) => `${item.id}` === ident.market_place_id
                  ),
                };
              })
            : []
        );
      }
      setSupplierSections(
        previousData?.product_suppliers?.map((supplier) => {
          return {
            supplier: {
              value: supplier.suppliers?.id,
              title: supplier.suppliers?.name,
            },
            cost: supplier.cost,
            profit: "",
            margin: "",
          };
        })
      );
      setAdditionalFields(previousData?.additional_informations);
    }
  }, [location]);

  return (
    <div className="add__prod_screen">
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <div className="add__prod_screenleft">
        <TableOfContent contentList={contentList} />
      </div>
      <div className="add__prod_screencenter">
        <div className="add__prod_sheader hide_mobile_btn">
          <Link
            to={user.role == "Shipper" ? "/products" : "/warehouse-products"}
          >
            <BiArrowBack size={22} className="add__prod_sheaderback" />
          </Link>
          Edit product
        </div>
        <InfoSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          additionalFields={additionalFields}
          setAdditionalFields={setAdditionalFields}
          editMode={true}
        />
        <MediaSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          uploadedImages={uploadedImages}
          setUploadedImages={setUploadedImages}
        />
        <PricingSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          productSuppliers={productSuppliers}
          setSupplierSections={setSupplierSections}
          supplierSections={supplierSections}
        />
        <DimensionsSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          packageDetails={packageDetails}
          setPackageDetails={setPackageDetails}
        />
        <IdentifiersSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          productIdentifiers={productIdentifiers}
          setProductIdentifiers={setProductIdentifiers}
        />
        {formData?.type?.title?.toLowerCase() === "combo" && (
          <ComboSection
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
          />
        )}

        {!previousData?.parent_id && (
          <VariantSection
            attributes={attributes}
            setAttributes={setAttributes}
            variants={variants}
            setVariants={setVariants}
            formData={formData}
            setFormData={setFormData}
            variationAttributes={variationAttributes}
            editMode={true}
            setSnack={setSnack}
          />
        )}
        <div className="add__prod_bottomsec">
          <button className="add__prod_saveprodbtn" onClick={handleSaveProduct}>
            {!savingProduct ? (
              "Save"
            ) : (
              <CircularProgress color="inherit" size={16} />
            )}
          </button>
        </div>
      </div>
      <div className="add__prod_screenright">
        <div className="add__prod_sheader show_mobile_btn">
          <Link
            to={user.role == "Shipper" ? "/products" : "/warehouse-products"}
          >
            <BiArrowBack size={22} className="add__prod_sheaderback" />
          </Link>
          Edit product
        </div>
        <StatusSection
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />
        <CategorySection
          formData={formData}
          productTypes={productTypes}
          productBrands={productBrands}
          setProductBrands={setProductBrands}
          categories={categories}
          setCategories={setCategories}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedSubCategory={selectedSubCategory}
          setSelectedSubCategory={setSelectedSubCategory}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          selectedBrand={selectedBrand}
          setSelectedBrand={setSelectedBrand}
          setSnack={setSnack}
        />
      </div>
    </div>
  );
};

export default EditProduct;
