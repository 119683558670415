import React, { useEffect, useState } from "react";
import "./Sections.css";
import { MdAddCircleOutline, MdErrorOutline } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import CustomDropdown from "../Components/CustomDropdown/CustomDropdown";

const PricingSection = ({
  formData,
  setFormData,
  errors,
  productSuppliers,
  supplierSections,
  setSupplierSections,
}) => {
  const [currency, setCurrency] = useState("CAD");
  function calculateMarginPercentage(salePrice, originalCost) {
    // Check if the original cost is greater than zero to avoid division by zero
    if (originalCost > 0) {
      // Calculate the profit
      const profit = salePrice - originalCost;

      // Calculate the margin percentage
      const marginPercentage = (profit / salePrice) * 100;
      const margin = marginPercentage.toFixed(2);
      if (margin !== "NaN") {
        return margin;
      } else {
        return "-";
      }
    } else {
      // Handle the case where originalCost is zero or negative
      return "-";
    }
  }
  function calculateProfit(price, cost) {
    const profit = (parseFloat(price) - parseFloat(cost)).toFixed(2);
    if (profit === "NaN") {
      return "-";
    } else {
      return profit;
    }
  }
  const handleFieldChange = (name, e) => {
    setFormData({ ...formData, [name]: e.target.value });
  };
  useEffect(() => {
    setSupplierSections((sections) => {
      return sections.map((section) => {
        return {
          ...section,
          profit: calculateProfit(formData?.sales_price, section?.cost),
          margin: calculateMarginPercentage(
            formData?.sales_price,
            section?.cost
          ),
        };
      });
    });
  }, [formData?.sales_price]);
  useEffect(() => {
    setFormData({ ...formData, suppliers: supplierSections });
  }, [supplierSections]);
  useEffect(() => {
    setFormData({ ...formData, currency });
  }, [currency]);
  return (
    <section className="addprod__formsection_wrap" id="addproduct_pricing">
      <div className="addprod__formsection_heading">Pricing</div>
      <div className="addprod__custominput_flexrow">
        <div className="addprod__custominput_flexcol">
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Currency</label>
            <CustomDropdown
              options={["CAD"]}
              onSelect={(option) => {
                setCurrency(option);
              }}
              selectedOption={currency}
            />
            {Object.keys(errors)?.includes("currency") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.currency?.msg}
              </div>
            )}
          </div>
        </div>
        <div className="addprod__custominput_flexcol">
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Regular Price</label>
            <input
              className="addprod__custominputfield"
              type="text"
              placeholder="Enter Regular price"
              onChange={(e) => handleFieldChange("regular_price", e)}
              value={formData?.regular_price}
            />
            {Object.keys(errors)?.includes("regular_price") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.regular_price?.msg}
              </div>
            )}
          </div>
        </div>
        <div className="addprod__custominput_flexcol">
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Sales Price</label>
            <input
              className="addprod__custominputfield"
              type="text"
              placeholder="Enter Sales price"
              onChange={(e) => handleFieldChange("sales_price", e)}
              value={formData?.sales_price}
            />
            {Object.keys(errors)?.includes("sales_price") && (
              <div className="addprod__custominputfield_error">
                <MdErrorOutline className="addprod__custominputfield_erroricn" />
                {errors?.sales_price?.msg}
              </div>
            )}
          </div>
        </div>
        <div className="addprod__custominput_flexcol">
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Total Quantity</label>
            <input
              className="addprod__custominputfield"
              type="text"
              placeholder="Quantity"
              onChange={(e) => handleFieldChange("totalQuantity", e)}
              value={formData?.totalQuantity ?? 0}
              disabled
            />
          </div>
        </div>
        <div className="addprod__custominput_flexcol">
          <div className="addprod__custominput_box">
            <label className="addprod__custominput_title">Total Value</label>
            <input
              className="addprod__custominputfield"
              type="text"
              placeholder="Value"
              onChange={(e) => handleFieldChange("totalQuantity", e)}
              value={
                (
                  parseFloat(formData?.sales_price ?? 0) *
                  parseInt(formData?.totalQuantity ?? 0)
                )?.toFixed(1) + "$"
              }
              disabled
            />
          </div>
        </div>
      </div>
      {formData.suppliers?.map((section, index) => (
        <div key={index} className="addprod__custominput_supcostsection">
          <button
            onClick={() => {
              if (section.id) {
                setFormData({
                  ...formData,
                  deletedSuppliersIds: [
                    ...formData?.deletedSuppliersIds,
                    section.id,
                  ],
                });
              }
              setSupplierSections([
                ...supplierSections?.filter((sec, idx) => idx !== index),
              ]);
            }}
          >
            <FaRegTrashAlt
              className="addprod__custominput_supcostsectionclose"
              size={14}
            />
          </button>
          <div className="addprod__custominput_flexrow">
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "200px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">Supplier</label>
                <CustomDropdown
                  optionObjects={productSuppliers}
                  onSelect={(option) => {
                    setSupplierSections([
                      ...supplierSections.map((sec, idx) =>
                        idx === index ? { ...section, supplier: option } : sec
                      ),
                    ]);
                  }}
                  selectedOption={section.supplier}
                />
                {Object.keys(errors)?.includes("suppliers_name") &&
                  errors?.suppliers_name?.includes(index)(
                    <div className="addprod__custominputfield_error">
                      <MdErrorOutline className="addprod__custominputfield_erroricn" />
                      Supplier Name is required
                    </div>
                  )}
              </div>
            </div>
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "120px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">
                  Cost per item
                </label>
                <input
                  className="addprod__custominputfield"
                  onChange={(e) => {
                    setSupplierSections([
                      ...supplierSections.map((sec, idx) =>
                        idx === index
                          ? {
                              ...section,
                              cost: e.target.value,
                              profit: calculateProfit(
                                formData?.sales_price,
                                e.target.value
                              ),
                              margin: calculateMarginPercentage(
                                formData?.sales_price,
                                e.target.value
                              ),
                            }
                          : sec
                      ),
                    ]);
                  }}
                  value={section.cost ?? ""}
                  type="text"
                  placeholder="Enter per item cost"
                />
                {Object.keys(errors)?.includes("suppliers_cost") &&
                  errors?.suppliers_cost?.includes(index)(
                    <div className="addprod__custominputfield_error">
                      <MdErrorOutline className="addprod__custominputfield_erroricn" />
                      Supplier Cost is required
                    </div>
                  )}
              </div>
            </div>
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "120px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">Profit</label>
                <input
                  className="addprod__customnoneditablefield"
                  type="text"
                  value={section.profit ?? "-"}
                />
              </div>
            </div>
            <div
              className="addprod__custominput_flexcol"
              style={{ minWidth: "120px" }}
            >
              <div className="addprod__custominput_box">
                <label className="addprod__custominput_title">Margin</label>
                <input
                  className="addprod__customnoneditablefield"
                  type="text"
                  value={`${section.margin ?? "-"}${
                    section.margin > 0 || section.margin < 0 ? "%" : ""
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <button
        className="addprod__formsection_addvarbtn"
        onClick={() =>
          setSupplierSections([
            ...supplierSections,
            { supplier: "", unit_cost: "", profit: "", margin: "" },
          ])
        }
      >
        <MdAddCircleOutline
          className="addprod__formsection_addvarbtnicn"
          size={14}
        />
        Add Supplier & Cost
      </button>
    </section>
  );
};

export default PricingSection;
