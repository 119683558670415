import React, { useState } from "react";
import "./AddBalance.css";

// components imports
import Loader from "../../../../../Components/CircularLoader/CircularLoader";
// apis imports
import { updateBalance } from "../../../../../services/AdminServices/Users/users.services";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const initialData = {
  balance: "",
  notes: "",
  payment_type: "by_hand",
};

export default function AddBalance({
  open,
  setOpen,
  setSnack,
  // setPayments,
  updateUsers,
}) {
  const user = useSelector((state) => state.user);

  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setData(initialData);
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleFieldChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    const id = open?.id;
    const response = await updateBalance(data, id);
    if (response.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Balance Updated Successfully",
          severity: "success",
        };
      });
    }
    updateUsers((prevUser) => {
      return {
        ...prevUser,
        data:
          open.role === "warehouseUser"
            ? prevUser?.data?.map((userData) => {
                return userData?.user?.id === id
                  ? { ...userData, user: response?.data?.result }
                  : userData;
              })
            : prevUser?.data?.map((user) => {
                return user.id === id
                  ? { ...user, balance: response?.data?.result?.balance }
                  : user;
              }),
      };
    });
    setData(initialData);
    setLoading(false);
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="admnadd__balance_modal">
          <div className="prdtable__hist_modalheading history__product_heading history__sub_heading">
            {open?.username ?? ""} ({open?.business_name ?? ""})
          </div>
          <div className="admnadd__balance_modalheading">Add Balance</div>
          <div className="admnadd__balance_modalcontent">
            <div className="admnedit__user_modalrow">
              <div className="admnedit__user_modalinputwrap">
                <label className="admnadd__balance_modallbl">
                  Payment Type
                </label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  initialValue="by_hand"
                  options={["by_hand", "bank_transfer", "cheque"]}
                  getOptionLabel={(option) => option}
                  sx={{
                    "&  div": {
                      outline: "none",
                      padding: 0,
                      border: "none",
                      outline: "none",
                      height: "50px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Payment Type"
                      className="admnedit__user_modalinput"
                      sx={{
                        height: "50px",
                        "&  div": {
                          height: "50px",
                        },
                      }}
                    />
                  )}
                  value={data.payment_type}
                  onChange={(e, payment_type) =>
                    handleFieldChange("payment_type", payment_type)
                  }
                />
              </div>
            </div>
            <div className="admnadd__balance_modalrow">
              <div className="admnadd__balance_modalinputwrap">
                <label className="admnadd__balance_modallbl">Amount*</label>
                <input
                  className="admnadd__balance_modalinput"
                  placeholder="Enter Amount"
                  name="balance"
                  value={data.balance}
                  onChange={handleChange}
                  type="number"
                />
              </div>
            </div>
            <div className="admnadd__balance_modalrow">
              <div className="admnadd__balance_modalinputwrap">
                <label className="admnadd__balance_modallbl">
                  Instructions
                </label>
                <input
                  className="admnadd__balance_modalinput"
                  placeholder="Enter Instructions"
                  name="notes"
                  value={data.notes}
                  onChange={handleChange}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="admnadd__balance_modalfooter">
            <div className="admnadd__balance_modalcncl" onClick={handleClose}>
              Cancel
            </div>
            <div className="admnadd__balance_modalsave" onClick={handleSave}>
              {loading ? <Loader /> : "Save"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
