import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomNavbar from "./Navbar";
import ProfileModal from "./Components/ProfileModal/ProfileModal";
import logo from "../logo.png";
import moscot from "../moscot.png";
import { MdOutlineShoppingCart, MdStorage } from "react-icons/md";
import { FaDigitalTachograph, FaShippingFast, FaTrailer } from "react-icons/fa";
import { TbTicket, TbTruckDelivery } from "react-icons/tb";
import { BiPackage } from "react-icons/bi";
import AuthComponent from "../../Pages/Authentication/Components/AuthComponent/AuthComponent";

function ShipperNavbar() {
  const user = useSelector((state) => state.user);
  const [shipperPermissions, setShipperPermissions] = useState([]);

  useEffect(() => {
    setShipperPermissions([
      {
        label: "Orders",
        url: "/orders",
        name: "shipper.access.order.view",
        unReadData: user?.unReadOrders, // Badge data
        icon: <MdOutlineShoppingCart className="m-auto header__nav_btn_icon" />,
      },
      {
        label: "Ship",
        url: "/",
        name: "shipper.access.shipment.create",
        unReadData: null,
        icon: <FaShippingFast className="m-auto header__nav_btn_icon" />,
      },
      {
        label: "Shipments",
        url: "/shipments",
        name: "shipper.access.shipment.view",
        unReadData: user?.unReadShipments, // Badge data
        icon: <FaTrailer className="m-auto header__nav_btn_icon" />,
      },
      {
        label: "Finances",
        url: "/payments",
        name: "shipper.access.payment.view",
        unReadData: null,
        icon: <FaDigitalTachograph className="m-auto header__nav_btn_icon" />,
      },
      {
        label: "Tickets",
        url: "/Tickets",
        name: "shipper.access.ticket.view",
        unReadData: null,
        icon: <TbTicket className="m-auto header__nav_btn_icon" />,
      },
      {
        label: "Carriers",
        url: "/Carriers",
        name: "shipper.access.carrier.view",
        unReadData: null,
        icon: <TbTruckDelivery className="m-auto header__nav_btn_icon" />,
      },
      {
        label: "Products",
        url: "/products",
        name: "", // No specific permission required
        unReadData: null,
        icon: <BiPackage className="m-auto header__nav_btn_icon" />,
      },
      {
        label: "Packages",
        url: "/Packages",
        name: "shipper.access.product.view",
        unReadData: null,
        icon: <BiPackage className="m-auto header__nav_btn_icon" />,
      },
      {
        label: "MarketPlaces",
        url: "/Marketplaces",
        name: "", // No specific permission required
        unReadData: null,
        icon: <MdStorage className="m-auto header__nav_btn_icon" />,
      },
    ]);
  }, [user]);

  return (
    <>
      <AuthComponent />
      <CustomNavbar
        logo={logo}
        navItems={shipperPermissions}
        profileImage={user?.profile_image}
        profileModal={({ onClose }) => (
          <ProfileModal onClose={onClose} style={{ top: "50px", right: "0" }} />
        )}
        dateBadge
        toastConfig={{
          show: false,
          title: "Hajex Shipment",
          message: "Successfully Logged Out!",
          image: moscot,
          timestamp: "just now",
        }}
      />
    </>
  );
}

export default ShipperNavbar;
