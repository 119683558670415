import React, { useEffect } from "react";
import { refreshUser } from "../../../../services/auth.services";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addUser } from "../../../../store/userSlice";

const AuthComponent = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateToken = async () => {
    const res = await refreshUser(user?.token);
    // console.log('response',res);
    if (res.error?.response?.status === 401) {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}logout`,
          method: "POST",
          timeout: 0,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("Token"),
          },
        });
        localStorage.clear();
        navigate("/Login");
      } catch (error) {
        localStorage.clear();
        navigate("/Login");
      }
    } else {
      dispatch(addUser({ ...res.data.result, token: user.token }));
      localStorage.setItem(
        "user",
        JSON.stringify({ ...res.data.result, token: user.token })
      );
    }
  };

  useEffect(() => {
    validateToken();

    // Set up an interval to call validateToken every minute
    const intervalId = setInterval(() => {
      validateToken();
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearInterval(intervalId);
  }, []);
  return <></>;
};

export default AuthComponent;
