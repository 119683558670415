import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  Checkbox,
  TextField,
  Button,
} from "@mui/material";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { BsTrash } from "react-icons/bs";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { setdata } from "../../../../../../helpers/helpers";
import noImage from "../../../../../../assets/images/noImage.png";
import NewTextField from "./TextField";
import IconButton from "@mui/material/IconButton";
import { RiAddLine } from "react-icons/ri";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

export default function ProductList(props) {
  const {
    Plist,
    StepCheck,
    SetStepCheck,
    SetPlist,
    SelectedProducts,
    setSelectedProducts,
  } = props;

  const user = useSelector((state) => state.user);

  const handleStepper = () => {
    SetStepCheck((prevStepCheck) => ({
      ...prevStepCheck,
      Packages_bit: 0,
      QuoteCheck: false,
    }));
  };

  const handlescrollpackage = (data) => {
    if (!StepCheck["Warehouses"]) {
      return;
    }

    const productId = data.id;

    const existingProductIndex = SelectedProducts.findIndex(
      (product) => product.id === productId
    );

    if (existingProductIndex !== -1) {
      const updatedProducts = [...SelectedProducts];
      updatedProducts[existingProductIndex].quantity += 1;
      setSelectedProducts(updatedProducts);
    } else {
      const newData = { ...data, quantity: 1 };
      setSelectedProducts([...SelectedProducts, newData]);
    }

    if (data.package_details) {
      const x = Plist.length - 1;
      if (
        Plist[x]["name"] === "" &&
        Plist[x]["length"] === "" &&
        Plist[x]["width"] === "" &&
        Plist[x]["height"] === "" &&
        Plist[x]["weight"] === "" &&
        Plist[x]["description"] === "" &&
        Plist[x]["insurance"] === ""
      ) {
        SetPlist((prevPlist) => {
          const newPackages = Object.entries(data.package_details).map(
            ([packageId, packageItem]) => ({
              name: data.name,
              length: setdata(() => packageItem.length, false, ""),
              width: setdata(() => packageItem.width, false, ""),
              height: setdata(() => packageItem.height, false, ""),
              weight: setdata(() => packageItem.weight, false, ""),
              description: packageItem.description,
              insurance: "",
              volume: (
                (setdata(() => packageItem.length, false, 0) *
                  setdata(() => packageItem.width, false, 0) *
                  setdata(() => packageItem.height, false, 0)) /
                1000000
              ).toFixed(2),
              warehouse_id: StepCheck["Warehouses"],
              product_id: data.id,
            })
          );
          return [...newPackages];
        });
      } else {
        SetPlist((prevPlist) => [
          ...prevPlist,
          ...Object.entries(data.package_details).map(
            ([packageId, packageItem]) => ({
              name: data.name,
              length: setdata(() => packageItem.length, false, ""),
              width: setdata(() => packageItem.width, false, ""),
              height: setdata(() => packageItem.height, false, ""),
              weight: setdata(() => packageItem.weight, false, ""),
              description: packageItem.description,
              insurance: "",
              volume: (
                (setdata(() => packageItem.length, false, 0) *
                  setdata(() => packageItem.width, false, 0) *
                  setdata(() => packageItem.height, false, 0)) /
                1000000
              ).toFixed(2),
              warehouse_id: StepCheck["Warehouses"],
              product_id: data.id,
            })
          ),
        ]);
      }
    } else {
      const x = Plist.length - 1;
      if (
        Plist[x]["name"] === "" &&
        Plist[x]["length"] === "" &&
        Plist[x]["width"] === "" &&
        Plist[x]["height"] === "" &&
        Plist[x]["weight"] === "" &&
        Plist[x]["description"] === "" &&
        Plist[x]["insurance"] === ""
      ) {
        const plist = [...Plist];
        plist[x]["name"] = data.name;
        plist[x]["length"] = setdata(() => data.length, false, "");
        plist[x]["width"] = setdata(() => data.width, false, "");
        plist[x]["height"] = setdata(() => data.height, false, "");
        plist[x]["weight"] = setdata(() => data.weight, false, "");
        plist[x]["volume"] = (
          (setdata(() => data.length, false, 0) *
            setdata(() => data.width, false, 0) *
            setdata(() => data.height, false, 0)) /
          1000000
        ).toFixed(2);
        plist[x]["description"] = data.full_name;
        plist[x]["insurance"] = "";
        plist[x]["warehouse_id"] = StepCheck["Warehouses"];
        plist[x]["product_id"] = data.id;
        SetPlist(plist);
      } else {
        SetPlist([
          ...Plist,
          {
            name: data.name,
            length: setdata(() => data.length, false, ""),
            width: setdata(() => data.width, false, ""),
            height: setdata(() => data.height, false, ""),
            weight: setdata(() => data.weight, false, ""),
            description: data.full_name,
            insurance: "",
            warehouse_id: StepCheck["Warehouses"],
            product_id: data.id,
            volume: (
              (setdata(() => data.length, false, 0) *
                setdata(() => data.width, false, 0) *
                setdata(() => data.height, false, 0)) /
              1000000
            ).toFixed(2),
          },
        ]);
      }
    }
    handleStepper();
    // handleClose();
  };

  const handleAdd = (index) => {
    handlescrollpackage(SelectedProducts[index]);
    // const updatedProducts = [...SelectedProducts];
    // updatedProducts[index].quantity += 1;
    // setSelectedProducts(updatedProducts);
    handleStepper();
  };

  const handleNotes = (e, index) => {
    const updatedProducts = [...SelectedProducts];
    updatedProducts[index].note = e.target.value;
    setSelectedProducts(updatedProducts);
  };

  const handleSubtract = (index) => {
    const updatedProducts = [...SelectedProducts];
    // console.log(SelectedProducts);

    // const SelectedProduct = SelectedProducts[index];
    // const foundProduct = Plist.filter(
    //   (product) => product.product_id === SelectedProduct["id"]
    // );
    // if (foundProduct) {
    //   const length = SelectedProduct["package_details"]
    //     ? Object.keys(SelectedProduct["package_details"]).length
    //     : 0;
    //   if (length > 0) {
    //     const trimmedPlist = Plist.slice(0, -length);
    //     console.log("done + packages", trimmedPlist);
    //   } else {
    //     const trimmedPlist = Plist.slice(0, -1);
    //     console.log("done + dimentions", trimmedPlist);
    //   }
    // } else {
    //   console.log("failed", foundProduct);
    // }
    if (updatedProducts[index].quantity > 1) {
      updatedProducts[index].quantity -= 1;
    } else {
      RemovePackagesOnProductRemove(updatedProducts[index]["id"]);
      updatedProducts.splice(index, 1);
    }
    setSelectedProducts(updatedProducts);
    handleStepper();
  };

  const fetchData = async () => {
    let totalWeight = 0;
    let totalCost = 0;
    let totalweightcost = 0;
    let costcount = 0;
    let totalproducts = 0;
    const userWarehouses = user?.user_approved_warehouses || [];
    const foundWarehouse = userWarehouses.find(
      (warehouse) => warehouse.warehouse_id === StepCheck["Warehouses"]
    );
    const Formula = foundWarehouse?.warehouse?.formula[0];
    if (Formula.type === "by_weight") {
      const findFormula = async (weight) => {
        let lastFormulaValue = null;

        for (const formula of Formula.formula_details) {
          const min = parseFloat(formula.min) || Number.NEGATIVE_INFINITY;
          const max = formula.max
            ? parseFloat(formula.max)
            : Number.POSITIVE_INFINITY;

          if (weight >= min && weight <= max) {
            return parseFloat(formula.value);
          }

          if (weight > max) {
            lastFormulaValue = parseFloat(formula.value);
          }
        }
        return lastFormulaValue;
      };

      SelectedProducts.forEach(async (product) => {
        const weight = product?.weight ?? 0;
        if (!product?.fulfillment_cost) {
          totalWeight = totalWeight + parseFloat(weight) * product.quantity;
        } else {
          costcount = costcount + 1 * product.quantity;
          totalCost =
            totalCost +
            parseFloat(product?.fulfillment_cost) * product.quantity;
        }
        totalproducts = totalproducts + product.quantity;
      });

      totalweightcost = await findFormula(totalWeight);

      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck,
        TotalFulfilmentCost: totalweightcost + totalCost ?? 0,
        TotalWeightCost: totalweightcost ?? 0,
        TotalPreCost: totalCost ?? 0,
        TotalFulfilmentWeight: totalWeight ?? 0,
        TotalPreProduct: costcount ?? 0,
        UnitType: Formula.unit,
        TotalProducts: totalproducts ?? 0,
        Formula: Formula,
      }));
    } else {
      const findFormula = async (volume) => {
        let lastFormulaValue = null;

        for (const formula of Formula.dimensions) {
          const min =
            (
              (parseFloat(formula.min_length) *
                parseFloat(formula.min_width) *
                parseFloat(formula.min_height)) /
              1000000
            ).toFixed(2) || Number.NEGATIVE_INFINITY;
          const max =
            (
              (parseFloat(formula.max_length) *
                parseFloat(formula.max_width) *
                parseFloat(formula.max_height)) /
              1000000
            ).toFixed(2) || Number.POSITIVE_INFINITY;

          if (volume >= min && volume <= max) {
            return parseFloat(formula.value);
          }

          if (volume > max) {
            lastFormulaValue = parseFloat(formula.value);
          }
        }
        return lastFormulaValue;
      };

      SelectedProducts.forEach(async (product) => {
        const volume = (
          (parseFloat(product.length) *
            parseFloat(product.width) *
            parseFloat(product.height)) /
          1000000
        ).toFixed(2);
        if (!product?.fulfillment_cost) {
          // console.log(volume);
          totalWeight = totalWeight + volume * product.quantity;
        } else {
          costcount = costcount + 1 * product.quantity;
          totalCost =
            totalCost +
            parseFloat(product?.fulfillment_cost) * product.quantity;
        }
        totalproducts = totalproducts + product.quantity;
      });

      totalweightcost = await findFormula(totalWeight);

      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck,
        TotalFulfilmentCost: totalweightcost + totalCost ?? 0,
        TotalWeightCost: totalweightcost ?? 0,
        TotalPreCost: totalCost ?? 0,
        TotalFulfilmentWeight: totalWeight ?? 0,
        TotalPreProduct: costcount ?? 0,
        UnitType: "CBM",
        TotalProducts: totalproducts ?? 0,
        Formula: Formula,
      }));
    }
    // console.log(Formula);
  };

  useEffect(() => {
    if (SelectedProducts.length > 0) {
      fetchData();
    }
  }, [SelectedProducts]);

  function RemovePackagesOnProductRemove(productIdToRemove) {
    const filteredPlist = Plist.filter(
      (item) => item.product_id !== productIdToRemove
    );
    if (filteredPlist.length > 0) {
      SetPlist(filteredPlist);
    } else {
      SetPlist([
        {
          name: "",
          length: "",
          width: "",
          height: "",
          weight: "",
          volume: "",
          insurance: "",
          description: "",
          feright: "",
          nmfc: "",
          type: "",
          pieces: "",
        },
      ]);
    }
  }

  const handleRemove = (index, id) => {
    RemovePackagesOnProductRemove(id);
    const list = [...SelectedProducts];
    list.splice(index, 1);
    setSelectedProducts(list);
    handleStepper();
  };

  function filterProductWarehouses(singlelist) {
    try {
      const Data = singlelist.product_warehouse.filter((warehouse) => {
        return StepCheck?.Warehouses.includes(warehouse.warehouse_id);
      });

      let AvailableQty = parseInt(Data?.[0]?.quantity ?? 0);
      let ChangedQty = parseInt(singlelist?.quantity ?? 0);

      return AvailableQty - ChangedQty;
    } catch (error) {
      console.error("Error occurred:", error);
      return 0; // Return a default value or handle the error as needed
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLotChange = (productIndex, lotId, quantity) => {
    const updatedProducts = [...SelectedProducts];

    const product = updatedProducts[productIndex];
    const existingLot = product.productLotData?.find(
      (lot) => lot.product_lot_id === lotId
    );

    if (existingLot) {
      // Update quantity for the existing lot
      existingLot.quantity = quantity;
    } else {
      // Add new lot
      product.productLotData = [
        ...(product.productLotData || []),
        { product_lot_id: lotId, quantity },
      ];
    }

    setSelectedProducts(updatedProducts);
  };

  const handleToggleLot = (productIndex, lotId, name) => {
    const updatedProducts = [...SelectedProducts];

    const product = updatedProducts[productIndex];
    const existingLotIndex = product.productLotData?.findIndex(
      (lot) => lot.product_lot_id === lotId
    );

    if (existingLotIndex > -1) {
      // Remove lot if it exists
      product.productLotData.splice(existingLotIndex, 1);
    } else {
      // Add new lot with default quantity
      product.productLotData = [
        ...(product.productLotData || []),
        { product_lot_id: lotId, quantity: 0, name: name },
      ];
    }

    setSelectedProducts(updatedProducts);
  };

  return (
    <>
      <Modal
        open={isModalOpen ? true : false}
        onClose={() => setIsModalOpen(false)}
      >
        <Box sx={style}>
          <Typography variant="h6">Select Lots</Typography>
          <List>
            {isModalOpen?.singlelist?.product_warehouse
              ?.find((warehouse) =>
                StepCheck?.Warehouses?.includes(warehouse?.warehouse_id)
              )
              ?.product_lots?.map((lot) => {
                const isSelected = SelectedProducts[
                  isModalOpen?.index
                ]?.productLotData?.some(
                  (selectedLot) => selectedLot.product_lot_id === lot.id
                );
                const quantity =
                  SelectedProducts[isModalOpen?.index]?.productLotData?.find(
                    (selectedLot) => selectedLot.product_lot_id === lot.id
                  )?.quantity || 0;

                return (
                  <ListItem
                    key={lot.id}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Checkbox
                      checked={isSelected}
                      onChange={() =>
                        handleToggleLot(isModalOpen?.index, lot.id, lot.name)
                      }
                    />
                    <Typography>{`${lot.name} (Available: ${lot.product_pallets_sum_quantity})`}</Typography>
                    {isSelected && (
                      <TextField
                        type="number"
                        size="small"
                        value={quantity}
                        onChange={(e) =>
                          handleLotChange(
                            isModalOpen?.index,
                            lot.id,
                            Number(e.target.value)
                          )
                        }
                        sx={{ width: "80px", marginLeft: "10px" }}
                      />
                    )}
                  </ListItem>
                );
              })}
          </List>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsModalOpen(false)}
          >
            Save
          </Button>
        </Box>
      </Modal>
      <Row>
        <Col md={9} className="package__name_list_wrap">
          <div className="package__name_list_names_header">
            <div className="package__name_list_heading package__name_head_product">
              <h6>Products</h6>
            </div>
            <div className="package__name_list_heading package__name_head_name">
              <h6>Name</h6>
            </div>
            <div className="package__name_list_heading package__name_head_sku">
              <h6>Sku</h6>
            </div>
            <div className="package__name_list_heading package__name_head_size">
              <h6>Size (L*W*H)</h6>
            </div>
            <div className="package__name_list_heading package__name_head_weight">
              <h6>Weight</h6>
            </div>
            <div className="package__name_list_heading package__name_head_quantity">
              <h6>Quantity</h6>
            </div>
            <div className="package__name_list_heading package__name_head_stock">
              <h6>Stock (#{StepCheck?.Warehouses})</h6>
            </div>
            <div className="package__name_list_heading package__name_head_notes">
              <h6>Lot/Batch</h6>
            </div>
            <div className="package__name_list_heading package__name_head_notes">
              <h6>Notes</h6>
            </div>
            <div className="package__name_list_heading package__name_head_action">
              <h6>Action</h6>
            </div>
          </div>

          {SelectedProducts.map((singlelist, index) => (
            <div className="package__name_list_box_content_wrap" key={index}>
              <div className="package__name_list_box_content package__name_content_index">
                {"#"} {index + 1}
              </div>
              <div className="package__name_list_box_content package__name_content_image">
                <img
                  src={singlelist?.media[0]?.file || noImage}
                  onError={(e) => {
                    e.target.src = noImage;
                  }}
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "10px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="package__name_list_box_content package__name_content_fullname">
                {singlelist.full_name ?? "-"}
              </div>
              <div className="package__name_list_box_content package__name_content_code">
                {singlelist.code ?? "-"}
              </div>
              <div className="package__name_list_box_content package__name_content_dimension">
                {singlelist.length ?? "0"}*{singlelist.width ?? "0"}*
                {singlelist.height ?? "0"} {singlelist.dimension_unit}
              </div>
              <div className="package__name_list_box_content package__name_content_weight">
                {singlelist.weight ?? "0"} {singlelist.weight_unit}
              </div>
              <div className="package__name_list_box_content package__name_content_qty">
                <Tooltip title="Remove Quantity">
                  <a
                    type="button"
                    onClick={() => handleSubtract(index)}
                    className="package__name_list_qty_btn"
                  >
                    <IoIosRemove
                      size={25}
                      style={{
                        border: "2px solid #E2EDFB",
                        borderRadius: "25%",
                        marginRight: "5px",
                      }}
                      className="p-1 overlaybtn"
                    />
                  </a>
                </Tooltip>
                {singlelist.quantity ?? "N/A"}
                <Tooltip title="Add Quantity">
                  <a
                    type="button"
                    onClick={() => handleAdd(index)}
                    className="package__name_list_qty_btn"
                  >
                    <IoIosAdd
                      size={25}
                      style={{
                        border: "2px solid #E2EDFB",
                        borderRadius: "25%",
                        marginLeft: "5px",
                      }}
                      className="p-1 overlaybtn"
                    />
                  </a>
                </Tooltip>
              </div>
              <div className="package__name_list_box_content package__name_content_stock">
                {filterProductWarehouses(singlelist)}
              </div>
              <div
                style={{ justifyContent: "center" }}
                className="package__name_list_box_content package__name_content_notes"
              >
                {singlelist?.productLotData?.map((lot) => (
                  <>
                    {lot?.name} ({lot?.quantity})
                    <br />
                  </>
                ))}
                <IconButton
                  onClick={() =>
                    setIsModalOpen({ index: index, singlelist: singlelist })
                  }
                  aria-label="select_lot"
                  style={{ marginLeft: "5px" }}
                >
                  <RiAddLine style={{ color: "#1976d2" }} />
                </IconButton>
                {/* <Button
                  onClick={() =>
                    setIsModalOpen({ index: index, singlelist: singlelist })
                  }
                >
                  Select Lots
                </Button> */}
              </div>
              <div
                className="package__name_list_box_content package__name_content_notes"
                style={{ justifyContent: "space-around" }}
              >
                <NewTextField
                  // Label="Product Notes"
                  Name="note"
                  Data={singlelist?.note ?? ""}
                  Required={false}
                  Handler={(e) => handleNotes(e, index)}
                  Multiline={true}
                  SX={{
                    fontSize: "12px",
                    width: "90%",
                    input: { font: "menu" },
                  }}
                />
              </div>
              <div className="package__name_list_box_content package__name_content_delete">
                <Tooltip title="Delete Product">
                  <a
                    type="button"
                    onClick={() => handleRemove(index, singlelist.id)}
                    className="package__name_list_box_delete_prod"
                  >
                    <BsTrash
                      size={35}
                      style={{
                        border: "2px solid #E2EDFB",
                        borderRadius: "25%",
                      }}
                      className="p-2 overlaybtn"
                    />
                  </a>
                </Tooltip>
              </div>
            </div>
          ))}
        </Col>
        <Col
          md={3}
          className="package__name_list_wrap package__name_list_wrap_fullfilment_cost"
          style={{
            borderLeft: "1px solid #d3d3d3",
            padding: "10px 15px 5px 15px",
            // margin: "0 0px 0 17px",
            // width: "31%",
          }}
        >
          <Row style={{ marginBottom: "5px" }}>
            <h3>Fulfilment Cost </h3>{" "}
          </Row>
          {StepCheck["TotalProducts"] - StepCheck["TotalPreProduct"] > 0 && (
            <div className="package__name_fullfilment_cost_row">
              <label className="tile__label_fullfilment_cost">
                {StepCheck["UnitType"] === "CBM" ? "Volume" : "Weight"}: (
                {StepCheck["TotalFulfilmentWeight"]} {StepCheck["UnitType"]}) (
                {StepCheck["TotalProducts"] - StepCheck["TotalPreProduct"]})
              </label>
              <span className="tile__value_fullfilment_cost">
                {StepCheck["TotalWeightCost"]} CAD
              </span>
            </div>
          )}
          {StepCheck["TotalPreProduct"] > 0 && (
            <>
              <div className="package__name_fullfilment_cost_row">
                <label className="tile__label_fullfilment_cost">
                  Pre Cost Item Quantity: ({StepCheck["TotalPreProduct"]})
                </label>
                {/* <span className="tile__value_fullfilment_cost">{StepCheck["TotalPreCost"]} CAD </span> */}
              </div>
              {SelectedProducts.filter(
                (singlelist) => singlelist.fulfillment_cost
              ).map((singlelist, index) => (
                <div
                  className="package__name_fullfilment_cost_row"
                  key={index} // It's a good 5practice to provide a unique key for each mapped element
                >
                  <label className="tile__label_fullfilment_cost">
                    {singlelist.name} ({singlelist.quantity} x{" "}
                    {singlelist.fulfillment_cost})
                  </label>
                  <span className="tile__value_fullfilment_cost">
                    {singlelist.fulfillment_cost * singlelist.quantity} CAD{" "}
                  </span>
                </div>
              ))}
              <div
                className="package__name_fullfilment_cost_row"
                style={{ justifyContent: "flex-end" }}
              >
                {/* <label className="tile__label_fullfilment_cost">Total Items Fulfilment: ({StepCheck["TotalPreProduct"]})</label> */}
                <span className="tile__value_fullfilment_cost">
                  {StepCheck["TotalPreCost"]} CAD{" "}
                </span>
              </div>
            </>
          )}

          <div className="package__name_fullfilment_cost_row">
            <label className="tile__label_fullfilment_cost">
              Total Products Selected:
            </label>
            <span className="tile__value_fullfilment_cost">
              {StepCheck["TotalProducts"]}
            </span>
          </div>
          <div className="package__name_fullfilment_cost_row">
            <label className="tile__label_fullfilment_cost">
              Total Fulfilment COST:
            </label>
            <span className="tile__value_fullfilment_cost">
              {StepCheck["TotalFulfilmentCost"]} CAD{" "}
            </span>
          </div>
          <div className="package__dimension_wrap dimension_weight_card_wrap fulfilment__after_brod_add">
            <div className="package__dimension_weight_volume">
              <div className="package__dimension_weight_volume_label">
                <div className="package__dimension_weight">
                  <span>Weight</span>
                  <small style={{ whiteSpace: "nowrap" }}>
                    {parseFloat(StepCheck["TotalWeight"] ?? 0).toFixed(2)}{" "}
                    {StepCheck["unit"] === "Imperial" ? "lb" : "kg"}
                  </small>
                </div>
                <div className="package__dimension_volume">
                  <span>Volume</span>
                  <small style={{ whiteSpace: "nowrap" }}>
                    {parseFloat(StepCheck["TotalVolume"] ?? 0).toFixed(2)}{" "}
                    {StepCheck["unit"] === "Imperial" ? "Cbm" : "Cbf"}
                  </small>
                </div>
                <div className="package__dimension_volume">
                  <span>Fulfilment Cost</span>
                  <small style={{ whiteSpace: "nowrap" }}>
                    {StepCheck["TotalFulfilmentCost"]} CAD{" "}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
