import React from "react";
import { useSelector } from "react-redux";

const AppOverlay = () => {
  const uiState = useSelector((state) => state.ui);
  return (
    <div
      style={
        uiState?.isDisabled
          ? {
              // pointerEvents: "none",
              position: "fixed",
              height: "100vh",
              width: "100vw",
              top: "0px",
              left: "0px",
              zIndex: "99999999999999999999999999",
            }
          : {}
      }
    ></div>
  );
};

export default AppOverlay;
