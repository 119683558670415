import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import frame3 from "../../../../../../../Components/Images/Frame 3.png";
import frame4 from "../../../../../../../Components/Images/Frame 4.png";

export default function MarketPlaceDetails({ open, setOpen }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[95%] max-w-md max-h-[90vh] overflow-auto bg-white shadow-lg p-6 rounded-lg animate-fadeIn">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">Marketplace</h2>
          <button
            onClick={handleClose}
            className="px-3 py-1 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Close
          </button>
        </div>
        <div className="text-gray-600 mt-2">{open?.order_id}</div>
        <div className="text-gray-600 mt-2">
          {open?.market_place_status?.toUpperCase()}
        </div>
        <div className="text-gray-600 mt-2">
          {open?.amount_received ?? "0"} $
        </div>
        <img
          src={
            open?.order_market_place_details?.market === "Shopify"
              ? frame4
              : open?.order_market_place_details?.market === "Woocommerce"
              ? frame3
              : ""
          }
          alt="Marketplace Logo"
          className="w-20 h-14 mt-4"
          loading="lazy"
          onError={(e) => {
            e.target.src =
              open?.order_market_place_details?.market === "Shopify"
                ? frame4
                : open?.order_market_place_details?.market === "Woocommerce"
                ? frame3
                : "";
          }}
        />
        <div className="mt-4 space-y-3">
          {open?.shopify_details?.order_url && (
            <div className="flex justify-between items-center">
              <span className="font-semibold">Order:</span>
              <button
                onClick={() =>
                  window.open(open?.shopify_details?.order_url, "_blank")
                }
                className="px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                View
              </button>
            </div>
          )}
          {open?.shopify_details?.order_detail_url && (
            <div className="flex justify-between items-center">
              <span className="font-semibold">Order Details:</span>
              <button
                onClick={() =>
                  window.open(open?.shopify_details?.order_detail_url, "_blank")
                }
                className="px-3 py-1 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                View
              </button>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}
