import React, { useState } from "react";
import "./shipperslist.css";

// components imports
import ShippersTable from "../../../../../../Components/BoltTable/BoltTable";
import InvoicesHistory from "../../InvoicesHistory/InvoicesHistory";
import ManageUserFormula from "../Components/ManageUserFormula/ManageUserFormula";
import ShipperDetails from "../Components/ShipperDetails/ShipperDetails";
import EditUser from "../../EditUser/EditUser";
import ChangePassword from "../../ChangePassword/ChangePassword";
import AddBalance from "../../AddBalance/AddBalance";
import PaymentsHistory from "../../PaymentsHistory/PaymentsHistory";
import Confirm from "../../../../../../Components/Popups/Confirm";
import Tooltip from "../../../../../../Components/Tooltip/Tooltip";
import SwitchButton from "../../../../../../Components/SwitchButton/SwitchButton";
import SubtractBalance from "../../SubtractBalance/SubtractBalance";
// apis imports
import {
  fetchUsers,
  updateServicecharge,
  updateAccountStatus,
  deleteUserByAdmin,
} from "../../../../../../services/AdminServices/Users/users.services";
// library imports
import { useSelector } from "react-redux";
import { IoInformationCircleOutline } from "react-icons/io5";
import { IconButton } from "@mui/material";
import CustomMenu from "../Components/ActionsDropDown/ActionsDropDown";

const ShippersList = ({ setSnack }) => {
  const user = useSelector((state) => state.user);

  const [shippers, setShippers] = useState([]);
  const [Change, setChange] = useState(false);
  const [invoicesHistoryModal, setInvoicesHistoryModal] = useState(false);
  const [formulaModal, setFormulaModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [addBalanceModal, setAddBalanceModal] = useState(false);
  const [subtractBalanceModal, setSubtractBalanceModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState();
  const [statusUpdating, setStatusUpdating] = useState();

  const fetchAllShippers = async (page, perPage, filter) => {
    const response = await fetchUsers(page, perPage, filter, "Shipper");
    if (!response.error) {
      setShippers(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "UserName",
      render: (row) => <>{row?.username ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Bussiness Name",
      render: (row) => <>{row?.business_name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Email",
      render: (row) => <>{row?.email ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Website",
      render: (row) => (
        <>
          {row?.website ? (
            <a
              href={
                row?.website.startsWith("http")
                  ? row?.website
                  : `http://${row?.website}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {row?.website}
            </a>
          ) : (
            "N/A"
          )}
        </>
      ),
      width: "200px",
    },
    {
      id: 5,
      label: "Markup",
      render: (row) => (
        <>
          {row?.markup_formula?.formula_details?.[0]?.value
            ? `${row.markup_formula.formula_details[0].value} %`
            : "Default"}
        </>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Balance",
      render: (row) => <>{row?.balance ?? "-"}</>,
      width: "200px",
    },
    {
      id: 7,
      label: "Shipment Count",
      render: (row) => <>{row?.shipments_count ?? "0"}</>,
      width: "200px",
    },
    {
      id: 8,
      label: (
        <>
          Activate
          <Tooltip title="Account Approved / Disapproved">
            <IconButton>
              <IoInformationCircleOutline size={10} />
            </IconButton>
          </Tooltip>
        </>
      ),
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleStatusChange(e, row)}
            checked={
              ![false, "false", "0", null, undefined].includes(
                row.account_status
              )
            }
            disabled={Change === "Status" ? true : false}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 9,
      label: (
        <>
          Service Charge
          <Tooltip title="Billing Activated / Deactivated">
            <IconButton>
              <IoInformationCircleOutline size={10} />
            </IconButton>
          </Tooltip>
        </>
      ),
      render: (row) => (
        <>
          <SwitchButton
            onChange={(e) => handleServiceCharge(e, row)}
            checked={
              ![false, "false", "0", null, undefined].includes(
                row?.is_service_charge
              )
            }
            disabled={Change === "Service" ? true : false}
          />
        </>
      ),
      width: "200px",
    },
    {
      id: 10,
      label: "Admin Actions",
      render: (row) => (
        <CustomMenu
          row={row}
          setAddBalanceModal={setAddBalanceModal}
          setSubtractBalanceModal={setSubtractBalanceModal}
          setChangePassword={setChangePassword}
          setEditUserModal={setEditUserModal}
          setDetailsModal={setDetailsModal}
          setFormulaModal={setFormulaModal}
          setInvoicesHistoryModal={setInvoicesHistoryModal}
          setHistoryModal={setHistoryModal}
          setConfirmDelete={setConfirmDelete}
        />
      ),
      width: "600px",
      fixed: "right",
    },
  ];

  const handleServiceCharge = async (e, record) => {
    setChange("Service");
    const updated = await updateServicecharge({
      id: parseInt(record.id),
      is_service_charge: e.target.checked,
    });

    if (updated.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
      setChange(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Billing of ${
            updated?.data?.result?.username ?? "user"
          } changed to ${
            ![false, "false", "0", null, undefined].includes(
              updated?.data?.result?.is_service_charge
            )
              ? "Active"
              : "Inactive"
          }`,
          severity: ![false, "false", "0", null, undefined].includes(
            updated?.data?.result?.is_service_charge
          )
            ? "success"
            : "info",
        };
      });
      setShippers((prevShippers) => {
        return {
          ...prevShippers,
          data: prevShippers?.data.map((item) =>
            item.id === record.id
              ? {
                  ...item,
                  is_service_charge: ![
                    false,
                    "false",
                    "0",
                    null,
                    undefined,
                  ].includes(updated?.data?.result?.is_service_charge),
                }
              : item
          ),
        };
      });
      setChange(false);
    }
  };

  const handleStatusChange = async (e, record) => {
    setChange("Status");
    const updated = await updateAccountStatus({
      id: parseInt(record.id),
      account_status: e.target.checked,
    });

    if (updated.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
      setChange(false);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${
            updated?.data?.result?.username ?? "user"
          } changed to ${
            ![false, "false", "0", null, undefined].includes(
              updated?.data?.result?.account_status
            )
              ? "Active"
              : "Inactive"
          }`,
          severity: ![false, "false", "0", null, undefined].includes(
            updated?.data?.result?.account_status
          )
            ? "success"
            : "info",
        };
      });
      setShippers((prevShippers) => {
        return {
          ...prevShippers,
          data: prevShippers?.data.map((item) =>
            item.id === record.id
              ? {
                  ...item,
                  account_status: ![
                    false,
                    "false",
                    "0",
                    null,
                    undefined,
                  ].includes(updated?.data?.result?.account_status),
                }
              : item
          ),
        };
      });
      setChange(false);
    }
  };

  const updateIndex = (id, formula, column) => {
    setFormulaModal({ ...formulaModal, [column]: formula });
    setShippers((prevShippers) => {
      return {
        ...prevShippers,
        data: prevShippers?.data.map((item) =>
          item.id === id ? { ...item, [column]: formula } : item
        ),
      };
    });
  };

  const handleDeleteUser = async (record) => {
    setStatusUpdating(true);
    const response = await deleteUserByAdmin(record.id);

    if (response.error) {
      setStatusUpdating(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    } else {
      setShippers((prevShippers) => {
        return {
          ...prevShippers,
          data: prevShippers?.data.filter((item) => item.id !== record.id),
        };
      });

      setStatusUpdating(false);
      setConfirmDelete(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `User Deleted Successfully `,
          severity: "success",
        };
      });
    }
  };

  return (
    <div>
      <InvoicesHistory
        open={invoicesHistoryModal}
        setOpen={setInvoicesHistoryModal}
        User={invoicesHistoryModal}
        setSnack={setSnack}
      />
      <ManageUserFormula
        open={formulaModal}
        setOpen={setFormulaModal}
        setSnack={setSnack}
        updateIndex={updateIndex}
      />
      <ShipperDetails
        open={!!detailsModal}
        data={detailsModal}
        setOpen={setDetailsModal}
        users={shippers}
        setUsers={setShippers}
        user={user}
        setSnack={setSnack}
        setDetailsModal={setDetailsModal}
      />
      <EditUser
        open={!!editUserModal}
        setOpen={setEditUserModal}
        previousData={editUserModal}
        setSnack={setSnack}
        setUsers={setShippers}
      />
      <ChangePassword
        open={!!changePassword}
        setOpen={setChangePassword}
        previousData={changePassword}
        setSnack={setSnack}
        setUsers={setShippers}
      />
      <AddBalance
        open={addBalanceModal}
        setOpen={setAddBalanceModal}
        setSnack={setSnack}
        updateUsers={setShippers}
      />
      <SubtractBalance
        open={subtractBalanceModal}
        setOpen={setSubtractBalanceModal}
        setSnack={setSnack}
        updateUsers={setShippers}
      />
      <PaymentsHistory
        open={historyModal}
        setOpen={setHistoryModal}
        setSnack={setSnack}
        User={historyModal}
      />
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteUser(confirmDelete)}
        text="Do you want to delete this user?"
        loader={statusUpdating}
      />
      <div>
        <div className="shippers_header">
          <div>
            <span className="shippers_heading">Shippers List</span>
          </div>
        </div>
        <div className="actionbtn__needtobe_fixwidth">
          <ShippersTable
            message={"No Shippers Available To Display!"}
            searchPlaceHolder="Search Shippers"
            columns={columns}
            data={shippers}
            getAllData={fetchAllShippers}
          />
        </div>
      </div>
    </div>
  );
};

export default ShippersList;
