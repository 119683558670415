import { createSlice } from "@reduxjs/toolkit";

const localState = JSON.parse(localStorage.getItem("user"));
const initialState = {}

export const userSlice = createSlice({
  name: "user",
  initialState: localState && localState !== null ? { ...localState, ...initialState } : initialState,
  reducers: {
    addUser: (state, action) => {
      const user = action.payload;
      // console.log(user);
      return user;
    },
    updateUser: (state, action) => {
      const { payload } = action;
      Object.entries(payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
});

// this is for dispatch
export const { addUser, updateUser } = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;
