import React, { useState } from "react";
import "./ProfileModal.css";
import RiseModal from "../../../RiseModal/RiseModal";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { IoLogOutOutline, IoSettings } from "react-icons/io5";
import { MdOutlineGTranslate } from "react-icons/md";

import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setDisabled } from "../../../../store/common/uiSlice";
const AdminProfileModal = ({ onClose, style }) => {
  const navigate = useNavigate();
  const [loggingOut, setLoggingOut] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleLogout = async () => {
    dispatch(setDisabled(true));
    setLoggingOut(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}logout`,
        method: "POST",
        timeout: 0,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("Token"),
        },
      });
      localStorage.clear();
      setLoggingOut(false);
      navigate("/Login");
    } catch (error) {
      localStorage.clear();
      setLoggingOut(false);
      navigate("/Login");
    } finally {
      dispatch(setDisabled(false));
    }
  };
  return (
    <RiseModal style={style} onClose={onClose}>
      <div className="profile_modal">
        <div className="profile_modal_title">Welcome {user.firstname}!</div>
        <Link className="profile_modal_item" to="#">
          <MdOutlineGTranslate size={20} className="profile_modal_icon" />
          <div>Language</div>
        </Link>
        <Link
          className="profile_modal_item"
          to={"admin/settings/table-pagination"}
        >
          <IoSettings size={20} className="profile_modal_icon" />
          <div>Settings</div>
        </Link>
        <hr />
        <div
          className={`profile_modal_item ${
            loggingOut && "profile_modal_itemdisabled"
          }`}
          f
          onClick={handleLogout}
        >
          <IoLogOutOutline size={20} className="profile_modal_icon" />
          <div>Logout</div>
          {!loggingOut && (
            <div className="version__badge">
              {process.env.React_APP_Version}
              <span></span>
            </div>
          )}
          <span className="pm_item_logoutloader">
            {" "}
            {loggingOut && <CircularProgress size={16} />}
          </span>
        </div>
      </div>
    </RiseModal>
  );
};

export default AdminProfileModal;
