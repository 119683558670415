import axios from "axios";
import React, { Fragment, useEffect, useState, useRef } from "react";
import SendReply from "../SendReply/SendReply";
import ProfileAvatar from "../../../../assets/images/profile-avatar.png";
import { useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { MdClose } from "react-icons/md";
const DiscussionBody = ({
  ticketId,
  ticketResponses,
  setTicketResponses,
  checkMediaAdded,
}) => {
  const divRef = useRef();
  const [open, setOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const userRole = localStorage.getItem("Role");
  const [enableScroll, setEnableScroll] = useState(false);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    setEnableScroll(true);
  }, [ticketId, ticketResponses]);

  if (
    ticketResponses?.length > 0 &&
    divRef?.current != null &&
    divRef?.current != "undefined"
  ) {
    divRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    return formattedDate;
  };

  const setModalImag = (img) => {
    setOpen(true);
    setModalImage(img);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="invoices__modal_wrapper">
            <div className="allTickets__shipment_image_modal">
              <button
                onClick={() => {
                  setOpen(false);
                  setModalImage(null);
                }}
              >
                <MdClose />
              </button>
              <img src={modalImage} alt="" />
            </div>
          </div>
        </Fade>
      </Modal>
      <div className="discussionBody__main_wrap">
        <div className="discussionBody__chats_box">
          {ticketResponses?.map((ticketResponses) => (
            <Fragment key={ticketResponses?.id}>
              {ticketResponses?.user?.role === userRole ? (
                <div className="discussionBody__chats_carrier">
                  <div className="discussionBody__chats_carrier_wrap">
                    <div className="discussionBody__chats_carrier_name_role">
                      <div className="discussionBody__chats_carrier_name_accr">
                        {ticketResponses?.user.firstname.charAt(0)}
                        {ticketResponses?.user.lastname.charAt(0)}
                      </div>
                      <div className="discussionBody__chats_carrier_name">
                        {ticketResponses?.user.firstname}{" "}
                        {ticketResponses?.user.lastname}
                      </div>
                      <div className="discussionBody__chats_carrier_role">
                        {ticketResponses?.user.role}
                      </div>
                    </div>
                    <div className="discussionBody__chats_carrier_queries">
                      <div className="discussionBody__chats_carrier_first_query">
                        {ticketResponses?.response_type === "text" ? (
                          <div className="text-child">
                            {ticketResponses?.response_body}
                          </div>
                        ) : ticketResponses?.response_type === "image" ? (
                          <div
                            className="discussionBody__chats_image"
                            onClick={() =>
                              setModalImag(
                                ticketResponses?.response_body?.file_url
                              )
                            }
                          >
                            <img
                              src={ticketResponses?.response_body?.file_url}
                              alt=""
                            />
                          </div>
                        ) : ticketResponses?.response_type === "file" ? (
                          <div className="discussionBody__chats_file">
                            <a
                              href={ticketResponses?.response_body?.file_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {ticketResponses?.response_body?.file_name}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="discussionBody__chats_carrier_query_time">
                        {formatDateTime(ticketResponses?.created_at)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="discussionBody__chats_recipient"
                  key={ticketResponses?.id}
                >
                  <div className="discussionBody__chats_recipient_wrap">
                    <div className="discussionBody__chats_recipient_name_role">
                      <div className="discussionBody__chats_recipient_name_accr">
                        {ticketResponses?.user?.firstname.charAt(0)}
                        {ticketResponses?.user?.lastname.charAt(0)}
                      </div>
                      <div className="discussionBody__chats_recipient_name">
                        {ticketResponses?.user?.firstname}{" "}
                        {ticketResponses?.user?.lastname}
                      </div>
                      <div className="discussionBody__chats_recipient_role">
                        {ticketResponses?.user?.role}
                      </div>
                    </div>

                    <div className="discussionBody__chats_recipient_queries">
                      <div className="discussionBody__chats_recipient_first_query">
                        {ticketResponses?.response_type === "text" ? (
                          <div className="text-child">
                            {ticketResponses?.response_body}
                          </div>
                        ) : ticketResponses?.response_type === "image" ? (
                          <div
                            className="discussionBody__chats_image"
                            onClick={() =>
                              setModalImag(
                                ticketResponses?.response_body?.file_url
                              )
                            }
                          >
                            <img
                              src={ticketResponses?.response_body?.file_url}
                              alt=""
                            />
                          </div>
                        ) : ticketResponses?.response_type === "file" ? (
                          <div className="discussionBody__chats_file">
                            <a
                              href={ticketResponses?.response_body?.file_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {ticketResponses?.response_body?.file_name}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="discussionBody__chats_recipient_query_time">
                        {formatDateTime(ticketResponses?.created_at)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          ))}
          <div ref={divRef} />
        </div>

        <div className="discussionBody__chats_form">
          <div className="account_role_box_main">
            {user.profile_image &&
            user.profile_image.split("/").pop() !== "undefined" ? (
              <img
                src={`${user?.profile_image}`}
                alt="Profile"
                className="profile__avatar_image 1"
              />
            ) : (
              <img
                src={ProfileAvatar}
                alt=""
                className="profile__avatar_image 2"
              />
            )}
            <div className="account_role_box_roleText">{user?.role}</div>
            <div className="account_role_box_firstName">{user.firstname}</div>
          </div>
          <SendReply
            ticketId={ticketId}
            checkMediaAdded={checkMediaAdded}
            setTicketResponses={setTicketResponses}
            ticketResponses={ticketResponses}
            divRef={divRef}
          />
        </div>
      </div>
    </>
  );
};
export default DiscussionBody;
