import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import "./AssignPickup.css";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { arrangePickup } from "../../../../../../../services/shipments.services";
import { MdOutlineErrorOutline } from "react-icons/md";
import { updateUser } from "../../../../../../../store/userSlice";
import CircularLoader from "../../../../../../../Components/CircularLoader/CircularLoader";
import { showSnackBar } from "../../../../../../../store/common/snackBarSlice";

const keys = {
  readyopentime: "Pickup Open Time",
  readyclosetime: "Pickup Close Time",
  shipdate: "Pickup Date",
};

export default function AssignPickup({
  open,
  setOpen,
  StepCheck,
  SetStepCheck,
}) {
  const dispatch = useDispatch();
  const [FinalData, setFinalData] = useState(StepCheck["FinalData"]);
  const [data, setData] = useState({
    shipdate: FinalData?.shipdate,
    readyopentime: FinalData?.readyopentime ?? "",
    readyclosetime: FinalData?.readyclosetime ?? "",
  });
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(true);
  const [error, setError] = useState(false);
  const handleClose = () => {
    setData({
      shipdate: FinalData?.shipdate,
      readyopentime: FinalData?.readyopentime ?? "",
      readyclosetime: FinalData?.readyclosetime ?? "",
    });
    setError(false);
    setOpen(false);
  };

  const user = useSelector((state) => state.user);
  const handleChange = (value, name, type) => {
    // console.log(value);
    const val =
      type === "date"
        ? `${value.$y}-${addZeroToDate(value.$M + 1)}-${addZeroToDate(
            value.$D
          )}`
        : value.target.value;
    if (type === "date") {
      setData({ ...data, [name]: `${val}` });
    } else {
      setData({ ...data, [name]: `${val}:00` });
    }
  };
  const updateBalance = (newBal) => {
    const lsuser = JSON.parse(localStorage.getItem("user"));
    dispatch(updateUser({ balance: newBal }));
    localStorage.setItem(
      "user",
      JSON.stringify({ ...lsuser, balance: newBal })
    );
    localStorage.setItem("Balance", newBal);
  };
  const addZeroToDate = (d) => {
    const date = d.toString();
    return date.length === 1 ? "0" + date : date;
  };

  const handleSave = async () => {
    setError(false);
    setLoading(true);
    for (const key in data) {
      if (data[key] === "") {
        setError(`${keys[key]} is required!`);
        setLoading(false);
        return;
      } else if (data[key].includes("NaN")) {
        setError(`${keys[key]} is Invalid!`);
        setLoading(false);
        return;
      }
    }
    const openTime =
      data.readyopentime.length === 5
        ? data.readyopentime + ":00"
        : data.readyopentime;
    const closeTime =
      data.readyclosetime.length === 5
        ? data.readyclosetime + ":00"
        : data.readyclosetime;
    const saved = await arrangePickup(
      user.token,
      FinalData?.shipping_company_id,
      FinalData?.id,
      {
        ...data,
        readyopentime: openTime.replace(/-/g, ":"),
        readyclosetime: closeTime.replace(/-/g, ":"),
      }
    );
    if (saved?.error || !saved?.data?.success) {
      setError(saved?.error ?? "Something went wrong!");
    } else {
      SetStepCheck((prevStepCheck) => ({
        ...prevStepCheck, // Copy the previous state
        FinalData: {
          ...prevStepCheck.FinalData, // Copy the previous FinalData object
          PickupDate: data?.shipdate?.slice(0, 10),
          readyopentime: data?.readyopentime?.slice(0, 5).replace(/-/g, ":"),
          readyclosetime: data?.readyclosetime?.slice(0, 5).replace(/-/g, ":"),
          PickupID: saved?.data?.result?.PickupID,
        },
      }));
      updateBalance(saved?.data?.result?.balance);
      dispatch(
        showSnackBar({
          text: "Pickup Arranged successfully",
          severity: "success",
        })
      );
      setData({
        shipdate: FinalData?.shipdate,
        readyopentime: FinalData?.readyopentime ?? "",
        readyclosetime: FinalData?.readyclosetime ?? "",
      });
      handleClose();
    }
    setLoading(false);
  };

  useEffect(() => {
    setData({
      ...data,
      shipdate: FinalData?.shipdate,
      readyopentime: FinalData?.readyopentime ?? "",
      readyclosetime: FinalData?.readyclosetime ?? "",
    });
  }, [StepCheck["FinalData"]]);

  useEffect(() => {
    setError(false);
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className="assign__pickup_modal">
          <div className="assign__pickup_modalheading">
            You have to arrange pickup for this{" "}
            {FinalData?.shipping_company?.shipping_company?.name} shipment
          </div>
          <div className="assign__pickup_modalcontent">
            {FinalData?.shipping_company?.shipping_company?.name === "UPS" && (
              <div
                className="assign__pickup_modalerrorwrap assign__pickup_modalerrorwrapopen"
                style={{
                  overflow: "hidden",
                }}
              >
                <div
                  className="assign__pickup_modalerrorbox"
                  style={{
                    backgroundColor: "#bbdefb",
                    color: "#2196f3",
                  }}
                >
                  <span>
                    <MdOutlineErrorOutline
                      size={16}
                      style={{ marginTop: "2px" }}
                    />
                  </span>
                  Please note that the UPS Basic pickup charge will be added to
                  the invoice. Additional fees apply for residential addresses.
                </div>
              </div>
            )}
            <div className="assign__pickup_modalrow">
              <div className="assign__pickup_modalinputwrap">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DemoItem label="Pickup Date">
                      <DatePicker
                        defaultValue={dayjs(data?.shipdate?.slice(0, 10))}
                        views={["year", "month", "day"]}
                        onChange={(value) =>
                          handleChange(value, "shipdate", "date")
                        }
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <div className="assign__pickup_modalrow">
              <div className="assign__pickup_modalinputwrap">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      "TimePicker",
                      "DesktopTimePicker",
                      "StaticTimePicker",
                    ]}
                  >
                    <DemoItem label="Pickup Open Time">
                      <TextField
                        onChange={(value) =>
                          handleChange(value, "readyopentime")
                        }
                        value={data?.readyopentime}
                        type="time"
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="assign__pickup_modalinputwrap">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={[
                      "TimePicker",
                      "DesktopTimePicker",
                      "StaticTimePicker",
                    ]}
                  >
                    <DemoItem label="Pickup Close Time">
                      <TextField
                        name="readyclosetime"
                        onChange={(value) =>
                          handleChange(value, "readyclosetime")
                        }
                        value={data?.readyclosetime}
                        type="time"
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>

            <div
              className={`assign__pickup_modalerrorwrap ${
                error && "assign__pickup_modalerrorwrapopen"
              }`}
            >
              <div className="assign__pickup_modalerrorbox">
                <span>
                  <MdOutlineErrorOutline
                    size={16}
                    style={{ marginTop: "2px" }}
                  />
                </span>
                {error}
              </div>
            </div>
          </div>
          <div className="assign__pickup_modalfooter">
            <div
              className="assign_shipage_pickup_modalcncl"
              onClick={handleClose}
            >
              Arrange Later
            </div>
            <div
              className={`assign__pickup_modalsave  ${
                !validated && "assign__pickup_modalsavedisabled"
              }`}
              style={{ minWidth: "fit-content", padding: "5px 10px" }}
              onClick={handleSave}
            >
              {loading ? <CircularLoader /> : "Book Pickup"}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
