import React from "react";
import { fixedDecimals } from "../../../../helpers/helpers";

const ShipmentChargesTable = ({
  data,
  taxCharges,
  additionalCharges,
  newCharge,
  setNewTaxModal,
  setAdditionalChargeModal,
  ChargeDifference,
  newChargeData,
  newTaxData,
  additionalChargesData,
  finaltotaldifference,
  handleChargeChange,
  handleAdditionalChargeChange,
  finalTotal,
  finaltotaldata,
  handleTaxChange,
  modifyInvoice,
  findTaxChargeByNameEn,
  findAdditionalChargeByNameEn,
  calculateServiceChargePercentage,
}) => {
  const hasInsurance = !!data?.insurance;
  const hasServiceCharge = parseFloat(data?.service_charge ?? "0") > 0;
  const hasShipmentCharges = !!data?.shipment_charges;

  return (
    <div className="overflow-x-auto">
      <table className="w-full min-w-max mb-2 text-[10px] md:text-xxs lg:text-xs xl:text-sm font-medium">
        <thead>
          <tr>
            <th className="p-1 text-center font-semibold">#</th>
            <th className="p-1 text-center font-semibold">Base</th>
            <th className="p-1 text-center font-semibold">Fuel</th>
            <th className="p-1 text-center font-semibold">Freight Charge</th>
            <th className="p-1 text-center font-semibold">Signature</th>
            <th className="p-1 text-center font-semibold">Residential</th>
            <th className="p-1 text-center font-semibold">Pickup Charge</th>
            {hasInsurance && (
              <th className="p-1 text-center font-semibold">
                Insurance Charge
              </th>
            )}
            <th className="p-1 text-center font-semibold">Sub Total</th>
            {hasShipmentCharges &&
              taxCharges?.map((tax, index) => (
                <th className="p-1 text-center font-semibold" key={index}>
                  {tax?.NameEn}
                </th>
              ))}
            {hasShipmentCharges &&
              additionalCharges?.map((charge, index) => (
                <th className="p-1 text-center font-semibold" key={index}>
                  {charge?.NameEn}
                </th>
              ))}
            {newCharge && (
              <th className="p-1 text-center font-medium">
                <button
                  className="text-xxs font-medium bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                  onClick={() => setNewTaxModal(true)}
                >
                  New Tax
                </button>
                <button
                  className="text-xxs font-medium bg-green-500 text-white px-2 py-1 rounded ml-2 hover:bg-green-600"
                  onClick={() => setAdditionalChargeModal(true)}
                >
                  New Charge
                </button>
              </th>
            )}
            {hasServiceCharge && (
              <th className="p-1 text-center font-semibold">Service Charge</th>
            )}
            {hasServiceCharge && (
              <th className="p-1 text-center font-semibold">Carrier Total</th>
            )}
            <th className="p-1 text-center font-semibold">Total</th>
          </tr>
        </thead>
        <tbody>
          {data?.shipment_charges?.map((charge, index) => (
            <tr key={index}>
              <td className="p-1 text-center">{index + 1}</td>
              {[
                "base_price",
                "fuel_charge",
                "freight_charge",
                "signature_charge",
                "residential_charge",
                "PickupCharge",
                ...(hasInsurance ? ["insurance_charge"] : []), // Ensuring conditional inclusion
                "sub_total",
              ].map((key) => (
                <td key={key} className="p-1 text-center">
                  {charge?.[key] && charge?.[key] !== 0 ? charge?.[key] : "-"}
                </td>
              ))}
              {hasShipmentCharges &&
                taxCharges?.map((tax, idx) => (
                  <td className="p-1 text-center" key={idx}>
                    {findTaxChargeByNameEn(tax?.NameEn, index)?.Charge ?? "-"}
                  </td>
                ))}
              {hasShipmentCharges &&
                additionalCharges?.map((charge, idx) => (
                  <td className="p-1 text-center" key={idx}>
                    {findAdditionalChargeByNameEn(charge?.NameEn, index)
                      ?.Charge ?? "-"}
                  </td>
                ))}
              {newCharge && <td className="p-1"></td>}
              {hasServiceCharge && (
                <td className="p-1 text-center">
                  {index === 0
                    ? `${parseFloat(data?.service_charge ?? "0").toFixed(
                        2
                      )} (${calculateServiceChargePercentage(
                        charge?.total,
                        parseFloat(data?.service_charge ?? "0")
                      )} %)`
                    : "-"}
                </td>
              )}
              {hasServiceCharge && (
                <td className="p-1 text-center">
                  {index === 0
                    ? (
                        parseFloat(charge?.total) -
                        parseFloat(data?.service_charge)
                      ).toFixed(2)
                    : "-"}
                </td>
              )}
              <td className="p-1 text-center">
                {charge?.total && charge?.total !== 0
                  ? fixedDecimals(charge?.total)
                  : "-"}
              </td>
            </tr>
          ))}
          {newCharge && (
            <>
              <tr>
                <td className="p-1 text-center shadow rounded">Diff</td>
                {[
                  "base_price",
                  "fuel_charge",
                  "freight_charge",
                  "signature_charge",
                  "residential_charge",
                  "PickupCharge",
                  ...(hasInsurance ? ["insurance_charge"] : []), // Ensuring conditional inclusion
                  "sub_total",
                ].map((key) => (
                  <td key={key} className="p-1 text-center">
                    <ChargeDifference
                      previousCharge={0}
                      newCharge={newChargeData?.[key]}
                    />
                  </td>
                ))}
                {hasShipmentCharges &&
                  taxCharges?.map((tax, idx) => (
                    <td className="p-1 text-center" key={idx}>
                      <ChargeDifference
                        previousCharge={0}
                        newCharge={newTaxData[tax?.NameEn]}
                      />
                    </td>
                  ))}
                {hasShipmentCharges &&
                  additionalCharges?.map((charge, idx) => (
                    <td className="p-1 text-center" key={idx}>
                      <ChargeDifference
                        previousCharge={0}
                        newCharge={additionalChargesData[charge?.NameEn]}
                      />
                    </td>
                  ))}
                {newCharge && <td className="p-1"></td>}
                {hasServiceCharge && (
                  <>
                    <td className="p-1 text-center"></td>
                    <td className="p-1 text-center"></td>
                  </>
                )}
                <td className="p-1 text-center">
                  <ChargeDifference
                    previousCharge={0}
                    newCharge={finaltotaldifference}
                  />
                </td>
              </tr>

              <tr>
                <td className="p-1 text-center">
                  {data?.shipment_charges?.length + 1}
                </td>
                {[
                  "base_price",
                  "fuel_charge",
                  "freight_charge",
                  "signature_charge",
                  "residential_charge",
                  "PickupCharge",
                  ...(hasInsurance ? ["insurance_charge"] : []), // Ensuring conditional inclusion
                  "sub_total",
                ].map((key) => (
                  <td key={key} className="p-1 text-center">
                    <input
                      type="text"
                      className="w-14 px-1 py-0.5 border border-gray-300 text-xs text-center rounded focus:outline-none focus:ring focus:ring-blue-300"
                      onChange={(e) =>
                        handleChargeChange(newCharge?.data?.[key], e)
                      }
                      name={key}
                    />
                  </td>
                ))}
                {hasShipmentCharges &&
                  taxCharges?.map((tax, idx) => (
                    <td className="p-1 text-center" key={idx}>
                      <input
                        type="text"
                        className="w-14 px-1 py-0.5 border border-gray-300 text-xs text-center rounded focus:outline-none focus:ring focus:ring-blue-300"
                        value={newTaxData[tax.NameEn]}
                        name={tax.NameEn}
                        onChange={(e) => handleTaxChange(tax.Charge, e)}
                      />
                    </td>
                  ))}
                {hasShipmentCharges &&
                  additionalCharges?.map((charge, idx) => (
                    <td className="p-1 text-center" key={idx}>
                      <input
                        type="text"
                        className="w-14 px-1 py-0.5 border border-gray-300 text-xs text-center rounded focus:outline-none focus:ring focus:ring-blue-300"
                        value={additionalChargesData[charge.NameEn]}
                        name={charge.NameEn}
                        onChange={(e) =>
                          handleAdditionalChargeChange(charge.Charge, e)
                        }
                      />
                    </td>
                  ))}
                {newCharge && <td className="p-1"></td>}
                <td className="p-1"></td>
                <td className="p-1"></td>
              </tr>

              <tr>
                <td className="p-1 text-center shadow rounded">Total</td>
                {[
                  "base_price",
                  "fuel_charge",
                  "freight_charge",
                  "signature_charge",
                  "residential_charge",
                  "PickupCharge",
                  ...(hasInsurance ? ["insurance_charge"] : []), // Ensuring conditional inclusion
                  "sub_total",
                ].map((key) => (
                  <td key={key} className="p-1 text-center">
                    {finalTotal?.[key] ?? "-"}
                  </td>
                ))}
                {hasShipmentCharges &&
                  taxCharges?.map((tax, idx) => (
                    <td className="p-1 text-center" key={idx}>
                      {finalTotal[tax.NameEn] ?? "-"}
                    </td>
                  ))}
                {hasShipmentCharges &&
                  additionalCharges?.map((charge, idx) => (
                    <td className="p-1 text-center" key={idx}>
                      {finalTotal[charge.NameEn] ?? "-"}
                    </td>
                  ))}
                {newCharge && <td className="p-1"> </td>}
                {hasServiceCharge && (
                  <>
                    <td className="p-1 text-center"></td>
                    <td className="p-1 text-center"></td>
                  </>
                )}
                <td className="p-1 text-center">
                  {finaltotaldata ? fixedDecimals(finaltotaldata) : "-"}
                </td>
              </tr>
            </>
          )}
          {modifyInvoice && (
            <>
              <tr>
                <td className="p-1 text-center">
                  {data?.shipment_charges?.length + 1}
                </td>
                {[
                  "base_price",
                  "fuel_charge",
                  "freight_charge",
                  "signature_charge",
                  "residential_charge",
                  "PickupCharge",
                  ...(hasInsurance ? ["insurance_charge"] : []), // Ensuring conditional inclusion
                  "sub_total",
                ].map((key) => (
                  <td key={key} className="p-1 text-center">
                    <input
                      type="text"
                      className="w-14 px-1 py-0.5 border border-gray-300 text-xs text-center rounded focus:outline-none focus:ring focus:ring-blue-300"
                      onChange={(e) =>
                        handleChargeChange(modifyInvoice?.data?.[key], e)
                      }
                      value={newChargeData?.[key]}
                      name={key}
                    />
                  </td>
                ))}
                {hasShipmentCharges &&
                  taxCharges?.map((tax, idx) => (
                    <td key={idx} className="p-1 text-center">
                      <input
                        type="text"
                        className="w-14 px-1 py-0.5 border border-gray-300 text-xs text-center rounded focus:outline-none focus:ring focus:ring-blue-300"
                        value={newTaxData[tax.NameEn]}
                        name={tax.NameEn}
                        onChange={(e) => handleTaxChange(tax.Charge, e)}
                      />
                    </td>
                  ))}
                {hasShipmentCharges &&
                  additionalCharges?.map((charge, idx) => (
                    <td key={idx} className="p-1 text-center">
                      <input
                        type="text"
                        className="w-14 px-1 py-0.5 border border-gray-300 text-xs text-center rounded focus:outline-none focus:ring focus:ring-blue-300"
                        value={additionalChargesData[charge.NameEn]}
                        name={charge.NameEn}
                        onChange={(e) =>
                          handleAdditionalChargeChange(charge.Charge, e)
                        }
                      />
                    </td>
                  ))}
                {hasServiceCharge && (
                  <>
                    <td className="p-1 text-center"></td>
                    <td className="p-1 text-center"></td>
                  </>
                )}
                <td className="p-1 text-center">
                  <input
                    type="text"
                    className="w-14 px-1 py-0.5 border border-gray-300 text-xs text-center rounded focus:outline-none focus:ring focus:ring-blue-300"
                    onChange={(e) =>
                      handleChargeChange(modifyInvoice?.data?.total, e)
                    }
                    value={newChargeData?.total ?? 0}
                    name="total"
                  />
                </td>

                <td className="p-1 text-center"></td>
              </tr>

              <tr>
                <td className="p-1 text-center shadow rounded">Total</td>
                {[
                  "base_price",
                  "fuel_charge",
                  "freight_charge",
                  "signature_charge",
                  "residential_charge",
                  "PickupCharge",
                  ...(hasInsurance ? ["insurance_charge"] : []), // Ensuring conditional inclusion
                  "sub_total",
                ].map((key) => (
                  <td key={key} className="p-1 text-center">
                    {finalTotal?.[key] ?? "-"}
                  </td>
                ))}
                {hasShipmentCharges &&
                  taxCharges?.map((tax, idx) => (
                    <td className="p-1 text-center" key={idx}>
                      {finalTotal[tax.NameEn] ?? "-"}
                    </td>
                  ))}
                {hasShipmentCharges &&
                  additionalCharges?.map((charge, idx) => (
                    <td className="p-1 text-center" key={idx}>
                      {finalTotal[charge.NameEn] ?? "-"}
                    </td>
                  ))}
                {hasServiceCharge && (
                  <>
                    <td className="p-1 text-center"></td>
                    <td className="p-1 text-center"></td>
                  </>
                )}
                <td className="p-1 text-center">{finalTotal?.total ?? 0}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ShipmentChargesTable;
