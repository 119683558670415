import React, { useState, useEffect } from "react";
import "./orders.css";
import OrdersTable from "../../../Components/BoltTable/BoltTable";
import OrderDetails from "./Components/OrderDetails/OrderDetails";
import FullfilmentTotalDetailsModal from "../../../Pages/Shipper/Customer/MainPages/Orders/Components/FullfilmentTotalDetails/FullfilmentTotalDetails";
import DetailsModal from "./Components/OrderDetails/DetailsModal";
import InvoiceModal from "../../../Components/InvoiceModal/InvoiceModal";
import { fetchAllWarehouseOrders } from "../../../services/warehouse.services";
import CircularProgress from "@mui/material/CircularProgress";
import OrderHistoryModal from "../../Shipper/Customer/MainPages/Orders/Components/OrderHistoryModal/OrderHistoryModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import Snackbar from "../../../Components/SnackBar/SnackBar";
import noImage from "../../../assets/images/noImage.png";
import Image from "../../../assets/images/photo.png";
import pdfIcon from "../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../assets/icons/excelIcon.png";
import docsIcon from "../../../assets/icons/docsIcon.png";
import { checkFileType } from "../../../helpers/helpers";
import AttachmentsModal from "./Components/AttachmentsModal/AttachmentsModal";
import StatusIcons from "../../../Components/StatusIcons/StatusIcons";
import ActionDropDown from "./Components/ActionDropDown/ActionDropDown";
import AddImage from "../../../assets/images/add-image.png";
import { getUserWarehousesByWar_Id } from "../../../services/warehouse.services";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import ExpandableBox from "../../../Components/Common/ExpandableBox";

const WarehouseOrder = () => {
  // new states
  const [orders, setOrders] = useState([]);
  const [prodHistoryModal, setProdHistoryModal] = useState(false);
  const [fulBillingDetailsModal, setFulBillingDetailsModal] = useState(false);
  const [imagesModal, setImagesModal] = useState();
  const [clients, setClients] = useState([]);
  const [details, setDetails] = useState(false);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  function isCancelled(status) {
    const lowerCaseStatus = status ? status.toLowerCase() : null;
    return lowerCaseStatus === "cancelled";
  }

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 1,
      label: "Client Name",
      field_name: "username",
      render: (row) => (
        <>
          <div className="custom__spaces_content_boltTable">
            {row?.user?.username ?? "-"}
          </div>
        </>
      ),
      searchable: true,
      fixed: "left",
      headerColor: "#0a3e7a",
      headerTextColor: "#fff",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 2,
      label: "Order ID",
      field_name: "order_id",
      searchable: true,
      render: (row) => <>{row?.order_id ?? "-"}</>,
      headerTextColor: "#fff",
      headerColor: "#0a3e7a",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 4,
      label: "Type",
      field_name: "type",
      searchable: true,
      render: (row) => <div>{row?.type ?? "-"}</div>,
      headerTextColor: "#fff",
      headerColor: "#0a3e7a",
      width: "100px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 3,
      label: "Customer Name",
      field_name: "first_name",
      searchable: true,
      render: (row) => (
        <>
          <div className="custom__spaces_content_boltTable">
            {row?.order_shipping_address_details?.first_name ?? "-"}
          </div>
        </>
      ),
      headerTextColor: "#fff",
      headerColor: "#0a3e7a",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    // {
    //   id: 4,
    //   label: <div>Payment Method</div>,
    //   field_name: "payment_method_title",
    //   render: (row) => (
    //     <div
    //
    //
    //     >
    //       {row?.payment_method_title ?? "-"}
    //     </div>
    //   ),
    //   editable: true,
    // disabled: (row) => isCancelled(row?.status),
    //
    // },
    {
      id: 5,
      label: "Order Status",
      field_name: "status",
      searchable: true,
      render: (row) => <StatusIcons status={row?.status} />,
      headerTextColor: "#fff",
      headerColor: "#0a3e7a",
      width: "150px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 6,
      label: "Shipment Status",
      field_name: "shipment_status",
      searchable: true,
      render: (row) => (
        <>
          {row?.shipment && (
            <>
              <StatusIcons status={row?.shipment?.shipment_status} />
            </>
          )}
          {row?.manual_shipment && (
            <>
              <StatusIcons status={row?.manual_shipment?.shipment_status} />
            </>
          )}
        </>
      ),
      headerTextColor: "#002859",
      headerColor: "#a8e2f6",
      width: "150px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 7,
      label: "Carrier",
      field_name: "shipping_company",
      render: (row) => (
        <>
          {row?.shipment && (
            <>
              <img
                src={
                  row?.shipment?.shipping_company?.shipping_company?.logo_url ??
                  noImage
                }
                onError={(e) => {
                  e.target.src = noImage;
                }}
                loading="lazy"
              />
            </>
          )}
          {row?.manual_shipment?.shipping_company?.shipping_company && (
            <>
              <img
                src={
                  row?.manual_shipment?.shipping_company?.shipping_company
                    ?.logo_url ?? noImage
                }
                onError={(e) => {
                  e.target.src = noImage;
                }}
                loading="lazy"
              />
            </>
          )}
          {row?.manual_shipment?.manual_shipping_company && (
            <>
              {row?.manual_shipment?.manual_shipping_company
                ?.shipping_company_name ?? ""}
            </>
          )}
        </>
      ),
      headerTextColor: "#002859",
      headerColor: "#a8e2f6",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 15,
      label: "Tracking",
      field_name: "shipment_tracking_id",
      searchable: true,
      render: (row) => (
        <>
          {row?.shipment && <>{row?.shipment?.shipment_tracking_id ?? ""}</>}
          {row?.manual_shipment && (
            <>{row?.manual_shipment?.shipment_tracking_id ?? ""}</>
          )}
        </>
      ),
      headerTextColor: "#002859",
      headerColor: "#a8e2f6",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 8,
      label: "Fulfilment Status",
      field_name: "fulfillment_status",
      searchable: true,
      render: (row) => <StatusIcons status={row?.fullfilment_status?.name} />,
      headerTextColor: "#fff",
      headerColor: "#002958",
      width: "170px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 9,
      label: "Fulfilment Total",
      field_name: "fulfillment_cost",
      render: (row) => (
        <div>
          <Tooltip
            title={
              <div
                className="package__name_list_wrap fulfilment_cost_tooltip_content"
                style={{ border: "1px solid #d3d3d3", padding: "5px" }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                  }}
                >
                  <strong className="fulfilmentcost__headers">
                    Fulfilment Cost{" "}
                  </strong>{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    fontSize: "14px",
                  }}
                >
                  <label>
                    <strong>
                      {row?.formula?.["UnitType"] === "CBM"
                        ? "Volume"
                        : "Weight"}
                      : ({row?.formula?.["TotalFulfilmentWeight"]}{" "}
                      {row?.formula?.["UnitType"]})
                    </strong>
                  </label>
                  <span>
                    <strong>{row?.formula?.["TotalWeightCost"]} CAD </strong>
                  </span>
                </div>
                {row?.formula?.["TotalPreProduct"] > 0 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        fontSize: "14px",
                      }}
                    >
                      <strong className="fulfilmentcost__headers">
                        Pre Cost Item Quantity: (
                        {row?.formula?.["TotalPreProduct"]})
                      </strong>
                      {/* <span>{row?.formula?.["TotalPreCost"]} CAD </span> */}
                    </div>
                    {row?.order_details
                      ?.filter(
                        (singlelist) => singlelist?.products?.fulfillment_cost
                      )
                      .map((list, index) => (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            fontSize: "14px",
                          }}
                          key={index} // It's a good 5practice to provide a unique key for each mapped element
                        >
                          <label>
                            {list.product_name} ({list.item_quantity} x{" "}
                            {list.products?.fulfillment_cost})
                          </label>
                          <span>
                            {list.products?.fulfillment_cost *
                              list.item_quantity}{" "}
                            CAD{" "}
                          </span>
                        </div>
                      ))}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                        fontSize: "14px",
                      }}
                    >
                      <label>
                        {/* <strong>({row?.formula?.["TotalPreProduct"]})</strong> */}
                      </label>
                      <span>
                        <strong>{row?.formula?.["TotalPreCost"]} CAD</strong>{" "}
                      </span>
                    </div>
                  </>
                )}
                {row?.additional_costs?.length > 0 && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        fontSize: "14px",
                      }}
                    >
                      <strong className="fulfilmentcost__headers">
                        Additional Cost: ({row?.additional_costs?.length})
                      </strong>
                      {/* <span>{row?.formula?.["TotalPreCost"]} CAD </span> */}
                    </div>
                    {row?.additional_costs?.map((list, index) => (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          fontSize: "14px",
                        }}
                        key={index} // It's a good 5practice to provide a unique key for each mapped element
                      >
                        <label>{list?.cost_type?.name}</label>
                        <span>{list?.cost} CAD </span>
                      </div>
                    ))}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "end",
                        fontSize: "14px",
                      }}
                    >
                      {/* <label>
    <strong>Total Items Fulfilment: ({row?.formula?.["TotalPreProduct"]})</strong>
    </label> */}
                      <span>
                        <strong
                          style={{
                            borderTop: "1px solid black",
                          }}
                        >
                          {calculateTotalAdditionalCost(row)} CAD
                        </strong>{" "}
                      </span>
                    </div>
                    <hr />
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    fontSize: "14px",
                  }}
                >
                  <label>
                    <strong>Total Fulfilment COST: </strong>
                  </label>
                  <span>
                    <strong>
                      {/* {parseFloat(row?.formula?.["TotalFulfilmentCost"]) + */}
                      {calculateTotalCost(row)} CAD{" "}
                    </strong>
                  </span>
                </div>
              </div>
            }
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setFulBillingDetailsModal(row)}
            >
              {calculateTotalCost(row) + "CAD" ?? "-"}
            </div>
          </Tooltip>
        </div>
      ),
      headerTextColor: "#fff",
      headerColor: "#002958",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 17,
      label: "Fulfilment Billing",
      field_name: "fulfillment_billing_status",
      searchable: true,
      render: (row) => <StatusIcons status={row?.fulfillment_billing_status} />,
      headerTextColor: "#fff",
      headerColor: "#002958",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    // {
    //   id: 10,
    //   label: "Attachments",
    //   field_name: "shipper_attachments",
    //   render: (row) => (
    //     <div className="shipper__attachments_thumbnail_wrap">
    //       {row?.attachments?.length === 0 ? (
    //         <img
    //           src={AddImage}
    //           // key={index}
    //           loading="lazy"
    //           onClick={() => setImagesModal(row.id)}
    //           onError={(e) => {
    //             e.target.src = noImage;
    //           }}
    //         />
    //       ) : (
    //         <img
    //           src={
    //             checkFileType(row?.attachments[0]?.file) === "Image"
    //               ? row?.attachments[0]?.file
    //               : checkFileType(row?.attachments[0]?.file) === "PDF"
    //               ? pdfIcon
    //               : checkFileType(row?.attachments[0]?.file) === "Excel"
    //               ? excelIcon
    //               : checkFileType(row?.attachments[0]?.file) === "Document"
    //               ? docsIcon
    //               : noImage
    //           }
    //           // key={index}
    //           loading="lazy"
    //           onClick={() => setImagesModal(row.id)}
    //           onError={(e) => {
    //             e.target.src = noImage;
    //           }}
    //         />
    //       )}
    //     </div>
    //   ),
    //   headerTextColor: "#fff",
    //   headerColor: "#002958",
    //   width: "200px",
    //   disabled: (row) => isCancelled(row?.status),
    // },
    {
      id: 110,
      label: "Attachments",
      field_name: "shipper_attachments",
      render: (row) => (
        <div className="shipper__attachments_thumbnail_wrap">
          {row?.attachments?.length === 0 ? (
            <img
              src={AddImage}
              // key={index}
              loading="lazy"
              onClick={() => setImagesModal(row.id)}
              onError={(e) => {
                e.target.src = noImage;
              }}
            />
          ) : (
            <img
              src={
                checkFileType(row?.attachments[0]?.file) === "Image"
                  ? Image
                  : checkFileType(row?.attachments[0]?.file) === "PDF"
                  ? pdfIcon
                  : checkFileType(row?.attachments[0]?.file) === "Excel"
                  ? excelIcon
                  : checkFileType(row?.attachments[0]?.file) === "Document"
                  ? docsIcon
                  : noImage
              }
              // key={index}
              loading="lazy"
              onClick={() => setImagesModal(row.id)}
              onError={(e) => {
                e.target.src = noImage;
              }}
            />
          )}
        </div>
      ),
      headerTextColor: "#fff",
      headerColor: "#002958",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    // {
    //   id: 11,
    //   label: <div>Amount Pending</div>,
    //   field_name: "amount_pending",
    //   render: (row) => (
    //     <div
    //
    //
    //     >
    //       {row?.amount_pending ?? "-"}
    //     </div>
    //   ),
    //   editable: true,
    // disabled: (row) => isCancelled(row?.status),
    //
    // },
    // {
    //   id: 12,
    //   label: <div>Amount Received</div>,
    //   field_name: "amount_received",
    //   render: (row) => (
    //     <div
    //
    //
    //     >
    //       {row?.amount_received ?? "-"}
    //     </div>
    //   ),
    //   editable: true,
    // disabled: (row) => isCancelled(row?.status),
    //
    // },

    {
      id: 13,
      label: "Created At",
      field_name: "created_at",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 14,
      label: "Actions",
      field_name: "Actions",
      render: (row) => (
        <ActionDropDown
          setdetailModal={setdetailModal}
          setProdHistoryModal={setProdHistoryModal}
          setInvoiceModal={setInvoiceModal}
          handleOrderShipemnt={handleOrderShipemnt}
          printLabel={printLabel}
          printDeclaration={printDeclaration}
          labelDownloading={labelDownloading}
          CircularProgress={CircularProgress}
          row={row}
        />
      ),
      width: "400px",
      fixed: "right",
      disabled: (row) => isCancelled(row?.status),
    },
  ];

  const getAllOrders = async (page, perPage, filter, nestedSearch) => {
    let response;
    response = await fetchAllWarehouseOrders(
      page,
      perPage,
      filter,
      user.warehouse_id,
      nestedSearch
    );
    if (!response.error) {
      setOrders(response?.data?.result);
    }
  };

  const printDeclaration = async (row) => {
    setLabelDownloading("Declaration" + row?.id);
    try {
      fetch(row?.shipment?.single_shipping_invoice.file, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${
              row?.shipment?.tattension +
              " " +
              row?.shipment?.shipment_tracking_id
            } Declaration.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLabelDownloading("");
        });
    } catch (error) {
      setLabelDownloading("");
    }
  };

  const [labelDownloading, setLabelDownloading] = useState("");
  const [invoiceModal, setInvoiceModal] = useState();
  const [detailModal, setdetailModal] = useState(false);
  const user = useSelector((state) => state.user);
  const Navigate = useNavigate();
  const printLabel = async (row) => {
    setLabelDownloading("label" + row?.id);
    try {
      fetch(row?.shipment?.single_shipping_labels.file, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${
              row?.shipment?.tattension +
              " " +
              row?.shipment?.shipment_tracking_id
            } Label.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLabelDownloading("");
        });
    } catch (error) {
      setLabelDownloading("");
    }
  };
  const calculateTotalCost = (data) => {
    let totalCost = 0;
    let total = 0;
    if (data?.additional_costs?.length > 0) {
      data.additional_costs.forEach((currentItem) => {
        const cost = parseFloat(currentItem.cost ?? 0);
        totalCost += cost;
      });
    }
    total = totalCost + parseFloat(data.fulfillment_cost ?? 0);
    return parseFloat(total).toFixed(2);
  };
  const provideAttachmentsUrl = (data) => {
    return data?.order_notes?.flatMap((item) => item.order_attachments) ?? [];
  };
  const calculateTotalAdditionalCost = (data) => {
    let totalCost = 0;
    if (data?.additional_costs?.length > 0) {
      data.additional_costs.forEach((currentItem) => {
        const cost = parseFloat(currentItem.cost);
        totalCost += cost;
      });
    }
    return totalCost;
  };
  const handleOrderShipemnt = (row) => {
    localStorage.setItem("OrderShipment", JSON.stringify(row));
    Navigate("/");
  };

  const getAllWarehouseClients = async () => {
    const response = await getUserWarehousesByWar_Id(
      user?.warehouse_id,
      1,
      50,
      ""
    );
    if (!response.error) {
      setClients(response?.data?.result?.data);
    }
  };

  function getUserbalance() {
    const totalBalance = clients?.reduce((total, current) => {
      const balance = Number(current.balance);
      // Adjust balance sign
      const adjustedBalance = balance >= 0 ? -balance : Math.abs(balance);
      return total + adjustedBalance;
    }, 0);
    return totalBalance ? totalBalance.toFixed(2) : "0.00";
  }

  useEffect(() => {
    getAllWarehouseClients();
  }, []);

  function formatBalance(balance) {
    return balance >= 0 ? -Math.abs(balance) : Math.abs(balance);
  }

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <FullfilmentTotalDetailsModal
        open={fulBillingDetailsModal}
        setOpen={setFulBillingDetailsModal}
        calculateTotalCost={calculateTotalCost}
        calculateTotalAdditionalCost={calculateTotalAdditionalCost}
      />
      <AttachmentsModal
        open={imagesModal}
        setOpen={setImagesModal}
        setSnack={setSnack}
        setOrders={setOrders}
      />
      <OrderHistoryModal
        open={prodHistoryModal}
        setOpen={setProdHistoryModal}
        setSnack={setSnack}
        setOrders={setOrders}
      />
      <DetailsModal
        open={detailModal}
        setOpen={setdetailModal}
        orders={orders}
        setOrders={setOrders}
        setSnack={setSnack}
        editable={true}
      />
      <InvoiceModal
        open={!!invoiceModal}
        data={invoiceModal}
        setOpen={setInvoiceModal}
        actions={false}
      />
      <div className="warehouse_orders_header">
        <div>
          <span className="warehouse_orders_hdrheading">Order List</span>
        </div>
        <div className="w-40 md:w-48 ml-2 h-[70px] md:h-[80px] relative">
          <ExpandableBox
            heading="Available 3PL Balance"
            balance={getUserbalance()}
            list={clients?.map((client) => ({
              name: client?.shipper?.username || "Unknown",
              value: formatBalance(client?.balance ?? 0),
            }))}
          />
        </div>
      </div>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <OrdersTable
          message="No Orders Available to display!"
          searchPlaceHolder="Search Orders"
          // expandable
          // detailsComponent={(data) => <OrderDetails data={data} />}
          columns={columns}
          data={orders}
          getAllData={getAllOrders}
        />
      </div>
    </div>
  );
};

export default WarehouseOrder;
