import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import noImage from "../../../assets/images/noImage.png";

export default function ShopifyProductList({ products = [] }) {
  const [ShopifyProducts, setShopifyProducts] = useState([]);

  useEffect(() => {
    if (products?.length) {
      const shopifyItems = [];

      for (const item of products) {
        if (item.plugin_item_id) {
          shopifyItems.push(item);
        }
      }

      setShopifyProducts(shopifyItems);
    }
  }, [products]);

  return (
    <>
      {ShopifyProducts?.length > 0 && (
        <div className="sss__products_section">
          <div className="sss__addressection_heading">Shopify Products</div>
          <Row>
            <Col md={12} className="package__name_list_wrap">
              <div className="package__name_list_names_header">
                <div className="package__name_list_heading package__name_head_product">
                  <h6>My Products</h6>
                </div>
                <div className="package__name_list_heading package__name_head_name">
                  <h6>Name</h6>
                </div>
                <div className="package__name_list_heading package__name_head_sku">
                  <h6>Sku</h6>
                </div>
                <div className="package__name_list_heading package__name_head_size">
                  <h6>Size (L*W*H)</h6>
                </div>
                <div className="package__name_list_heading package__name_head_weight">
                  <h6>Weight</h6>
                </div>
                <div className="package__name_list_heading package__name_head_name">
                  <h6>Quantity</h6>
                </div>
                <div className="package__name_list_heading package__name_head_name">
                  <h6> Item Value</h6>
                </div>
              </div>

              {ShopifyProducts?.map((singlelist, index) => (
                <>
                  <div
                    className="package__name_list_box_content_wrap"
                    key={index}
                  >
                    {/* <div className="package__name_list_box_content package__name_content_index">
                      {"#"} {index + 1}
                    </div> */}
                    <div className="package__name_list_box_content package__name_content_image">
                      {"#"} {index + 1}
                      <img
                        src={singlelist?.shopify_image ?? noImage}
                        style={{
                          width: "60px",
                          height: "60px",
                          borderRadius: "10px",
                          objectFit: "contain",
                        }}
                        onError={(e) => {
                          e.target.src = noImage;
                        }}
                      />
                    </div>

                    <div className="package__name_list_box_content package__name_content_fullname">
                      {singlelist.product_name}
                    </div>

                    <div className="package__name_list_box_content package__name_content_code">
                      {singlelist.sku}
                    </div>

                    <div className="package__name_list_box_content package__name_content_dimension">
                      {singlelist.length ?? "0"}*{singlelist.width ?? "0"}*
                      {singlelist.height ?? "0"} {singlelist.dimension_unit}
                    </div>

                    <div className="package__name_list_box_content package__name_content_weight">
                      {singlelist.weight ?? "0"} {singlelist.weight_unit}
                    </div>

                    <div className="package__name_list_box_content package__name_content_code">
                      {singlelist.item_quantity ?? "0"}
                    </div>

                    <div className="package__name_list_box_content package__name_content_code">
                      {singlelist.item_total ?? "0"}
                    </div>
                  </div>
                </>
              ))}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}
