import React, { useState } from "react";
import "./Orders.css";

// components imports
import OrdersTable from "../../../../../Components/BoltTable/BoltTable";
import InvoiceModal from "../../../../../Components/InvoiceModal/InvoiceModal";
import OrderDetails from "./Components/OrderDetails/OrderDetails";
import Snackbar from "../../../../../Components/SnackBar/SnackBar";
import Tooltip from "../../../../../Components/Tooltip/Tooltip";
import Confirm from "../../../../../Components/Popups/Confirm";
import AttachmentsModal from "../../../../Warehouse/WarehouseOrders/Components/AttachmentsModal/AttachmentsModal";

// apis imports
import { updateUser } from "../../../../../store/userSlice";
import {
  fetchAllOrders,
  deleteSingleOrder,
  fetchSingleOrder,
} from "../../../../../services/orders.services";

// library imports

import { FcPlus } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import frame3 from "../../../../../Components/Images/Frame 3.png";
import frame4 from "../../../../../Components/Images/Frame 4.png";
import OrderHistoryModal from "./Components/OrderHistoryModal/OrderHistoryModal";
import noImage from "../../../../../assets/images/noImage.png";
import Image from "../../../../../assets/images/photo.png";
import pdfIcon from "../../../../../assets/icons/pdfIcon.png";
import excelIcon from "../../../../../assets/icons/excelIcon.png";
import docsIcon from "../../../../../assets/icons/docsIcon.png";
import DetailsModal from "../../../../Warehouse/WarehouseOrders/Components/OrderDetails/DetailsModal";
import { checkFileType } from "../../../../../helpers/helpers";
import ActionDropDown from "./Components/ActionsDropDown";
import StatusIcons from "../../../../../Components/StatusIcons/StatusIcons";
import FullfilmentBillingStatusModal from "./Components/FullfilmentBillingStatus/FullfilmentBillingStatus";
import FullfilmentTotalDetailsModal from "./Components/FullfilmentTotalDetails/FullfilmentTotalDetails";
import AddImage from "../../../../../assets/images/add-image.png";
import IconButton from "@mui/material/IconButton";
import ExpandableBox from "../../../../../Components/Common/ExpandableBox";
import MarketPlaceDetails from "./Components/MarketPlace/marketplaceDetails";

const formatWarehouses = (warehouses) => {
  return (
    warehouses?.map((warehouse) => ({
      name: warehouse?.warehouse?.name || "Unknown",
      value: warehouse?.balance ?? 0,
    })) || []
  );
};

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [actionLoader, setActionLoader] = useState(false);

  const [prodHistoryModal, setProdHistoryModal] = useState(false);
  const [fulBillingModal, setFulBillingModal] = useState(false);
  const [marketplaceModal, setMarketplaceModal] = useState(false);
  const [fulBillingDetailsModal, setFulBillingDetailsModal] = useState(false);

  const [detailModal, setdetailModal] = useState(false);
  const [labelDownloading, setLabelDownloading] = useState("");

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);
  const [imagesModal, setImagesModal] = useState();
  const [details, setDetails] = useState(false);

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  function isCancelled(status) {
    const lowerCaseStatus = status ? status.toLowerCase() : null;
    return lowerCaseStatus === "cancelled";
  }

  const columns = [
    {
      id: 0,
      label: "#",
      field_name: "id",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
      // headerColor: "#0a3e7a",
      // headerTextColor: "#fff",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 1,
      label: "Order ID",
      field_name: "order_id",
      searchable: true,
      render: (row) => <>{row?.order_id ?? "-"}</>,
      width: "200px",
      headerColor: "#0a3e7a",
      headerTextColor: "#fff",
      fixed: "left",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 2,
      label: "Type",
      field_name: "type",
      searchable: true,
      render: (row) => <>{row?.type ?? "-"}</>,
      headerColor: "#0a3e7a",
      headerTextColor: "#fff",
      width: "100px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 3,
      label: "Customer",
      field_name: "first_name",
      searchable: true,
      render: (row) => (
        <>{row?.order_shipping_address_details?.first_name ?? "-"}</>
      ),
      headerColor: "#0a3e7a",
      headerTextColor: "#fff",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 4,
      label: "Order",
      field_name: "status",
      searchable: true,
      render: (row) => <StatusIcons status={row?.status} />,
      headerColor: "#0a3e7a",
      headerTextColor: "#fff",
      width: "100px",
      disabled: (row) => isCancelled(row?.status),
    },
    // {
    //   id: 5,
    //   label: "Amount Pending",
    //   field_name: "amount_pending",
    //   render: (row) => <>{row?.amount_pending ?? "-"}</>,
    //   headerColor: "#0a3e7a",
    //   headerTextColor: "#fff",
    //   width: "200px",
    //   disabled: (row) => isCancelled(row?.status),
    // },
    // {
    //   id: 6,
    //   label: "Payment Received",
    //   field_name: "amount_received",
    //   render: (row) => <>{row?.amount_received ?? "-"}</>,
    //   headerColor: "#0a3e7a",
    //   headerTextColor: "#fff",
    //   width: "200px",
    //   disabled: (row) => isCancelled(row?.status),
    // },
    // {
    //   id: 7,
    //   label: "Method",
    //   field_name: "payment_method_title",
    //   render: (row) => <>{row?.payment_method_title ?? "-"}</>,
    //   headerColor: "#0a3e7a",
    //   headerTextColor: "#fff",
    //   width: "200px",
    //   disabled: (row) => isCancelled(row?.status),
    // },
    {
      id: 8,
      label: "Marketplace",
      field_name: "marketplace_name",
      render: (row) => (
        <>
          {row?.order_market_place_details?.market ? (
            <img
              src={
                row?.order_market_place_details?.market === "Shopify"
                  ? frame4
                  : row?.order_market_place_details?.market === "Woocommerce"
                  ? frame3
                  : ""
              }
              onClick={() => setMarketplaceModal(row)}
              alt=""
              className="adcomtable__company_logo"
              loading="lazy"
              style={{ cursor: "pointer" }}
              onError={(e) => {
                e.target.src =
                  row?.order_market_place_details?.market === "Shopify"
                    ? frame4
                    : row?.order_market_place_details?.market === "Woocommerce"
                    ? frame3
                    : "";
              }}
            />
          ) : (
            ""
          )}
        </>
      ),
      headerColor: "#98BDE7",
      headerTextColor: "#002859",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 9,
      label: "Status",
      field_name: "market_place_status",
      render: (row) => <StatusIcons status={row?.market_place_status} />,
      headerColor: "#98BDE7",
      headerTextColor: "#002859",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 10,
      label: "Carrier",
      field_name: "shipping_company",
      render: (row) => (
        <>
          {row?.shipment && (
            <>
              <img
                src={
                  row?.shipment?.shipping_company?.shipping_company?.logo_url ??
                  noImage
                }
                onError={(e) => {
                  e.target.src = noImage;
                }}
                loading="lazy"
              />
            </>
          )}
          {row?.manual_shipment?.shipping_company?.shipping_company && (
            <>
              <img
                src={
                  row?.manual_shipment?.shipping_company?.shipping_company
                    ?.logo_url ?? noImage
                }
                onError={(e) => {
                  e.target.src = noImage;
                }}
                loading="lazy"
              />
            </>
          )}
          {row?.manual_shipment?.manual_shipping_company && (
            <>
              {row?.manual_shipment?.manual_shipping_company
                ?.shipping_company_name ?? ""}
            </>
          )}
        </>
      ),
      headerColor: "#a8e2f6",
      headerTextColor: "#002859",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 11,
      label: "Tracking",
      field_name: "shipment_tracking_id",
      searchable: true,
      render: (row) => (
        <>
          {row?.shipment && <>{row?.shipment?.shipment_tracking_id ?? ""}</>}
          {row?.manual_shipment && (
            <>{row?.manual_shipment?.shipment_tracking_id ?? ""}</>
          )}
        </>
      ),
      headerColor: "#a8e2f6",
      headerTextColor: "#002859",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 12,
      label: "Status",
      field_name: "shipment_status",
      render: (row) => (
        <>
          {row?.shipment && (
            <>
              <StatusIcons status={row?.shipment?.shipment_status} />
            </>
          )}
          {row?.manual_shipment && (
            <>
              <StatusIcons status={row?.manual_shipment?.shipment_status} />
            </>
          )}
        </>
      ),
      searchable: true,
      headerColor: "#a8e2f6",
      headerTextColor: "#002859",
      width: "150px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 13,
      label: "Total",
      field_name: "shipping_total",
      render: (row) => (
        <>{row?.shipping_total ? row?.shipping_total + " $" : "-"}</>
      ),
      headerColor: "#a8e2f6",
      headerTextColor: "#002859",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 14,
      label: "Warehouse",
      field_name: "warehouse_name",
      render: (row) => <>{row?.warehouse?.name ?? "-"}</>,
      searchable: true,
      headerColor: "#002958",
      headerTextColor: "#fff",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 15,
      label: "Fulfilment Status",
      field_name: "fulfillment_status",
      searchable: true,
      render: (row) => <StatusIcons status={row?.fullfilment_status?.name} />,
      headerColor: "#002958",
      headerTextColor: "#fff",
      width: "170px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 16,
      label: "Fulfilment Total",
      field_name: "fulfillment_cost",
      render: (row) => (
        <>
          {row?.warehouse_id ? (
            <>
              <Tooltip
                title={
                  <div
                    className="package__name_list_wrap fulfilment_cost_tooltip_content"
                    style={{ border: "1px solid #d3d3d3", padding: "5px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <strong className="fulfilmentcost__headers">
                        Fulfilment Cost{" "}
                      </strong>{" "}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        fontSize: "14px",
                      }}
                    >
                      <label>
                        <strong>
                          {row?.formula?.["UnitType"] === "CBM"
                            ? "Volume"
                            : "Weight"}
                          : ({row?.formula?.["TotalFulfilmentWeight"]}{" "}
                          {row?.formula?.["UnitType"]})
                        </strong>
                      </label>
                      <span>
                        <strong>
                          {row?.formula?.["TotalWeightCost"]} CAD{" "}
                        </strong>
                      </span>
                    </div>
                    {row?.formula?.["TotalPreProduct"] > 0 && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            fontSize: "14px",
                          }}
                        >
                          <strong className="fulfilmentcost__headers">
                            Pre Cost Item Quantity: (
                            {row?.formula?.["TotalPreProduct"]})
                          </strong>
                          {/* <span>{row?.formula?.["TotalPreCost"]} CAD </span> */}
                        </div>
                        {/* {row?.order_details
                          ?.filter(
                            (singlelist) =>
                              singlelist?.products?.fulfillment_cost
                          )
                          .map((list, index) => (
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                fontSize: "14px",
                              }}
                              key={index} // It's a good 5practice to provide a unique key for each mapped element
                            >
                              <label>
                                {list.product_name} ({list.item_quantity} x{" "}
                                {list.products?.fulfillment_cost})
                              </label>
                              <span>
                                {list.products?.fulfillment_cost *
                                  list.item_quantity}{" "}
                                CAD{" "}
                              </span>
                            </div>
                          ))} */}
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "end",
                            fontSize: "14px",
                          }}
                        >
                          <label>
                            <strong>
                              {/* Total: ({row?.formula?.["TotalPreProduct"]}) */}
                            </strong>
                          </label>
                          <span>
                            <strong>
                              {row?.formula?.["TotalPreCost"]} CAD
                            </strong>
                          </span>
                        </div>
                      </>
                    )}
                    {row?.additional_costs?.length > 0 && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            fontSize: "14px",
                          }}
                        >
                          <strong className="fulfilmentcost__headers">
                            Additional Cost: ({row?.additional_costs?.length})
                          </strong>
                          {/* <span>{row?.formula?.["TotalPreCost"]} CAD /span> */}
                        </div>
                        {row?.additional_costs?.map((list, index) => (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              fontSize: "14px",
                            }}
                            key={index} // It's a good 5practice to provide a unique key for each mapped element
                          >
                            <label>{list?.cost_type?.name}</label>
                            <span>{list?.cost} CAD </span>
                          </div>
                        ))}
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "end",
                            fontSize: "14px",
                          }}
                        >
                          {/* <label>
    <strong>Total Items Fulfilment: ({row?.formula?.["TotalPreProduct"]})</strong>
    </label> */}
                          <span>
                            <strong
                              style={{
                                borderTop: "1px solid black",
                              }}
                            >
                              {calculateTotalAdditionalCost(row)} CAD
                            </strong>{" "}
                          </span>
                        </div>
                        <hr />
                      </>
                    )}

                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        fontSize: "14px",
                      }}
                    >
                      <label>
                        <strong>Total Fulfilment COST: </strong>
                      </label>
                      <span>
                        <strong>
                          {/* {parseFloat(row?.formula?.["TotalFulfilmentCost"]) + */}
                          {calculateTotalCost(row)} CAD{" "}
                        </strong>
                      </span>
                    </div>
                  </div>
                }
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => setFulBillingDetailsModal(row)}
                >
                  {calculateTotalCost(row) + "CAD" ?? "-"}
                </div>
              </Tooltip>
            </>
          ) : (
            "-"
          )}
        </>
      ),
      headerColor: "#002958",
      headerTextColor: "#fff",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 17,
      label: "Fulfilment Billing",
      field_name: "fulfillment_billing_status",
      searchable: true,
      render: (row) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setFulBillingModal(row)}
        >
          <StatusIcons status={row?.fulfillment_billing_status} />
        </div>
      ),
      headerTextColor: "#fff",
      headerColor: "#002958",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },

    // {
    //   id: 18,
    //   label: "Attachments",
    //   field_name: "warehouse_attachments",
    //   render: (row) => (
    //     <div className="shipper__attachments_thumbnail_wrap">
    //       {row?.attachments?.length === 0 ? (
    //         <img
    //           src={AddImage}
    //           // key={index}
    //           loading="lazy"
    //           onClick={() => setImagesModal(row.id)}
    //           onError={(e) => {
    //             e.target.src = noImage;
    //           }}
    //         />
    //       ) : (
    //         <img
    //           src={
    //             checkFileType(row?.attachments[0]?.file) === "Image"
    //               ? row?.attachments[0]?.file
    //               : checkFileType(row?.attachments[0]?.file) === "PDF"
    //               ? pdfIcon
    //               : checkFileType(row?.attachments[0]?.file) === "Excel"
    //               ? excelIcon
    //               : checkFileType(row?.attachments[0]?.file) === "Document"
    //               ? docsIcon
    //               : noImage
    //           }
    //           key={row?.attachments[0]?.id}
    //           loading="lazy"
    //           onClick={() => setImagesModal(row.id)}
    //           onError={(e) => {
    //             e.target.src = noImage;
    //           }}
    //         />
    //       )}
    //     </div>
    //   ),
    //   headerColor: "#002958",
    //   headerTextColor: "#fff",
    //   width: "200px",
    //   disabled: (row) => isCancelled(row?.status),
    // },
    {
      id: 118,
      label: "Attachments",
      field_name: "warehouse_attachments",
      render: (row) => (
        <div className="shipper__attachments_thumbnail_wrap">
          {row?.attachments?.length === 0 ? (
            <img
              src={AddImage}
              // key={index}
              loading="lazy"
              onClick={() => setImagesModal(row.id)}
              onError={(e) => {
                e.target.src = noImage;
              }}
            />
          ) : (
            <img
              src={
                checkFileType(row?.attachments[0]?.file) === "Image"
                  ? Image
                  : checkFileType(row?.attachments[0]?.file) === "PDF"
                  ? pdfIcon
                  : checkFileType(row?.attachments[0]?.file) === "Excel"
                  ? excelIcon
                  : checkFileType(row?.attachments[0]?.file) === "Document"
                  ? docsIcon
                  : noImage
              }
              key={row?.attachments[0]?.id}
              loading="lazy"
              onClick={() => setImagesModal(row.id)}
              onError={(e) => {
                e.target.src = noImage;
              }}
            />
          )}
        </div>
      ),
      headerColor: "#002958",
      headerTextColor: "#fff",
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 19,
      label: "Created At",
      field_name: "created_at",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "200px",
      disabled: (row) => isCancelled(row?.status),
    },
    {
      id: 20,
      label: "Actions",
      field_name: "actions",
      render: (row) => (
        <ActionDropDown
          setdetailModal={setdetailModal}
          setProdHistoryModal={setProdHistoryModal}
          setConfirmDelete={setConfirmDelete}
          setInvoiceModal={setInvoiceModal}
          handleOrderShipemnt={handleOrderShipemnt}
          printLabel={printLabel}
          actionLoader={actionLoader}
          printDeclaration={printDeclaration}
          labelDownloading={labelDownloading}
          CircularProgress={CircularProgress}
          row={row}
        />
      ),
      width: "400px",
      fixed: "right",
      disabled: (row) => isCancelled(row?.status),
    },
  ];

  const provideAttachmentsUrl = (data) => {
    return data?.order_notes?.flatMap((item) => item.order_attachments) ?? [];
  };

  const getAllOrders = async (page, perPage, filter, nestedSearch) => {
    let response = await fetchAllOrders(page, perPage, filter, nestedSearch);
    if (!response.error) {
      const { data, ...newObject } = response?.data?.result?.orderData;
      dispatch(
        updateUser({
          unReadOrders: response?.data?.result?.unReadOrdersCount,
        })
      );
      setOrders(response?.data?.result?.orderData);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const [invoiceModal, setInvoiceModal] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const Navigate = useNavigate();
  const printLabel = async (row) => {
    try {
      setActionLoader(row.id);
      fetch(row?.shipment?.single_shipping_labels.file, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${
              row?.shipment?.tattension +
              " " +
              row?.shipment?.shipment_tracking_id
            } Label.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setActionLoader(false);
        });
    } catch (error) {
      setSnack((snack) => {
        setActionLoader(false);
        return {
          ...snack,
          open: true,
          text: "Something went wrong try again later",
          severity: "error",
        };
      });
    }
  };
  const printDeclaration = async (row) => {
    setLabelDownloading("Declaration" + row?.id);
    try {
      fetch(row?.shipment?.single_shipping_invoice.file, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((url) => url.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${
              row?.shipment?.tattension +
              " " +
              row?.shipment?.shipment_tracking_id
            } Declaration.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setLabelDownloading("");
        });
    } catch (error) {
      setLabelDownloading("");
    }
  };
  const calculateTotalAdditionalCost = (data) => {
    let totalCost = 0;
    if (data?.additional_costs?.length > 0) {
      data.additional_costs.forEach((currentItem) => {
        const cost = parseFloat(currentItem.cost);
        totalCost += cost;
      });
    }
    return totalCost;
  };
  const calculateTotalCost = (data) => {
    let totalCost = 0;
    let total = 0;
    if (data?.additional_costs?.length > 0) {
      data.additional_costs.forEach((currentItem) => {
        const cost = parseFloat(currentItem.cost ?? 0);
        totalCost += cost;
      });
    }
    total = totalCost + parseFloat(data.fulfillment_cost ?? 0);
    return parseFloat(total).toFixed(2);
  };

  const handleOrderShipemnt = async (row) => {
    try {
      let data = await fetchSingleOrder(row?.id);
      if (Object.keys(data?.data?.result).length !== 0) {
        localStorage.setItem(
          "OrderShipment",
          JSON.stringify(data?.data?.result)
        );
        Navigate("/");
      } else {
        // Handle the case where data is empty
        setSnack((snack) => ({
          ...snack,
          open: true,
          text: "No order data available",
          severity: "warning",
        }));
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error fetching order data:", error);
      setSnack((snack) => ({
        ...snack,
        open: true,
        text: "Something went wrong",
        severity: "error",
      }));
    }
  };

  const handleDeleteRequest = async (data) => {
    if (data.id) {
      setdeleteLoader(true);
      const response = await deleteSingleOrder(data.id);

      if (!response.error && response?.data?.success) {
        setOrders((prevOrders) => {
          return {
            ...prevOrders,
            data: prevOrders?.data?.filter((d) => d?.id != data?.id),
          };
        });
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Order deleted successfully",
            severity: "success",
          };
        });
        setdeleteLoader(false);
        setConfirmDelete(false);
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text:
              response?.error?.response?.data?.message ??
              "Couldn't cancel this request!",
            severity: "error",
          };
        });
        setdeleteLoader(false);
      }
    }
  };

  function getLtlbalance() {
    const totalBalance = user?.user_approved_warehouses?.reduce(
      (total, current) => total + Number(current.balance),
      0
    );

    return totalBalance ? totalBalance.toFixed(2) : "0.00";
  }

  return (
    <div className="dispatcher__screen" style={{ maxWidth: "100%" }}>
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <AttachmentsModal
        open={imagesModal}
        setOpen={setImagesModal}
        setSnack={setSnack}
        setOrders={setOrders}
      />
      <OrderHistoryModal
        open={prodHistoryModal}
        setOpen={setProdHistoryModal}
        setSnack={setSnack}
        setOrders={setOrders}
      />
      <FullfilmentBillingStatusModal
        open={fulBillingModal}
        setOpen={setFulBillingModal}
        setSnack={setSnack}
        setOrders={setOrders}
        orders={orders}
      />
      <MarketPlaceDetails
        open={marketplaceModal}
        setOpen={setMarketplaceModal}
      />
      <FullfilmentTotalDetailsModal
        open={fulBillingDetailsModal}
        setOpen={setFulBillingDetailsModal}
        calculateTotalCost={calculateTotalCost}
        calculateTotalAdditionalCost={calculateTotalAdditionalCost}
      />
      <DetailsModal
        open={detailModal}
        setOpen={setdetailModal}
        orders={orders}
        setOrders={setOrders}
        setSnack={setSnack}
      />
      <InvoiceModal
        open={!!invoiceModal}
        data={invoiceModal}
        setOpen={setInvoiceModal}
        actions={false}
        setSnack={setSnack}
      />
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteRequest(confirmDelete)}
        text="Do You Want To Delete This Order ? Deleting it will result in the removal of all associated tracks."
        loader={deleteLoader}
      />
      <div className="orders_header flex-wrap">
        <div className="min-w-20">
          <span className="text-sm md:text-lg">Orders List</span>
        </div>
        <div className="flex justify-end items-center h-[70px] md:h-[110px]">
          <div onClick={() => navigate("/sales")}>
            <div className="orders_hdraddprod">
              <FcPlus size={10} /> ADD SALE
            </div>
          </div>
          <div className="w-40 md:w-48 ml-2 h-[70px] md:h-[80px] relative">
            <ExpandableBox
              heading="Available 3PL Balance"
              balance={getLtlbalance()}
              list={formatWarehouses(user?.user_approved_warehouses)}
            />
            ;
          </div>
        </div>
      </div>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <OrdersTable
          message={"No Orders Available To Display!"}
          searchPlaceHolder={"Search Orders By Order ID"}
          // expandable
          // detailsComponent={(data) => <OrderDetails data={data} />}
          getAllData={getAllOrders}
          data={orders}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default Orders;
