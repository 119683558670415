import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MdManageAccounts, MdStore, MdChat, MdMoreHoriz } from "react-icons/md";
import { FaShippingFast, FaDigitalTachograph } from "react-icons/fa";
import logo from "../logo.png";
import ProfileAvatar from "../../assets/images/profile-avatar.png";
import AdminProfileModal from "./Components/ProfileModal/AdminProfileModal";
import CustomNavbar from "./Navbar";

const AdminNavBar = () => {
  const user = useSelector((state) => state.user);
  const [modal, setModal] = useState();

  const navItems = [
    {
      label: "Clients",
      url: "/Users",
      icon: <MdManageAccounts size={20} className="m-auto" />,
    },
    {
      label: "Carriers",
      url: "/companies",
      icon: <MdStore size={20} className="m-auto" />,
    },
    {
      label: "Tickets",
      url: "/tickets-admin",
      icon: <MdChat size={20} className="m-auto" />,
    },
    {
      label: "Shipments",
      url: "/shipments-admin",
      icon: <FaShippingFast size={20} className="m-auto" />,
    },
    {
      label: "Finances",
      url: "/payments-admin",
      icon: <FaDigitalTachograph size={20} className="m-auto" />,
    },
    {
      label: "More",
      url: "/user/Newsletter",
      icon: <MdMoreHoriz size={20} className="m-auto" />,
    },
  ];

  const profileModal = ({ onClose }) => (
    <AdminProfileModal
      onClose={onClose}
      style={{
        top: "50px",
        right: "0 ",
      }}
    />
  );

  const dateBadge = true; // Enable date badge

  return (
    <CustomNavbar
      logo={logo}
      navItems={navItems}
      profileImage={
        user.profile_image &&
        user.profile_image.split("/").pop() !== "undefined"
          ? user?.profile_image
          : ProfileAvatar
      }
      profileModal={profileModal}
      dateBadge={dateBadge}
      toastConfig={{
        show: false,
        title: "Hajex Shipment",
        message: "Successfully Logged Out !!",
        image: logo,
        timestamp: "just now",
      }}
    />
  );
};

export default AdminNavBar;
