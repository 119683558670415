import React, { useState } from "react";
import "./ProfileModal.css";
import RiseModal from "../../../RiseModal/RiseModal";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useSelector } from "react-redux";
import { IoPersonCircle, IoSettings, IoLogOutOutline } from "react-icons/io5";
import { BsShieldLockFill } from "react-icons/bs";
import { BiSolidNotepad } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { setDisabled } from "../../../../store/common/uiSlice";

const ProfileModal = ({ onClose, style }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [loggingOut, setLoggingOut] = useState(false);
  const dispatch = useDispatch();
  const handleLogout = async () => {
    dispatch(setDisabled(true));
    setLoggingOut(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}logout`,
        method: "POST",
        timeout: 0,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("Token"),
        },
      });
      localStorage.clear();
      setLoggingOut(false);
      navigate("/Login");
    } catch (error) {
      localStorage.clear();
      setLoggingOut(false);
      navigate("/Login");
    } finally {
      dispatch(setDisabled(false));
    }
  };
  return (
    <RiseModal style={style} onClose={onClose}>
      <div className="profile_modal">
        <div className="profile_modal_title">Balance: ${user.balance}</div>
        <Link className="profile_modal_item" to="/Profile">
          <IoPersonCircle size={20} className="profile_modal_icon" />
          <div>Profile</div>
        </Link>
        <Link
          className="profile_modal_item"
          to={
            user.role === "Shipper"
              ? // ? "/settings/manage-roles"
                "/settings/manage-warehouse"
              : "/settings/manage-permissions"
          }
        >
          <IoSettings size={20} className="profile_modal_icon" />
          <div>Settings</div>
        </Link>
        <a
          className="profile_modal_item"
          href="https://hajexbolt.com/policy"
          target="_blank"
        >
          <BsShieldLockFill size={20} className="profile_modal_icon" />
          <div>Privacy Policy</div>
        </a>
        <a
          className="profile_modal_item"
          href="https://hajexbolt.com/terms"
          target="_blank"
        >
          <BiSolidNotepad size={20} className="profile_modal_icon" />
          <div>Terms & Conditions</div>
        </a>

        <hr />
        <div
          className={`profile_modal_item ${
            loggingOut && "profile_modal_itemdisabled"
          }`}
          onClick={handleLogout}
        >
          <IoLogOutOutline size={20} className="profile_modal_icon" />
          <div>Logout</div>
          {!loggingOut && (
            <div className="version__badge">
              {process.env.React_APP_Version}
              <span></span>
            </div>
          )}
          <span className="pm_item_logoutloader">
            {" "}
            {loggingOut && <CircularProgress size={16} />}
          </span>
        </div>
      </div>
    </RiseModal>
  );
};

export default ProfileModal;
