import Stack from "@mui/material/Stack";
import Buttons from "./Buttons";

export default function UnitSwitch({ List, SetList, SetPlist }) {
  const handletype = (e) => {
    const { name: newUnit } = e.target;

    if (List?.unit !== newUnit) {
      const isMetric = newUnit === "Metric";
      const dimensionFactor = isMetric ? 1 / 2.54 : 2.54;
      const weightFactor = isMetric ? 2.205 : 1 / 2.205;

      SetPlist((prevState) =>
        prevState.map(({ length, height, width, weight, ...rest }) => ({
          ...rest,
          length: length ? parseFloat(length * dimensionFactor).toFixed(2) : "",
          height: height ? parseFloat(height * dimensionFactor).toFixed(2) : "",
          width: width ? parseFloat(width * dimensionFactor).toFixed(2) : "",
          weight: weight ? parseFloat(weight * weightFactor).toFixed(2) : "",
        }))
      );
      SetList((prev) => ({ ...prev, unit: newUnit, QuoteCheck: false }));
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {["Imperial", "Metric"].map((unit) => (
        <Buttons
          key={unit}
          Name={unit}
          HandleChange={handletype}
          Variant={List?.unit === unit ? "contained" : "outlined"}
          Color={List?.unit === unit ? "primary" : "inherit"}
        />
      ))}
    </Stack>
  );
}
