import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3B82F6',
            light: '#93C5FD',
            dark: '#1E40AF',
        },
        secondary: {
            main: '#F59E0B',
            light: '#FDE68A',
            dark: '#D97706',
        },
        success: {
            main: '#10B981',
        },
        error: {
            main: '#DC2626',
        },
        warning: {
            main: '#FBBF24',
        },
        info: {
            main: '#0EA5E9',
        },
        background: {
            default: '#F3F4F6',
            paper: '#FFFFFF',
        },
        text: {
            primary: '#111827',
            secondary: '#4B5563',
        },
        divider: '#D1D5DB',
    },
    typography: {
        fontFamily: "'Poppins', 'Roboto', 'Arial', sans-serif",
    }
});

export default theme;
