import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import DateBadge from "../DateBadge/DateBadge";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { FaChevronDown } from "react-icons/fa";
import ProfileAvatar from "../../assets/images/profile-avatar.png";

const CustomNavbar = ({
  logo,
  navItems,
  profileImage,
  profileModal,
  dateBadge,
  onLogout,
  toastConfig,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [modal, setModal] = useState();

  const handleMenuIconClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const currentDate = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <div>
      <div className="header__main_wrap">
        <div className="header__logo_wrap">
          <Navbar.Brand>
            <img alt="Logo" src={logo} className="brand_image_header" />
          </Navbar.Brand>
        </div>
        <div
          className={`header__navigation_main_wrap ${
            isMenuOpen ? "navigation__mobile_open" : ""
          }`}
        >
          {isMenuOpen && (
            <>
              <div
                className="header__mobile_overlay"
                onClick={handleMenuIconClick}
              ></div>
              <button
                className="headernav__btn_cross"
                onClick={handleMenuIconClick}
              >
                <span></span>
                <span></span>
              </button>
            </>
          )}
          <ul className="header__navigation_main_list">
            {navItems.map(({ label, url, icon, unReadData }, index) => (
              <li key={index}>
                <LinkContainer to={url} className="mr-2">
                  <Nav.Link className="navbar__link_wrap">
                    {unReadData ? (
                      <span className="navbar__item_badge">{unReadData}</span>
                    ) : null}
                    {icon}
                    {label}
                  </Nav.Link>
                </LinkContainer>
              </li>
            ))}
          </ul>
          <div className="headernav__navicon_wrap">
            <button
              className="headernav__navicon_btn"
              onClick={handleMenuIconClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
        <div className="header__dataUser-col_wrap">
          {dateBadge && (
            <div className="header__datebadge_col">
              <DateBadge
                month={months[currentDate.getMonth()]}
                day={currentDate.getDate()}
              />
            </div>
          )}
          <div className="header__userprofile_wrap relative">
            <div
              className="navbar__userprofile_section"
              onClick={() => setModal("profile")}
            >
              <div className="navbar__userprofile_avatar">
                <img
                  src={profileImage || ProfileAvatar}
                  alt="Profile"
                  className="profile__avatar_image"
                />
                <FaChevronDown className="navbar__userprofile_sectiondrop" />
              </div>
              {modal === "profile" &&
                profileModal &&
                profileModal({ onClose: () => setModal() })}
            </div>
          </div>
        </div>
      </div>

      {toastConfig && (
        <ToastContainer position={"top-end"} className="p-3 mt-14">
          <Toast
            onClose={onLogout}
            show={toastConfig.show}
            delay={toastConfig.delay || 2000}
            autohide
            className="ml-16"
          >
            <Toast.Header>
              <img
                src={toastConfig.image}
                className="rounded me-2"
                alt=""
                width="50"
              />
              <strong className="me-auto">{toastConfig.title}</strong>
              <small>{toastConfig.timestamp}</small>
            </Toast.Header>
            <Toast.Body>{toastConfig.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </div>
  );
};

export default CustomNavbar;
