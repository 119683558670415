import React, { useState, useEffect } from "react";
import "./Authentication.css";
import Logo from "../../assets/images/logo_login.png";
import bgTab from "../../assets/images/login_tab_bg.png";
import bg from "../../assets/images/logistic.png";
import CustomStepper from "./Components/CustomStepper/CustomStepper";
import CustomLStepper from "./Components/CustomStepper/CustomLStepper";
import RegisterStep1 from "./Components/RegisterSteps/RegisterStep1";
import RegisterStep2 from "./Components/RegisterSteps/RegisterStep2";
import RegisterStep3 from "./Components/RegisterSteps/RegisterStep3";
import RegisterCStep1 from "./Components/RegisterCSteps/RegisterCStep1";
import RegisterCStep2 from "./Components/RegisterCSteps/RegisterCStep2";
import RegisterCStep3 from "./Components/RegisterCSteps/RegisterCStep3";
import RegisterLSteps1 from "./Components/RegisterLSteps/RegisterLSteps1";
import RegisterLSteps2 from "./Components/RegisterLSteps/RegisterLSteps2";
import RegisterLSteps3 from "./Components/RegisterLSteps/RegisterLSteps3";
import { registerUser } from "../../services/auth.services";
import { useNavigate, useParams } from "react-router-dom";
import { showSnackBar } from "../../store/common/snackBarSlice";
import { useDispatch } from "react-redux";
import { MdOutlineErrorOutline } from "react-icons/md";

const initialCarrierData = {
  firstname: "",
  lastname: "",
  business_name: "",
  vehicles_qty: "",
  email: "",
  phone_number: "",
  address: "",
  country: "",
  province: "",
  city: "",
  zip: "",
  username: "",
  password: "",
  experience: "",
  about_us: "",
  revenue: "",
  role: "Carrier",
};
const initialShipperData = {
  username: "",
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  business_name: "",
  industry_type: "",
  phone_number: "",
  address: "",
  country: "",
  province: "",
  city: "",
  zip: "",
  about_us: "",
  shipment_per_month: "",
  revenue: "",
  role: "Shipper",
  market: [
    {
      value: "amazon",
    },
  ],
};
const initialLogisticData = {
  name: "",
  address: "",
  city: "",
  country: "",
  state: "",
  passcode: "",
  role: "warehouseUser",
  username: "",
  firstname: "",
  lastname: "",
  business_name: "",
  industry_type: "",
  email: "",
  password: "",
  // permissions: [],
};

const RegisterForm = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showComponent, setShowComponent] = useState(false);
  const [activeStepFormTab, setActiveStepFormTab] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentCStep, setCurrentCStep] = useState(1);
  const [currentLStep, setCurrentLStep] = useState(1);
  const [carrierForm, setCarrierForm] = useState(initialCarrierData);
  const [shipperForm, setShipperForm] = useState(initialShipperData);
  const [logisticForm, setlogisticForm] = useState(initialLogisticData);

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const handleActiveTab = (tab) => {
    tab !== activeTab && setShowComponent(false);
    setActiveTab(tab);
  };
  const handleCarrierForm = (name, value) => {
    setCarrierForm({ ...carrierForm, [name]: value });
  };
  const handleShipperForm = (name, value) => {
    setShipperForm({ ...shipperForm, [name]: value });
  };
  const handleLogisticForm = (name, value) => {
    setlogisticForm({ ...logisticForm, [name]: value });
  };

  const handleSignup = async (data) => {
    try {
      setLoading(true);
      const res = await registerUser(data);
      if (res.error) {
        let message = "";
        if (res?.error?.response?.data?.username) {
          message = res?.error?.response?.data?.username[0];
        } else if (res?.error?.response?.data?.email) {
          message = res?.error?.response?.data?.email[0];
        } else {
          message =
            res?.error?.response?.data?.message ?? "Something went wrong";
        }
        dispatch(
          showSnackBar({
            text: message,
            severity: "error",
          })
        );
        setLoading(false);
      } else {
        dispatch(
          showSnackBar({
            text: res?.data?.message,
            severity: "success",
          })
        );
        navigate("/welcome", {
          state: {
            username:
              res?.data?.result?.user?.role === "warehouseUser"
                ? res?.data?.result?.user?.username
                : res?.data?.result?.username,
            email:
              res?.data?.result?.user?.role === "warehouseUser"
                ? res?.data?.result?.user?.email
                : res?.data?.result?.email,
          },
        });
        setLoading(false);
      }
    } catch (error) {
      dispatch(
        showSnackBar({
          text: error?.message || "Something went wrong!",
          severity: "error",
        })
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    const role = searchParams.get("role");
    if (role) {
      role.toLowerCase() === "carrier" && setActiveTab(2);
    }
  }, []);
  useEffect(() => {
    setShowComponent(true);
  }, [activeTab]);

  const registerTabform = (valBtn) => {
    setActiveStepFormTab(valBtn);
  };

  return (
    <div
      className={
        activeStepFormTab <= 0
          ? "loginpage__new_landing_bg  register__form_screen"
          : "register__form_screen"
      }
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="register__main_wrapper">
        {activeStepFormTab === 0 && (
          <div className="register__main_landing_main">
            <div className="register__main_logo">
              <img
                src={Logo}
                alt=""
                className="regform_sheader_image"
                onClick={() => {
                  window.location.href = "https://www.hajexbolt.com";
                }}
              />
            </div>
            <div className="register__main_button">
              <div
                className="registerpage__new_buttons_shipper"
                onClick={() => registerTabform(1)}
              >
                Shipper
              </div>
              <div
                className="registerpage__new_buttons_carrier"
                onClick={() => registerTabform(2)}
              >
                Carrier
              </div>
              <div
                className="registerpage__new_buttons_logistic"
                onClick={() => registerTabform(3)}
              >
                Warehouse
              </div>
            </div>
            <div className="register__main_info_content">
              Welcome! Please select your desired Register tab. <span>OR</span>{" "}
              if you already have account so{" "}
              <a
                href="javascript:;"
                onClick={() => {
                  let destination = "/Login";
                  if (searchParams.toString()) {
                    destination = destination + "?" + searchParams.toString();
                  }
                  navigate(destination);
                }}
              >
                Login!
              </a>
            </div>
          </div>
        )}

        {activeStepFormTab === 1 && (
          <div className="register__tab_main">
            <div className="register__tab_left_col">
              <div className="register__tab_left_logo">
                <img
                  src={Logo}
                  alt=""
                  className="regform_sheader_image"
                  onClick={() => {
                    window.location.href = "https://www.hajexbolt.com";
                  }}
                />
              </div>
              <div className="register__tab_left_image">
                <img src={bgTab} alt="" />
              </div>
            </div>
            <div className="register__tab_right_col">
              <h3 className="register__tab_heading">Shipper Register</h3>
              <CustomStepper step={currentStep} setStep={setCurrentStep} />
              {currentStep === 1 && (
                <RegisterStep1
                  setCurrentStep={setCurrentStep}
                  formData={shipperForm}
                  handleFieldChange={(name, value) =>
                    handleShipperForm(name, value)
                  }
                />
              )}
              {currentStep === 2 && (
                <RegisterStep2
                  setCurrentStep={setCurrentStep}
                  formData={shipperForm}
                  handleFieldChange={(name, value) =>
                    handleShipperForm(name, value)
                  }
                />
              )}
              {currentStep === 3 && (
                <RegisterStep3
                  setCurrentStep={setCurrentStep}
                  formData={shipperForm}
                  handleFieldChange={(name, value) =>
                    handleShipperForm(name, value)
                  }
                  handleSubmit={(data) => handleSignup(data)}
                  loading={loading}
                />
              )}
              <div
                className="assign__pickup_modalerrorwrap assign__pickup_modalerrorwrapopen"
                style={{
                  overflow: "hidden",
                }}
              >
                <div
                  className="assign__pickup_modalerrorbox"
                  style={{
                    backgroundColor: "#bbdefb",
                    color: "#2196f3",
                  }}
                >
                  <span>
                    <MdOutlineErrorOutline
                      size={16}
                      style={{ marginTop: "2px" }}
                    />
                  </span>
                  Please provide your valid business address, email, and website
                  to complete your account verification and activation.
                </div>
              </div>
              <div
                className="register__tab_login_link"
                // onClick={() => navigate("/Login")}
              >
                Already have an account?{" "}
                <span
                  onClick={() => {
                    let destination = "/Login";
                    if (searchParams.toString()) {
                      destination = destination + "?" + searchParams.toString();
                    }
                    navigate(destination);
                  }}
                >
                  {" "}
                  Signin
                </span>{" "}
                .
              </div>
              {currentStep === 1 && (
                <div
                  className="regiterpage__new_form_back_btn"
                  onClick={() => registerTabform(0)}
                >
                  Back
                </div>
              )}
            </div>
          </div>
        )}
        {activeStepFormTab === 2 && (
          <div className="register__tab_main">
            <div className="register__tab_left_col">
              <div className="register__tab_left_logo">
                <img
                  src={Logo}
                  alt=""
                  className="regform_sheader_image"
                  onClick={() => {
                    window.location.href = "https://www.hajexbolt.com";
                  }}
                />
              </div>
              <div className="register__tab_left_image">
                <img src={bgTab} alt="" />
              </div>
            </div>
            <div className="register__tab_right_col">
              <h3 className="register__tab_heading">Carrier Register</h3>
              <CustomStepper step={currentCStep} setStep={setCurrentCStep} />
              {currentCStep === 1 && (
                <RegisterCStep1
                  setCurrentStep={setCurrentCStep}
                  formData={carrierForm}
                  handleFieldChange={(name, value) =>
                    handleCarrierForm(name, value)
                  }
                />
              )}
              {currentCStep === 2 && (
                <RegisterCStep2
                  setCurrentStep={setCurrentCStep}
                  formData={carrierForm}
                  handleFieldChange={(name, value) =>
                    handleCarrierForm(name, value)
                  }
                />
              )}
              {currentCStep === 3 && (
                <RegisterCStep3
                  setCurrentStep={setCurrentCStep}
                  formData={carrierForm}
                  handleFieldChange={(name, value) =>
                    handleCarrierForm(name, value)
                  }
                  loading={loading}
                  handleSubmit={(data) => handleSignup(data)}
                />
              )}
              <div
                className="register__tab_login_link"
                // onClick={() => navigate("/Login")}
              >
                Already have an account?{" "}
                <span
                  onClick={() => {
                    let destination = "/Login";
                    if (searchParams.toString()) {
                      destination = destination + "?" + searchParams.toString();
                    }
                    navigate(destination);
                  }}
                >
                  {" "}
                  Signin
                </span>{" "}
                .
              </div>
              {currentCStep === 1 && (
                <div
                  className="regiterpage__new_form_back_btn"
                  onClick={() => registerTabform(0)}
                >
                  Back
                </div>
              )}
            </div>
          </div>
        )}
        {activeStepFormTab === 3 && (
          <div className="register__tab_main">
            <div className="register__tab_left_col">
              <div className="register__tab_left_logo">
                <img
                  src={Logo}
                  alt=""
                  className="regform_sheader_image"
                  onClick={() => {
                    window.location.href = "https://www.hajexbolt.com";
                  }}
                />
              </div>
              <div className="register__tab_left_image">
                <img src={bgTab} alt="" />
              </div>
            </div>
            <div className="register__tab_right_col">
              <h3 className="register__tab_heading">Warehouse Register</h3>
              <CustomLStepper step={currentLStep} setStep={setCurrentLStep} />
              {currentLStep === 1 && (
                <>
                  <RegisterLSteps1
                    setCurrentLStep={setCurrentLStep}
                    formData={logisticForm}
                    handleFieldChange={(name, value) =>
                      handleLogisticForm(name, value)
                    }
                  />
                </>
              )}
              {currentLStep === 2 && (
                <RegisterLSteps2
                  setCurrentLStep={setCurrentLStep}
                  formData={logisticForm}
                  handleFieldChange={(name, value) =>
                    handleLogisticForm(name, value)
                  }
                />
              )}
              {currentLStep === 3 && (
                <RegisterLSteps3
                  setCurrentLStep={setCurrentLStep}
                  formData={logisticForm}
                  handleFieldChange={(name, value) =>
                    handleLogisticForm(name, value)
                  }
                  handleSubmit={(data) => handleSignup(data)}
                  loading={loading}
                />
              )}
              <div
                className="register__tab_login_link"
                // onClick={() => navigate("/Login")}
              >
                Already have an account?{" "}
                <span
                  onClick={() => {
                    let destination = "/Login";
                    if (searchParams.toString()) {
                      destination = destination + "?" + searchParams.toString();
                    }
                    navigate(destination);
                  }}
                >
                  {" "}
                  Signin
                </span>{" "}
                .
              </div>
              {currentLStep === 1 && (
                <div
                  className="regiterpage__new_form_back_btn"
                  onClick={() => registerTabform(0)}
                >
                  Back
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterForm;
