import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Stepper from "./Components/stepper";
import Row1 from "./Components/Row1";
import AdditionalServices from "./Components/AdditionalServices";
import Row2 from "./Components/Row2";
import Row3 from "./Components/Row3";
import FinalStep from "./Components/FinalStep";
import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addUser, updateUser } from "../../../../../store/userSlice";
import { setdata } from "../../../../../helpers/helpers";
import InternationalForm from "./Components/InternationalForm";
import { showSnackBar } from "../../../../../store/common/snackBarSlice";
import {
  quoteService,
  specficQuoteService,
  createShipmentService,
} from "../../../../../services/ShipperServices/Shipping/shipping.services";

function getNextDay() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const month = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
  const day = tomorrow.getDate().toString().padStart(2, "0");
  return `${tomorrow.getFullYear()}-${month}-${day}`;
}

const initial_internationl = {
  product: {
    Description: "",
    Country: "",
    OriginCountryCode: "",
    InsuranceAmount: "",
    ReasonForExport: "",
    TotalPriceAmount: "",
    ExportReasonType: "",
  },
  producer: {
    CompanyName: "",
    Country: "",
    CountryCode: "",
  },
};

const initial_stepcheck = {
  ShipmentCheck: false,
  QuoteCheck: false,
  Pickup_Address_bit: 1,
  Delivery_Address_bit: 1,
  Additional_services_bit: 1,
  Packages_bit: 1,
  Quote_bit: 1,
  insurancetype: "Shipment",
  TotalWeight: 0,
  TotalVolume: 0,
  type: "Package",
  unit: "Imperial",
  fullform: false,
  ShipmentType: "",
  serviceType: "",
  Shipmentby: "",
  QuoteData: [],
  SingleQuoteData: "",
  FinalData: "",
  Pending: true,
  Final_Order: false,
  Disable: "",
  Products: "Products",
  Package: true,
  Pallet: false,
  International: initial_internationl,
  Warehouses: "",
  TotalFulfilmentCost: 0,
  TotalDeclaration: 0,
};

const initial_Flist = {
  address: "",
  country: "",
  countrycode: "",
  state: "",
  statecode: "",
  city: "",
  zip: "",
  company: "",
  id: "",
  attention: "",
  phone: "",
  otime: "",
  ctime: "",
  residential: false,
  date: getNextDay(),
  email: "",
  notification: false,
  instruction: "",
  ref: "",
  type: "Flist",
};

const initial_Tlist = {
  address: "",
  country: "",
  countrycode: "",
  state: "",
  statecode: "",
  city: "",
  zip: "",
  company: "",
  id: "",
  attention: "",
  phone: "",
  email: "",
  instruction: "",
  residential: false,
  notification: false,
  otime: "",
  ctime: "",
  date: "",
  ref: "",
  type: "Tlist",
};

const initial_Alist = {
  signature: false,
  residential: false,
  notification: false,
  returnlabel: false,
  insurance: false,
  insurancevalue: "",
  fragile: false,
  paperinvoice: false,
  dangerious: false,
  appointment: false,
  thirdpartybilling: false,
  cashondelivery: false,
  cashondeliveryvalue: "",
  ref: "",
  tailgatedelivery: false,
  tailgatepickup: false,
};

const initial_Aplist = {
  signature: false,
  residential: false,
  notification: false,
  returnlabel: false,
  insurance: false,
  insurancevalue: "",
  fragile: false,
  paperinvoice: false,
  dangerious: false,
  appointment: false,
  thirdpartybilling: false,
  cashondelivery: false,
  cashondeliveryvalue: "",
  ref: "",
};

const initial_Plist = [
  {
    name: "",
    length: "",
    width: "",
    height: "",
    weight: "",
    volume: "",
    insurance: "",
    description: "",
    feright: "",
    nmfc: "",
    type: "",
    pieces: "",
    declaration: "",
  },
];

const initial_stepper = {
  Pickup_Address: 1,
  Delivery_Address: 1,
  Packages: 1,
  Quote: 1,
  Additional_services: 0,
  ShipmentCheck: false,
};

function Ship() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [stepcheck, setstepcheck] = useState(initial_stepcheck);

  const [Flist, setFlist] = useState(initial_Flist);

  const [Tlist, setTlist] = useState(initial_Tlist);

  const [Alist, setAlist] = useState(initial_Alist);

  const [Aplist, setAplist] = useState(initial_Aplist);

  const [Plist, setPlist] = useState(initial_Plist);

  const [SelectedProducts, setSelectedProducts] = useState([]);

  const [stepper, setstepper] = useState(initial_stepper);

  const Getquote = (event) => {
    event.preventDefault();

    setstepcheck((prevStepCheck) => ({
      ...prevStepCheck,
      Pending: true,
      QuoteCheck: true,
      QuoteData: [],
    }));

    const payload = {
      unit: stepcheck["unit"],
      fromzip: Flist["zip"],
      fromcountry: Flist["country"],
      fromstate: Flist["state"],
      fromcity: Flist["city"],
      fromcountrycode: Flist["countrycode"],
      fromstatecode: Flist["statecode"],
      fresidential: Flist["residential"],
      tozip: Tlist["zip"],
      tocountry: Tlist["country"],
      tostate: Tlist["state"],
      tocity: Tlist["city"],
      tocountrycode: Tlist["countrycode"],
      tostatecode: Tlist["statecode"],
      tresidential: Tlist["residential"],
      dimensions: Plist,
      totalweight: stepcheck["TotalWeight"],
      signature: Alist["signature"],

      insurance: Alist["insurance"],
      insurance_amount: Alist["insurancevalue"],
      insurance_type: stepcheck["insurancetype"],
      tailgate_pickup: Alist["tailgatepickup"],
      tailgate_delivery: Alist["tailgatedelivery"],
    };

    quoteService(stepcheck, setstepcheck, payload);
  };

  const GetSpecific = async (event) => {
    event.preventDefault();

    const requestData = {
      unit: stepcheck["unit"],
      totalweight: stepcheck["TotalWeight"],
      type: stepcheck["ShipmentType"],
      service_type: stepcheck["serviceType"],

      fromzip: Flist["zip"],
      fromcountry: Flist["country"],
      fromstate: Flist["state"],
      fromcity: Flist["city"],
      fromcountrycode: Flist["countrycode"],
      fromstatecode: Flist["statecode"],
      fresidential: Flist["residential"],
      fromnotification: Flist["notification"],

      tocountry: Tlist["country"],
      tostate: Tlist["state"],
      tocity: Tlist["city"],
      tozip: Tlist["zip"],
      tocountrycode: Tlist["countrycode"],
      tostatecode: Tlist["statecode"],
      tresidential: Tlist["residential"],
      tonotification: Tlist["notification"],

      signature: Alist["signature"],
      dangerous: Alist["dangerious"],
      fragile: Alist["fragile"],
      return_label: Alist["returnlabel"],
      paper_invoice: Alist["paperinvoice"],
      appointment: Alist["appointment"],
      cash_on_delivery: Alist["cashondelivery"],
      cash_on_delivery_value: Alist["cashondeliveryvalue"],

      pickup_date: Flist["date"],
      delivery_date: Tlist["date"],

      insurance: Alist["insurance"],
      insurance_amount: Alist["insurancevalue"],
      insurance_type: stepcheck["insurancetype"],
      tailgate_pickup: Alist["tailgatepickup"],
      tailgate_delivery: Alist["tailgatedelivery"],

      dimensions: Plist,
    };
    setstepcheck((prevStepCheck) => ({
      ...prevStepCheck,
      Disable: "finalquote",
      QuoteCheck: true,
    }));

    try {
      const response = await specficQuoteService(
        stepcheck["Shipmentby"],
        requestData
      );
      if (!response?.error && response?.data?.success === true) {
        setstepcheck((prevStepCheck) => ({
          ...prevStepCheck,
          QuoteCheck: true,
          SingleQuoteData: response?.data?.result[0],
          serviceType: response?.data?.result[0]["service_type"],
          Disable: "",
          Final_Order: true,
        }));
      } else {
        setstepcheck((prevStepCheck) => ({
          ...prevStepCheck,
          Disable: "",
          QuoteCheck: true,
        }));
        dispatch(
          showSnackBar({
            text: response?.data?.message ?? "Something went wrong",
            severity: "error",
          })
        );
      }
    } catch (error) {
      setstepcheck((prevStepCheck) => ({
        ...prevStepCheck,
        Disable: "",
        QuoteCheck: true,
      }));
      dispatch(
        showSnackBar({
          text: error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        })
      );
    }
  };

  const CreateShipment = async (event) => {
    event.preventDefault();
    setstepcheck((prevStepCheck) => ({
      ...prevStepCheck,
      Disable: "final",
    }));

    let salePrice = 0;
    const itemsArray = SelectedProducts.map((product, index) => {
      // Extract relevant information from each product
      salePrice += parseFloat(product.sale_price ?? 0) * product.quantity;
      const item = {
        item_id: product.id,
        productLotData: product?.productLotData ?? null,
        active_price: product.sale_price,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        sku: product.code,
        fulfillment_cost: product.fulfillment_cost,
        product_name: product.full_name,
        item_quantity: product.quantity,
        length: product.length,
        width: product.width,
        height: product.height,
        weight: product.weight,
        product_length_type: "Imperial",
        bolt_package_detail: product.package_details,
        item_subtotal: product.sale_price,
        item_subto_tax: 0,
        item_total: product.sale_price,
        item_total_tax: 0,
        item_tax_status: "taxable",
        note: product.note ?? "",
      };

      return item;
    });

    const order_details = {
      warehouse_id: stepcheck["Warehouses"],
      currency: "CAD",
      discount_total: 0,
      discount_tax: 0,
      type: "Manual",
      order_status_id: "2",
      total: salePrice,
      total_tax: 0,
      status: "Confirmed",
      items: itemsArray,
      fulfillment_cost: stepcheck.TotalFulfilmentCost,
      formula: {
        TotalFulfilmentCost: stepcheck.TotalFulfilmentCost,
        TotalWeightCost: stepcheck.TotalWeightCost,
        TotalPreCost: stepcheck.TotalPreCost,
        TotalFulfilmentWeight: stepcheck.TotalFulfilmentWeight,
        TotalPreProduct: stepcheck.TotalPreProduct,
        UnitType: stepcheck.UnitType,
        Formula: stepcheck.Formula,
      },
      warehouse: {
        full_address: Flist["address"],
        country: Flist["countrycode"],
        country_name: Flist["country"],
        state_provience: Flist["statecode"],
        state_name: Flist["state"],
        city_town: Flist["city"],
        zip_postal: Flist["zip"],
        company: Flist["company"],
        attention: Flist["attention"],
        phone: Flist["phone"],
        email: Flist["email"],
      },
      billing: {
        first_name: Tlist["attention"],
        company: Tlist["company"],
        address_1: Tlist["address"],
        address_2: "",
        city: Tlist["city"],
        state: Tlist["statecode"],
        postcode: Tlist["zip"],
        country: Tlist["countrycode"],
        email: Tlist["email"],
        phone: Tlist["phone"],
      },
      shipping: {
        first_name: Tlist["attention"],
        company: Tlist["company"],
        address_1: Tlist["address"],
        address_2: "",
        city: Tlist["city"],
        state: Tlist["statecode"],
        postcode: Tlist["zip"],
        country: Tlist["countrycode"],
        phone: Tlist["phone"],
      },
    };

    const Data = {
      company_id: stepcheck["Shipmentby"],
      unit: stepcheck["unit"],
      package_type: stepcheck["type"],
      total_weight: stepcheck["TotalWeight"],
      volume: stepcheck["TotalVolume"],

      insurance: Alist["insurance"],
      insurance_amount: Alist["insurancevalue"],
      insurance_type: stepcheck["insurancetype"],
      tailgate_pickup: Alist["tailgatepickup"],
      tailgate_delivery: Alist["tailgatedelivery"],

      signature: Alist["signature"],
      return_label: Alist["returnlabel"],
      shipment_status: "Creation",
      dangerous: Alist["dangerious"],
      fragile: Alist["fragile"],
      paper_invoice: Alist["paperinvoice"],
      appointment: Alist["appointment"],
      cash_on_delivery: Alist["cashondelivery"],
      cash_on_delivery_value: Alist["cashondeliveryvalue"],

      fcountry: Flist["country"],
      fcountrycode: Flist["countrycode"],
      fcity: Flist["city"],
      fstate: Flist["state"],
      fstatecode: Flist["statecode"],
      fzip: Flist["zip"],
      fid: Flist["id"],
      fcompany: Flist["company"],
      fphone: Flist["phone"],
      fattension: Flist["attention"],
      femail: Flist["email"],
      fnotification: Flist["notification"],
      finstructions: Flist["instruction"],
      readyopentime: Flist["otime"],
      readyclosetime: Flist["ctime"],
      shipdate: Flist["date"],
      faddress: Flist["address"],
      fresidential: Flist["residential"],
      f_reference: Flist["ref"],

      tcountry: Tlist["country"],
      tcountrycode: Tlist["countrycode"],
      tcity: Tlist["city"],
      tstate: Tlist["state"],
      tstatecode: Tlist["statecode"],
      tzip: Tlist["zip"],
      tcompany: Tlist["company"],
      tid: Tlist["id"],
      tphone: Tlist["phone"],
      tattension: Tlist["attention"],
      temail: Tlist["email"],
      tnotification: Tlist["notification"],
      tinstructions: Tlist["instruction"],
      taddress: Tlist["address"],
      tresidential: Tlist["residential"],
      deliveryopentime: Tlist["otime"],
      deliveryclosetime: Tlist["ctime"],
      referance_code: Tlist["ref"],

      dimensions: Plist,
      shipment_details: JSON.stringify(Plist),

      estimated_delivery_date: stepcheck["SingleQuoteData"]["delivery_date"],
      delivery_date: stepcheck["SingleQuoteData"]["delivery_date"],
      shipping_company_id: stepcheck["SingleQuoteData"]["comp_id"],
      BasePrice: stepcheck["SingleQuoteData"]["base_charge"],
      SubTotal: stepcheck["SingleQuoteData"]["base_charge"],
      Total: stepcheck["SingleQuoteData"]["total"],
      service_charge: stepcheck?.SingleQuoteData?.service_charge ?? 0,
      pickup_zone: stepcheck["SingleQuoteData"]["pickupZone"],
      delivery_zone: stepcheck["SingleQuoteData"]["deliveryZone"],
      type: stepcheck["ShipmentType"],
      service_type: stepcheck["SingleQuoteData"]["service_type"],
      response: stepcheck["SingleQuoteData"],
      credit_card_payment_method_id: stepcheck["credit_card_payment_method_id"],

      international:
        Tlist.country &&
        Tlist.countrycode &&
        Flist.country &&
        Flist.countrycode &&
        !(
          ["Canada", "canada"].includes(Tlist.country) &&
          ["CA", "ca"].includes(Tlist.countrycode) &&
          ["Canada", "canada"].includes(Flist.country) &&
          ["CA", "ca"].includes(Flist.countrycode)
        )
          ? stepcheck["International"]
          : [],

      order: order_details,
      order_id: stepcheck["Order_id"] ?? "",
      fulfillment_cost: stepcheck.TotalFulfilmentCost,
      sale:
        SelectedProducts.length > 0 && stepcheck["Warehouses"] ? true : false,
    };
    const response = await createShipmentService(stepcheck["Shipmentby"], Data);
    if (!response?.error) {
      if (response?.data?.success === true) {
        setstepcheck((prevStepCheck) => ({
          ...prevStepCheck,
          Disable: "",
          ShipmentCheck: true,
          Final_Order: false,
          FinalData: response?.data?.result,
        }));

        // Updating Store and Local Storage
        let key = "balance";
        let value = response.data.result.userBalance;
        const updatedUser = {
          ...user,
          balance: value,
          unReadShipments: (user.unReadShipments ?? 0) + 1,
          unReadPickups: (user.unReadPickups ?? 0) + 1,
          unReadTrackings: (user.unReadTrackings ?? 0) + 1,
          unReadInvoices: (user.unReadInvoices ?? 0) + 1,
        };
        dispatch(addUser(updatedUser));

        localStorage.setItem("user", JSON.stringify(updatedUser));
        // Updating Store and Local Storage
        dispatch(
          showSnackBar({
            text: response?.data?.message,
            severity: "success",
          })
        );
      } else {
        dispatch(
          showSnackBar({
            text: response?.data?.message ?? "Something went wrong",
            severity: "error",
          })
        );
        setstepcheck((prevStepCheck) => ({
          ...prevStepCheck,
          Disable: "",
        }));
      }
    } else {
      dispatch(
        showSnackBar({
          text:
            response?.error?.response?.data?.message ?? "Something went wrong",
          severity: "error",
        })
      );
      setstepcheck((prevStepCheck) => ({
        ...prevStepCheck,
        Disable: "",
      }));
    }
  };

  useEffect(() => {
    // Address
    let Pickup_Address = 1;
    let Delivery_Address = 1;
    if (stepcheck["fullform"] === false) {
      // Pickup Address
      if (
        Flist["country"] !== "" &&
        Flist["city"] !== "" &&
        Flist["state"] !== "" &&
        Flist["zip"] !== ""
      ) {
        if (stepcheck["Pickup_Address_bit"] === 1) {
          Pickup_Address = 1;
        } else {
          Pickup_Address = 0;
        }
      } else {
        if (
          stepcheck["Pickup_Address_bit"] === 1 &&
          stepcheck["Delivery_Address_bit"] === 1 &&
          stepcheck["Packages_bit"] === 1
        ) {
          Pickup_Address = 0;
        } else {
          Pickup_Address = 2;
        }
      }
      // Pickup Address

      // Delivery Address
      if (
        Tlist["country"] !== "" &&
        Tlist["city"] !== "" &&
        Tlist["state"] !== "" &&
        Tlist["zip"] !== ""
      ) {
        if (stepcheck["Delivery_Address_bit"] === 1) {
          Delivery_Address = 0;
        } else {
          Delivery_Address = 1;
        }
      } else {
        if (
          stepcheck["Delivery_Address_bit"] === 1 &&
          stepcheck["Packages_bit"] === 1
        ) {
          Delivery_Address = 0;
        } else {
          Delivery_Address = 2;
        }
      }
      // Delivery Address
    } else {
      // Pickup Address
      if (
        Flist["address"] !== "" &&
        Flist["country"] !== "" &&
        Flist["city"] !== "" &&
        Flist["state"] !== "" &&
        Flist["zip"] !== "" &&
        Flist["company"] !== "" &&
        Flist["phone"] !== "" &&
        Flist["attension"] !== "" &&
        // Flist[0]["femail"] !== "" &&
        Flist["date"] !== ""
      ) {
        if (stepcheck["Pickup_Address_bit"] === 1) {
          Pickup_Address = 1;
        } else {
          Pickup_Address = 0;
        }
      } else {
        if (
          stepcheck["Pickup_Address_bit"] === 1 &&
          stepcheck["Delivery_Address_bit"] === 1 &&
          stepcheck["Packages_bit"] === 1
        ) {
          Pickup_Address = 0;
        } else {
          Pickup_Address = 2;
        }
      }
      // Pickup Address

      // Delivery Address
      if (
        Tlist["address"] !== "" &&
        Tlist["country"] !== "" &&
        Tlist["city"] !== "" &&
        Tlist["state"] !== "" &&
        Tlist["zip"] !== "" &&
        Tlist["company"] !== "" &&
        Tlist["phone"] !== "" &&
        Tlist["attension"] !== ""
        // Tlist[0]["temail"] !== "" &&
        // Tlist[0]["tdeliverdate"] !== ""
      ) {
        if (stepcheck["Delivery_Address_bit"] === 1) {
          Delivery_Address = 0;
        } else {
          Delivery_Address = 1;
        }
      } else {
        if (
          stepcheck["Delivery_Address_bit"] === 1 &&
          stepcheck["Packages_bit"] === 1
        ) {
          Delivery_Address = 0;
        } else {
          Delivery_Address = 2;
        }
      }
      // Delivery Address
    }
    // Address

    // Packages
    let Packages = 1;
    Plist.forEach((element) => {
      if (
        element.length !== "" &&
        element.height !== "" &&
        element.width !== "" &&
        element.weight !== ""
      ) {
        if (stepcheck["Packages_bit"] === 1) {
          Packages = 0;
        } else {
          Packages = 1;
        }
      } else {
        if (stepcheck["Packages_bit"] === 1) {
          Packages = 0;
        } else {
          Packages = 2;
        }
      }
      if (
        Alist["insurance"] === true &&
        stepcheck["insurancetype"] !== "Shipment"
      ) {
        if (element.insurance !== "") {
          if (stepcheck["Packages_bit"] === 1) {
            Packages = 0;
          } else {
            Packages = 1;
          }
        } else {
          if (stepcheck["Packages_bit"] === 1) {
            Packages = 0;
          } else {
            Packages = 2;
          }
        }
      }
    });
    // Packages

    //  Quote
    let Quote = 1;
    if (stepcheck["Shipmentby"] === "") {
      Quote = 0;
    } else {
      Quote = 1;
    }
    // Quote

    //  Additonal Services
    let Additional_services = 1;
    if (
      Alist["insurance"] === true &&
      stepcheck["insurancetype"] === "Shipment" &&
      Alist["insurancevalue"] === ""
    ) {
      Additional_services = 2;
    } else {
      if (
        stepcheck["Additional_services_bit"] === 1 &&
        stepcheck["Packages_bit"] === 1
      ) {
        Additional_services = 0;
      } else {
        Additional_services = 1;
      }
    }
    // Additonal Services

    setstepper({
      ...stepcheck,
      Pickup_Address: Pickup_Address,
      Delivery_Address: Delivery_Address,
      Packages: Packages,
      Quote: Quote,
      Additional_services: Additional_services,
      ShipmentCheck: stepcheck["ShipmentCheck"],
    });
  }, [Flist, Tlist, stepcheck, Alist]);

  const reset = () => {
    setstepcheck(initial_stepcheck);
    setFlist(initial_Flist);
    setTlist(initial_Tlist);
    setAlist(initial_Alist);
    setPlist(initial_Plist);
    setstepper(initial_stepper);
  };

  useEffect(() => {
    const storedReship = localStorage.getItem("Reship");
    if (!storedReship) {
      localStorage.removeItem("Reship");
      return;
    }

    const Reship = JSON.parse(storedReship);
    localStorage.removeItem("Reship");

    const {
      faddress,
      fcountry,
      fcountrycode,
      fstate,
      fstatecode,
      fcity,
      fzip,
      fcompany,
      fid,
      fattension,
      fphone,
      readyopentime,
      readyclosetime,
      fresidential,
      femail,
      fnotification,
      finstructions,
      taddress,
      tcountry,
      tcountrycode,
      tstate,
      tstatecode,
      tcity,
      tzip,
      tcompany,
      tid,
      tattension,
      tphone,
      deliveryopentime,
      deliveryclosetime,
      tresidential,
      temail,
      tnotification,
      tinstructions,
      referance_code,
      signature,
      return_label,
      insurance,
      insurance_amount,
      fragile,
      paper_invoice,
      dangerous,
      appointment,
      cash_on_delivery,
      cash_on_delivery_value,
      insurance_type,
      package_type,
      unit,
      international,
      shipment_details,
    } = Reship;

    setFlist({
      address: faddress ?? "",
      country: fcountry ?? "",
      countrycode: fcountrycode ?? "",
      state: fstate ?? "",
      statecode: fstatecode ?? "",
      city: fcity ?? "",
      zip: fzip ?? "",
      company: fcompany ?? "",
      id: fid ?? "",
      attention: fattension ?? "",
      phone: fphone ?? "",
      otime: readyopentime ?? "",
      ctime: readyclosetime ?? "",
      residential: fresidential,
      date: getNextDay(),
      email: femail ?? "",
      notification: fnotification,
      instruction: finstructions ?? "",
      ref: "",
      type: "Flist",
    });

    setTlist({
      address: taddress ?? "",
      country: tcountry ?? "",
      countrycode: tcountrycode ?? "",
      state: tstate ?? "",
      statecode: tstatecode ?? "",
      city: tcity ?? "",
      zip: tzip ?? "",
      company: tcompany ?? "",
      id: tid ?? "",
      attention: tattension ?? "",
      phone: tphone ?? "",
      otime: deliveryopentime ?? "",
      ctime: deliveryclosetime ?? "",
      residential: tresidential,
      date: "",
      email: temail ?? "",
      notification: tnotification,
      instruction: tinstructions ?? "",
      ref: referance_code ?? "",
      type: "Tlist",
    });

    setAlist({
      signature,
      residential: false,
      notification: false,
      returnlabel: return_label,
      insurance,
      insurancevalue: insurance_amount ?? "",
      fragile,
      paperinvoice: paper_invoice,
      dangerious: dangerous,
      appointment,
      thirdpartybilling: false,
      cashondelivery: cash_on_delivery,
      cashondeliveryvalue: cash_on_delivery_value ?? "",
    });

    setstepcheck({
      ShipmentCheck: false,
      QuoteCheck: false,
      Pickup_Address_bit: 0,
      Delivery_Address_bit: 0,
      Additional_services_bit: 0,
      Packages_bit: 0,
      Quote_bit: 1,
      insurancetype: insurance_type ?? "Shipment",
      TotalWeight: 0,
      TotalVolume: 0,
      type: package_type ?? "Package",
      unit: unit ?? "Imperial",
      fullform: true,
      ShipmentType: "",
      Shipmentby: "",
      QuoteData: [],
      SingleQuoteData: "",
      FinalData: "",
      Pending: true,
      Final_Order: false,
      Disable: "",
      Products: "Packages",
      Package: true,
      Pallet: false,
      International: international?.length
        ? international
        : initial_internationl,
      Warehouses: "",
      TotalFulfilmentCost: 0,
    });

    setPlist(
      shipment_details?.map(
        ({
          length,
          width,
          height,
          weight,
          volume,
          insurance,
          description,
          feright,
          nmfc,
          type,
          pieces,
        }) => ({
          name: "",
          length: length ?? "",
          width: width ?? "",
          height: height ?? "",
          weight: weight ?? "",
          volume: volume ?? "",
          insurance: insurance ?? "",
          description: description ?? "",
          feright: feright ?? "",
          nmfc: nmfc ?? "",
          type: type ?? "",
          pieces: pieces ?? "",
        })
      ) || []
    );
  }, []);

  const loadOrderShipment = useCallback(() => {
    try {
      const orderShipment = localStorage.getItem("OrderShipment");

      if (!orderShipment) {
        return;
      }

      const parsedOrderShipment = JSON.parse(orderShipment);

      const pickupDetails =
        parsedOrderShipment?.order_pickup_details?.[0] || {};
      const shippingAddressDetails =
        parsedOrderShipment?.order_shipping_address_details || {};
      const billingAddressDetails =
        parsedOrderShipment?.order_billing_address_details || {};

      // Consolidating state updates
      setFlist({
        address: pickupDetails?.full_address ?? "",
        country: pickupDetails?.country_name ?? "",
        countrycode: pickupDetails?.country ?? "",
        state: pickupDetails?.state_name ?? "",
        statecode: pickupDetails?.state_provience ?? "",
        city: pickupDetails?.city_town ?? "",
        zip: pickupDetails?.zip_postal ?? "",
        company: pickupDetails?.company ?? "",
        id: "",
        attention: pickupDetails?.attention ?? "",
        phone: pickupDetails?.phone ?? "",
        otime: "",
        ctime: "",
        residential: false,
        date: getNextDay(),
        email: pickupDetails?.email ?? "",
        notification: false,
        instruction: "",
        ref: "",
        type: "Flist",
      });

      setTlist({
        address: `${shippingAddressDetails?.address_1 ?? ""} ${
          shippingAddressDetails?.address_2 ?? ""
        }`.trim(),
        country: shippingAddressDetails?.country_name ?? "",
        countrycode: shippingAddressDetails?.country ?? "",
        state: shippingAddressDetails?.state_name ?? "",
        statecode: shippingAddressDetails?.state ?? "",
        city: shippingAddressDetails?.city ?? "",
        zip: shippingAddressDetails?.postcode ?? "",
        company: `${shippingAddressDetails?.first_name ?? ""} ${
          shippingAddressDetails?.last_name ?? ""
        }`,
        id: "",
        attention: `${shippingAddressDetails?.first_name ?? ""} ${
          shippingAddressDetails?.last_name ?? ""
        }`,
        phone: billingAddressDetails?.phone ?? "",
        otime: "",
        ctime: "",
        residential: false,
        date: "",
        email: billingAddressDetails?.email ?? "",
        notification: false,
        instruction: parsedOrderShipment?.customer_note ?? "",
        ref: parsedOrderShipment?.order_id ?? "",
        type: "Tlist",
      });

      setAlist({
        signature: false,
        residential: false,
        notification: false,
        returnlabel: false,
        insurance: false,
        insurancevalue: "",
        fragile: false,
        paperinvoice: false,
        dangerious: false,
        appointment: false,
        thirdpartybilling: false,
        cashondelivery: false,
        cashondeliveryvalue: "",
      });

      setstepcheck({
        ShipmentCheck: false,
        QuoteCheck: false,
        Pickup_Address_bit: 0,
        Delivery_Address_bit: 0,
        Additional_services_bit: 0,
        Packages_bit: 0,
        Quote_bit: 1,
        insurancetype: "Shipment",
        TotalWeight: 0,
        TotalVolume: 0,
        type: "Package",
        unit: "Imperial",
        fullform: true,
        ShipmentType: "",
        serviceType:
          parsedOrderShipment?.selected_shipping_detail?.["Service-type"] ?? "",
        Shipmentby:
          parsedOrderShipment?.selected_shipping_detail?.["Comp-id"] ?? "",
        QuoteData: [],
        SingleQuoteData: "",
        FinalData: "",
        Pending: true,
        Final_Order: false,
        Disable: "",
        Products: "Products",
        Package: true,
        Pallet: false,
        International: initial_internationl,
        Warehouses: parsedOrderShipment?.warehouse_id ?? "",
        TotalFulfilmentCost: 0,
        Order_id: parsedOrderShipment?.id,
      });

      const newPlist = [];
      const newSelected = [];

      if (parsedOrderShipment?.order_details?.length) {
        parsedOrderShipment.order_details.forEach((item) => {
          const quantity = parseInt(item.item_quantity, 10);
          for (let i = 0; i < quantity; i++) {
            const newItem = {
              name: item.product_name || "",
              description: item.product_name || "",
              type: item.product_length_type || "Imperial",
              pieces: "",
            };

            // Process Bolt Package Details
            if (item?.bolt_package_detail?.length) {
              item.bolt_package_detail.forEach((boltItem) => {
                const isMetric = item.product_length_type === "Metric";
                newPlist.push({
                  ...newItem,
                  length: convertToImperial(
                    boltItem.length,
                    "length",
                    isMetric
                  ),
                  width: convertToImperial(boltItem.width, "width", isMetric),
                  height: convertToImperial(
                    boltItem.height,
                    "height",
                    isMetric
                  ),
                  weight: convertToImperial(
                    boltItem.weight,
                    "weight",
                    isMetric
                  ),
                });
              });
            } else {
              newPlist.push({
                ...newItem,
                length: convertToImperial(
                  item.length,
                  "length",
                  item.product_length_type === "Metric"
                ),
                width: convertToImperial(
                  item.width,
                  "width",
                  item.product_length_type === "Metric"
                ),
                height: convertToImperial(
                  item.height,
                  "height",
                  item.product_length_type === "Metric"
                ),
                weight: convertToImperial(
                  item.weight,
                  "weight",
                  item.product_length_type === "Metric"
                ),
              });
            }

            if (i === 0 && item.products) {
              item.products.quantity = quantity;
              newSelected.push(item.products);
            }
          }
        });

        setPlist(newPlist);
        setSelectedProducts(newSelected);
      } else {
        setPlist(initial_Plist);
      }

      // Function to convert metric to imperial
      function convertToImperial(metricValue, dataType, isMetric) {
        if (!isMetric || !metricValue) return metricValue;
        const value = parseFloat(metricValue);
        switch (dataType) {
          case "length":
          case "width":
          case "height":
            return (value / 2.54).toFixed(2);
          case "weight":
            return (value * 2.205).toFixed(2);
          default:
            return value;
        }
      }
    } catch (error) {
      console.error("Error while processing order shipment:", error);
    } finally {
      localStorage.removeItem("OrderShipment");
    }
  }, []);

  useEffect(() => {
    loadOrderShipment();
  }, [loadOrderShipment]);

  // console.log(Flist);
  // console.log(Tlist);
  // console.log(Alist);
  // console.log(Plist);
  // console.log(stepcheck);
  // useEffect(() => {
  // console.log(SelectedProducts);
  // }, [SelectedProducts]);

  return (
    <>
      <Stepper stepcheck={stepper} />

      {stepcheck["ShipmentCheck"] === false && (
        <Form
          onSubmit={
            stepcheck["QuoteCheck"] === false
              ? Getquote
              : stepcheck["Final_Order"] === true
              ? CreateShipment
              : GetSpecific
          }
        >
          {/* Row1 */}
          <Row1
            Flist={Flist}
            SetFlist={setFlist}
            Tlist={Tlist}
            SetTlist={setTlist}
            StepCheck={stepcheck}
            SetStepCheck={setstepcheck}
          />
          {/* Row1 */}

          {/* Addition services  */}
          <AdditionalServices
            List={Alist}
            SetList={setAlist}
            StepCheck={stepcheck}
            SetStepCheck={setstepcheck}
            Tlist={Tlist}
            setTlist={setTlist}
            Plist={Aplist}
          />
          {/* Addition services  */}

          {/* Row2 */}
          <Row2
            Plist={Plist}
            isInternational={
              Tlist.country &&
              Tlist.countrycode &&
              Flist.country &&
              Flist.countrycode &&
              !(
                ["Canada", "canada"].includes(Tlist.country) &&
                ["CA", "ca"].includes(Tlist.countrycode) &&
                ["Canada", "canada"].includes(Flist.country) &&
                ["CA", "ca"].includes(Flist.countrycode)
              )
            }
            SetPlist={setPlist}
            Alist={Alist}
            SetAlist={setAlist}
            StepCheck={stepcheck}
            SetStepCheck={setstepcheck}
            Stepper={stepper}
            SelectedProducts={SelectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
          {/* Row2 */}

          {/* Internationl Shipment Form */}
          <InternationalForm
            StepCheck={stepcheck}
            SetStepCheck={setstepcheck}
            Tlist={Tlist}
            Flist={Flist}
            Plist={Plist}
          />
          {/* Internationl Shipment Form */}

          {/* Row3 */}
          <Row3
            StepCheck={stepcheck}
            Flist={Flist}
            Tlist={Tlist}
            Plist={Plist}
            SetStepCheck={setstepcheck}
            CreateShipment={CreateShipment}
          />
          {/* Row3 */}
        </Form>
      )}

      <FinalStep
        StepCheck={stepcheck}
        reset={reset}
        SetStepCheck={setstepcheck}
      />
    </>
  );
}

export default Ship;
