import "swiper/css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store/store";

import Animatedroutes from "./Components/Router/Animatedroutes";
import LoadMap from "./Components/LoadMap";
import CustomSnackbar from "./Components/Popups/CustomSnackBar";

import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from "./theme";
import AppOverlay from "./Components/Common/AppOverlay";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AppOverlay />
          <LoadMap />
          <CustomSnackbar />
          <Animatedroutes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
