import React, { useState } from "react";
import "./ShipperDetails.css";

// components imports
import ManageServices from "../ManageServices/ManageServices";
import SwitchButton from "../../../../../../../Components/SwitchButton/SwitchButton";
// apis imports
import { updateCompanyStatus } from "../../../../../../../services/AdminServices/Users/users.services";
import { toCamelCase } from "../../../../../../../helpers/helpers";
// library imports
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import { FaRegTimesCircle } from "react-icons/fa";
import { BiUser } from "react-icons/bi";
import { AiOutlineEdit } from "react-icons/ai";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  boxShadow: 24,
  p: 4,
  padding: "30px",
  backgroundColor: "white",
  maxHeight: "95vh",
  overflowY: "auto",
  "&::scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar": {
    width: "5",
  },
};

export default function ShipperDetails({
  open,
  setOpen,
  data,
  setSnack,
  setUsers,
  setDetailsModal,
}) {
  const handleClose = () => setOpen(false);

  const [statusUpdating, setStatusUpdating] = useState();
  const [servicesModal, setServicesModal] = useState();
  const handleStatusChange = async (e, name) => {
    setStatusUpdating(true);
    const updated = await updateCompanyStatus(name, data.id);
    setStatusUpdating(false);
    if (updated.error) {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: updated.error.message,
          severity: "error",
        };
      });
    } else {
      setUsers((prevUser) => {
        return {
          ...prevUser,
          data: prevUser?.data.map((item) =>
            item.id === data.id ? updated?.data?.result : item
          ),
        };
      });
      setDetailsModal(updated?.data?.result);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: `Status of ${name} changed to ${
            !e.target.checked ? "Active" : "Inactive"
          }`,
          severity: !e.target.checked ? "success" : "info",
        };
      });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="aduser__details_modal_wrap">
          <div className="aduser__details_modal">
            <ManageServices
              open={!!servicesModal}
              carrier={servicesModal}
              setOpen={setServicesModal}
              setSnack={setSnack}
              Android12Switch={SwitchButton}
            />
            <div className="aduser__details_modalclose" onClick={handleClose}>
              <IconButton>
                <FaRegTimesCircle size={25} color="lightgrey" />
              </IconButton>
            </div>
            <div className="aduser__details_heading">
              <BiUser size={35} /> <h2>Shipper Details</h2>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">ID:</div>
                <div className="aduser__details_modalcol">
                  {data?.id ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">User Name:</div>
                <div className="aduser__details_modalcol">
                  {" "}
                  {data?.username ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">First Name:</div>
                <div className="aduser__details_modalcol">
                  {data?.firstname ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Last Name:</div>
                <div className="aduser__details_modalcol">
                  {" "}
                  {data?.lastname ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Business Name:
                </div>
                <div className="aduser__details_modalcol">
                  {data?.business_name ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Industry Type:
                </div>
                <div className="aduser__details_modalcol">
                  {" "}
                  {data?.industry_type ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Email:</div>
                <div className="aduser__details_modalcol">
                  {data?.email ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Phone Number:
                </div>
                <div className="aduser__details_modalcol">
                  {" "}
                  {data?.phone_number ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Address:</div>
                <div className="aduser__details_modalcol">
                  {data?.address ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Country:</div>
                <div className="aduser__details_modalcol">
                  {" "}
                  {data?.Country ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Province:</div>
                <div className="aduser__details_modalcol">
                  {data?.province ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">City:</div>
                <div className="aduser__details_modalcol">
                  {" "}
                  {data?.city ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Zip Code:</div>
                <div className="aduser__details_modalcol">
                  {data?.zip ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Est Shipment Per Month:
                </div>
                <div className="aduser__details_modalcol">
                  {" "}
                  {data?.shipment_per_month ?? "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Gross Revenue Per Year:
                </div>
                <div className="aduser__details_modalcol">
                  {data?.revenue && "$"}
                  {data?.revenue ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Marketplaces:
                </div>
                <div className="aduser__details_modalcol">
                  {" "}
                  {data?.market?.length
                    ? data["market"]?.map((markets) => (
                        <p className="mr-1">{markets["market"]}</p>
                      ))
                    : "-"}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">
                  Hear About Us:
                </div>
                <div className="aduser__details_modalcol">
                  {" "}
                  {data?.about_us ?? "-"}
                </div>
              </div>
              <div className="aduser__details_modalrowitem">
                <div className="aduser__details_modalcolbold">Website:</div>
                <div className="aduser__details_modalcol">
                  {data?.website ? (
                    <a
                      href={
                        data?.website.startsWith("http")
                          ? data?.website
                          : `http://${data?.website}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data?.website}
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
            <div className="aduser__details_modalrow">
              <div className="aduser__details_companiesbox">
                <h3 className="aduser__details_comboxheading">
                  Companies Status
                </h3>
                <div className="aduser__details_comboxlist">
                  <div className="aduser__details_comboxlistitem aduser__details_comboxlistitembold">
                    <div className="aduser__details_comboxlileft">
                      Companies
                    </div>
                    <div className="aduser__details_comboxliright"> Status</div>
                    <div className="aduser__details_comboxlirsrvcs">
                      {" "}
                      Manage Services
                    </div>
                  </div>
                  <div className="aduser__details_comboxlistcontent">
                    {data?.companies_status &&
                      Object.keys(data?.companies_status)?.map((company) => (
                        <div className="aduser__details_comboxlistitem">
                          <div className="aduser__details_comboxlileft">
                            {company?.toUpperCase()}
                          </div>
                          <div
                            className={`aduser__details_comboxliright ${
                              statusUpdating
                                ? "dispscreen__statusswitch_datainactive"
                                : ""
                            }`}
                          >
                            <SwitchButton
                              onChange={(e) => {
                                handleStatusChange(e, company);
                              }}
                              checked={data?.companies_status[company]}
                            />
                          </div>
                          <div className={`aduser__details_comboxliright`}>
                            <AiOutlineEdit
                              onClick={() =>
                                setServicesModal(
                                  data?.user_shipping_companies?.find(
                                    (comp) =>
                                      toCamelCase(comp?.name) === company
                                  )
                                )
                              }
                              style={{
                                fontSize: "22px",
                                color: "#0C6EFD",
                                cursor: "pointer",
                                margin: "auto",
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
