import React, { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  Button,
  TextField,
  IconButton,
  Collapse,
  Box,
  Switch,
  MenuItem,
  Select,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { storeOrderChange } from "../../../../../services/orders.services";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import CircularLoader from "../../../../../Components/CircularLoader/CircularLoader";
import noImage from "../../../../../assets/images/noImage.png";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function ConformModal({
  open,
  setOpen,
  setSnack,
  setOrders,
  selectedStatus,
  data,
  setData,
  orderStatus,
  setselectedStatus,
}) {
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [notes, setnotes] = useState();
  const [StatusName, setStatusName] = useState();
  const [loader, setloader] = useState(false);
  const [orderDetails, setorderDetails] = useState([]);

  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleClose = () => {
    setStatusName();
    setnotes();
    setAttachedFiles([]);
    setorderDetails([]);
    setOpen(false);
  };

  const handleCancel = () => {
    setselectedStatus(data?.fullfilment_status?.id);
    handleClose();
  };

  useEffect(() => {
    setStatusName(
      orderStatus
        .find((order) => order.id === selectedStatus)
        ?.name?.toUpperCase()
    );
  }, [selectedStatus]);

  useEffect(() => {
    setorderDetails(data?.order_details);
  }, [data?.order_details, open]);

  const handleFileChange = (e) => {
    const files = e.target.files;

    for (const file of files) {
      const reader = new FileReader();
      const lastDotIndex = file.name?.lastIndexOf(".");

      if (lastDotIndex !== -1) {
        const name = file.name?.substring(0, lastDotIndex);
        const extension = file.name?.substring(lastDotIndex);

        // Create a closure to capture the correct newFiles array
        reader.onload = (function (currentFile) {
          return function (e) {
            const newFile = {
              src: e.target.result,
              id: Math.random().toString(),
              name: name,
              extension: extension,
            };
            setAttachedFiles((prevFiles) => [...prevFiles, newFile]);
          };
        })(file);

        reader.readAsDataURL(file);
      } else {
        console.log("Invalid file name without extension");
      }
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...attachedFiles];
    updatedFiles.splice(index, 1);
    setAttachedFiles(updatedFiles);
  };

  const handleRenameFile = (index, newName) => {
    const updatedFiles = [...attachedFiles];
    updatedFiles[index].name = newName;
    setAttachedFiles(updatedFiles);
  };

  const handleSave = async () => {
    // console.log(modifiedOrderDetails);
    setloader(true);
    let response;
    try {
      const formData = new FormData();
      if (attachedFiles?.length > 0) {
        attachedFiles.forEach((image, index) => {
          const base64String = image.src.split(",")[1]; // Get the base64 string after the comma
          const blob = atob(base64String); // Decode the base64 string
          const arrayBuffer = new ArrayBuffer(blob.length);
          const uint8Array = new Uint8Array(arrayBuffer);

          for (let i = 0; i < blob.length; i++) {
            uint8Array[i] = blob.charCodeAt(i);
          }

          const fileBlob = new Blob([arrayBuffer], { type: "image/png" }); // Adjust the type accordingly

          formData.append(
            `attachments[${index}]`,
            fileBlob,
            `${image.name}${image.extension}`
          );
        });
      } else {
        formData.append(`attachments`, []);
      }
      if (StatusName === "ATTENTION") {
        const modifiedOrderDetails = orderDetails.map(
          ({ is_saleable, saleable_item_quantity, id, note }) => ({
            is_saleable,
            saleable_item_quantity,
            note,
            id,
          })
        );
        formData.append(`order_details`, JSON.stringify(modifiedOrderDetails));
      }
      if (StatusName === "FULFILLED") {
        const modifiedOrderDetails = orderDetails.map(
          ({ pallet_data, id }) => ({
            pallet_data,
            id,
          })
        );
        formData.append(`order_details`, JSON.stringify(modifiedOrderDetails));
      }

      if (data?.amount_received_by_warehouse) {
        formData.append(
          `amount_received_by_warehouse`,
          data?.amount_received_by_warehouse ?? ""
        );
      }
      formData.append(`note`, notes ?? "");
      formData.append(`order_id`, data?.id);
      formData.append(`status`, data?.status);
      formData.append(`fulfillment_order_status`, StatusName);
      response = await storeOrderChange(formData, data?.id, selectedStatus);
      if (!response.error) {
        setloader(false);
        setOrders((prevOrder) => {
          return {
            ...prevOrder,
            data: prevOrder?.data.map((order) =>
              order.id === data.id
                ? {
                    ...order,
                    order_notes: response?.data?.result[0]?.order_notes,
                    fullfilment_status: {
                      id: selectedStatus,
                      name: StatusName,
                    },
                    order_details: response?.data?.result[0]?.order_details,
                  }
                : order
            ),
          };
        });
        setData({
          ...data,
          order_notes: response?.data?.result[0]?.order_notes,
          fullfilment_status: {
            id: selectedStatus,
            name: StatusName,
          },
          order_details: response?.data?.result[0]?.order_details,
        });
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: response?.data?.message,
            severity: "success",
          };
        });
        handleClose();
      } else {
        setloader(false);
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Something went wrong try again later",
            severity: "error",
          };
        });
      }
    } catch (error) {
      setloader(false);
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: error.response.data.message,
          severity: "error",
        };
      });
    }
  };

  const handleStatusChange = async (e, index) => {
    const orderList = [...orderDetails];
    orderList[index]["is_saleable"] = e.target.checked;
    orderList[index]["saleable_item_quantity"] =
      e.target.checked === true ? orderList[index]["item_quantity"] : "0";
    setorderDetails(orderList);
  };

  const handleSaleableSelect = async (e, index) => {
    const orderList = [...orderDetails];
    orderList[index]["saleable_item_quantity"] = e.target.value;
    setorderDetails(orderList);
  };

  const handleAttentionNote = async (e, index) => {
    const orderList = [...orderDetails];
    orderList[index]["note"] = e.target.value;
    setorderDetails(orderList);
  };

  const handleAmount = async (value) => {
    setData({
      ...data,
      amount_received_by_warehouse: value,
    });
  };

  const handleLocationSelect = (selectedPalletData, index) => {
    setorderDetails((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = {
        ...updatedItems[index],
        pallet_data: selectedPalletData,
      };
      return updatedItems;
    });
  };

  const handlePalletSelect = (selectedPallet, index) => {
    const exists = orderDetails?.[index]?.pallet_data?.some(
      (data) => data?.pallet_id == selectedPallet
    );

    // Prepare updatedPalletData
    let updatedPalletData;

    if (exists) {
      // Remove the object that has the matching pallet_id
      updatedPalletData = orderDetails?.[index]?.pallet_data?.filter(
        (data) => data?.pallet_id != selectedPallet
      );
    } else {
      // Add a new object if the selectedPallet doesn't exist
      updatedPalletData = [
        ...(orderDetails?.[index]?.pallet_data ?? []),
        { pallet_id: parseInt(selectedPallet), quantity: 0 },
      ];
    }

    // Update the state
    setorderDetails((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index] = {
        ...updatedItems[index],
        pallet_data: updatedPalletData,
      };
      return updatedItems;
    });
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className="inner__detail_fulfilment_modal"
      >
        <Box
          sx={{
            ...style,
            width: "75%",
            maxWidth: "1600px",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <h2 id="child-modal-title" className="sale__status_title">
            Sale Status Update
          </h2>
          {(StatusName === "FULFILLED" ||
          StatusName === "CANCELLED" ||
          StatusName === "ATTENTION"
            ? true
            : false) && (
            <>
              <TextField
                label="Note"
                type="text"
                name="Note"
                value={notes}
                className="sale__status_notes"
                onChange={(e) => setnotes(e.target.value)}
                placeholder={"Note for Sale " + StatusName}
                sx={{
                  width: "100%",
                  input: { font: "menu" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                multiline={true}
                rows={2}
              />
              {(data?.payment_method_title === "Cash On Delivery" ||
                data?.payment_method_title === "Cash On Pickup") &&
                StatusName === "FULFILLED" && (
                  <div style={{ marginBottom: "5px" }}>
                    <TextField
                      label="Pending Amount"
                      type="number"
                      name="amount_pending"
                      value={data?.amount_pending ?? ""}
                      className="sale__status_notes"
                      placeholder={"Amount To Be Received"}
                      sx={{
                        width: "50%",
                        input: { font: "menu" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      disabled
                    />
                    <TextField
                      label="Received Amount"
                      type="number"
                      name="amount_received_by_warehouse"
                      value={data?.amount_received_by_warehouse ?? ""}
                      className="sale__status_notes"
                      onChange={(e) => handleAmount(e.target.value)}
                      placeholder={"Enter Received Amount"}
                      sx={{
                        width: "50%",
                        input: { font: "menu" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      error={
                        data?.amount_pending !=
                        data?.amount_received_by_warehouse
                          ? true
                          : false
                      }
                    />
                  </div>
                )}
              <div className="sale_status_update_attachment_wrap">
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-input"
                />
                <label htmlFor="file-input">
                  <Button variant="contained" component="span" color="primary">
                    Attach File
                  </Button>
                </label>
                <div className="salte_attachment_list_main_wrap">
                  {attachedFiles?.map((attachedFile, index) => (
                    <div
                      key={index}
                      className="sale_status_update_attachment_detail_box"
                    >
                      <TextField
                        variant="outlined"
                        className="attachment__field"
                        size="small"
                        value={attachedFile.name}
                        onChange={(e) =>
                          handleRenameFile(index, e.target.value)
                        }
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{ border: "none", padding: "0" }}
                      />
                      <IconButton
                        onClick={() => handleRemoveFile(index)}
                        color="error"
                        className="icon_btn_remove_attachment"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {(StatusName === "FULFILLED" || StatusName === "ATTENTION"
            ? true
            : false) && (
            <div className="invmodal__shipment_details sales_update_inner_modal">
              <div
                className="invmodal__shipment_summaryhding"
                style={{ marginTop: "5px" }}
              >
                Product Details
              </div>
              <div className="invmodal__shdetails_content sales_update_inner_modal_table">
                <div className="invmodal__shdetails_cntleft">
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full border border-gray-300">
                      <thead className="bg-[#dcdddf]">
                        <tr>
                          <th className="p-1 text-center text-sm border border-gray-300"></th>
                          <th className="p-1 text-center text-sm border border-gray-300">
                            #
                          </th>
                          <th className="p-1 text-left text-sm border border-gray-300">
                            Name
                          </th>
                          <th className="p-1 text-center text-sm border border-gray-300">
                            Sku
                          </th>
                          <th className="p-1 text-center text-sm border border-gray-300">
                            Qty
                          </th>
                          <th className="p-1 text-left text-sm border border-gray-300">
                            Lot/Batch
                          </th>
                          {StatusName === "ATTENTION" && (
                            <>
                              <th className="p-1 text-left text-sm border border-gray-300">
                                Saleable
                              </th>
                              <th className="p-1 text-center text-sm border border-gray-300">
                                Saleable Qty
                              </th>
                              <th className="p-1 text-left text-sm border border-gray-300">
                                Note
                              </th>
                            </>
                          )}
                          {StatusName === "FULFILLED" && (
                            <th className="p-1 text-left text-sm border border-gray-300">
                              Selected Pallets
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetails?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr className="odd:bg-white even:bg-gray-50">
                              <td className="p-1 text-center text-sm border border-gray-300">
                                {StatusName === "FULFILLED" && (
                                  <IconButton
                                    size="small"
                                    onClick={() => toggleRow(index)}
                                    aria-expanded={expandedRows[index] || false}
                                    aria-label="expand row"
                                  >
                                    {expandedRows[index] ? (
                                      <KeyboardArrowUp />
                                    ) : (
                                      <KeyboardArrowDown />
                                    )}
                                  </IconButton>
                                )}
                              </td>
                              <td className="p-1 text-center text-sm border border-gray-300">
                                <img
                                  src={
                                    item?.products?.media?.[0]?.file || noImage
                                  }
                                  className="w-9 h-9 object-cover mx-auto"
                                  alt="Product"
                                  onError={(e) => {
                                    e.target.src = noImage;
                                  }}
                                />
                              </td>
                              <td className="p-1 text-left text-sm border border-gray-300">
                                {item?.products?.name ?? "-"}
                              </td>
                              <td className="p-1 text-center text-sm border border-gray-300">
                                {item?.products?.code ?? "-"}
                              </td>
                              <td className="p-1 text-center text-sm border border-gray-300">
                                {item?.item_quantity ?? "-"}
                              </td>
                              <td className="p-1 text-center text-sm border border-gray-300">
                                {item?.order_item_product_lot_quantities?.map(
                                  (product_lot, index) => (
                                    <React.Fragment key={index}>
                                      {product_lot?.product_lot?.name} (
                                      {product_lot?.quantity})
                                      <br />
                                    </React.Fragment>
                                  )
                                )}
                                {/* {item?.product_lot?.name ?? "-"} */}
                              </td>
                              {StatusName === "FULFILLED" && (
                                <td className="p-1 text-left text-sm border border-gray-300 max-w-[250px]">
                                  {item?.pallet_data?.length > 0 ? (
                                    item?.pallet_data.map((pallet, idx) => {
                                      const palletDetails =
                                        item?.products?.product_pallets?.find(
                                          (product) =>
                                            product.pallet.id ===
                                            pallet.pallet_id
                                        );
                                      return (
                                        <div
                                          key={idx}
                                          className="flex gap-2 text-sm"
                                        >
                                          <span className="font-medium">
                                            {palletDetails?.pallet?.name ??
                                              "N/A"}
                                          </span>
                                          <span className="text-gray-500">
                                            {palletDetails?.pallet?.location
                                              ?.name
                                              ? `(${palletDetails.pallet.location.name})`
                                              : ""}
                                          </span>
                                          <span className="min-w-[40px]">
                                            Qty: {pallet.quantity}
                                          </span>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <span className="text-gray-400">
                                      No pallets selected
                                    </span>
                                  )}
                                </td>
                              )}
                              {StatusName === "ATTENTION" && (
                                <>
                                  <td className="p-1 text-left text-sm border border-gray-300">
                                    <Switch
                                      checked={item?.is_saleable || false}
                                      onChange={(e) =>
                                        handleStatusChange(e, index)
                                      }
                                      color="primary"
                                    />
                                  </td>
                                  <td className="p-1 text-center text-sm border border-gray-300">
                                    <Select
                                      value={
                                        item?.saleable_item_quantity || "0"
                                      }
                                      onChange={(e) =>
                                        handleSaleableSelect(e, index)
                                      }
                                      className="w-full"
                                      size="small"
                                    >
                                      {(item?.item_quantity
                                        ? Array.from(
                                            {
                                              length: item.item_quantity + 1,
                                            },
                                            (_, index) =>
                                              item.item_quantity - index
                                          ).filter((quantity) => quantity >= 0)
                                        : []
                                      ).map((quantity, index) => (
                                        <MenuItem key={index} value={quantity}>
                                          {quantity}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </td>
                                  <td className="p-1 text-left text-sm border border-gray-300">
                                    <TextField
                                      placeholder="Note"
                                      value={item?.note}
                                      onChange={(e) =>
                                        handleAttentionNote(e, index)
                                      }
                                      multiline
                                      size="small"
                                      fullWidth
                                    />
                                  </td>
                                </>
                              )}
                            </tr>

                            {StatusName === "FULFILLED" && (
                              <tr>
                                <td colSpan="100%" className="p-0 border-t">
                                  <Collapse
                                    in={expandedRows[index]}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <div className="p-3 bg-gray-50">
                                      <h4 className="font-medium text-sm">
                                        Product Pallets
                                      </h4>
                                      <div className="flex justify-between py-1 font-semibold border-b text-sm">
                                        <span className="flex-1 text-start">
                                          Selection
                                        </span>
                                        <span className="flex-1 text-start">
                                          Code
                                        </span>
                                        <span className="flex-1 text-start">
                                          Pallet Name
                                        </span>
                                        <span className="flex-1 text-start">
                                          Lot/Batch
                                        </span>
                                        <span className="flex-1 text-start">
                                          Location
                                        </span>
                                        <span className="flex-1 text-start">
                                          Qty
                                        </span>
                                      </div>
                                      {item?.products?.product_pallets?.map(
                                        (pallet, idx) => (
                                          <div
                                            key={idx}
                                            className="flex justify-between py-1 border-b text-sm"
                                          >
                                            <span className="flex-1 text-start">
                                              <Checkbox
                                                checked={
                                                  item?.pallet_data?.some(
                                                    (data) =>
                                                      data?.pallet_id ==
                                                      pallet?.pallet_id
                                                  ) || false
                                                }
                                                onChange={() => {
                                                  handlePalletSelect(
                                                    pallet?.pallet_id,
                                                    index
                                                  );
                                                }}
                                                inputProps={{
                                                  "aria-label": "controlled",
                                                }}
                                              />
                                            </span>
                                            <span className="flex-1 text-start">
                                              {pallet?.pallet?.code ?? "N/A"}
                                            </span>
                                            <span className="flex-1 text-start">
                                              {pallet?.pallet?.name ?? "N/A"}
                                            </span>
                                            <span className="flex-1 text-start">
                                              {pallet?.product_lot?.name ??
                                                "N/A"}
                                            </span>
                                            <span className="flex-1 text-start">
                                              {pallet?.pallet?.location?.name ??
                                                "N/A"}
                                            </span>
                                            <span className="flex-1 text-start">
                                              {pallet?.quantity ?? "N/A"}
                                            </span>
                                          </div>
                                        )
                                      )}

                                      <h4 className="mt-4 text-sm font-bold">
                                        Selected Pallets
                                      </h4>
                                      <div className="flex justify-between py-1 font-semibold border-b text-sm">
                                        <span className="flex-1 text-start">
                                          Code
                                        </span>
                                        <span className="flex-1 text-start">
                                          Pallet Name
                                        </span>
                                        <span className="flex-1 text-center">
                                          Lot/Batch
                                        </span>
                                        <span className="flex-1 text-center">
                                          Location
                                        </span>
                                        <span className="flex-1 text-center">
                                          Qty
                                        </span>
                                      </div>
                                      {item?.pallet_data?.map((data, idx) => {
                                        // Find the product pallet for this data
                                        const productPallet =
                                          item?.products?.product_pallets?.find(
                                            (product) =>
                                              product.pallet.id ===
                                              data.pallet_id
                                          );

                                        // Default to "N/A" if productPallet is not found
                                        const palletCode =
                                          productPallet?.pallet?.code || "N/A";
                                        const palletName =
                                          productPallet?.pallet?.name || "N/A";
                                        const productLotName =
                                          productPallet?.product_lot?.name ||
                                          "N/A";
                                        const palletLocationName =
                                          productPallet?.pallet?.location
                                            ?.name || "N/A";

                                        return (
                                          <div
                                            key={idx}
                                            className="flex justify-between py-1 border-b text-sm"
                                          >
                                            <span className="flex-1 text-start">
                                              {palletCode}
                                            </span>
                                            <span className="flex-1 text-start">
                                              {palletName}
                                            </span>
                                            <span className="flex-1 text-center">
                                              {productLotName}
                                            </span>
                                            <span className="flex-1 text-center">
                                              {palletLocationName}
                                            </span>
                                            <span className="flex-1 text-center">
                                              <TextField
                                                type="number"
                                                value={data.quantity}
                                                onChange={(e) => {
                                                  const updatedPalletData = [
                                                    ...item.pallet_data,
                                                  ];
                                                  updatedPalletData[
                                                    idx
                                                  ].quantity = e.target.value;
                                                  handleLocationSelect(
                                                    updatedPalletData,
                                                    index
                                                  );
                                                }}
                                                size="small"
                                                error={
                                                  parseInt(data.quantity) >
                                                  parseInt(
                                                    productPallet?.quantity
                                                  )
                                                }
                                              />
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </Collapse>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          <p id="child-modal-description">
            {" "}
            Do you want to Change the Order status from{" "}
            <strong>
              {data?.fullfilment_status?.name?.toUpperCase() ?? "-"}
            </strong>{" "}
            to <strong>{StatusName}.</strong>
            {StatusName === "CANCELLED" && (
              <>
                <br />
                <strong style={{ color: "red" }}>
                  Note: Canceling is an irreversible action!
                </strong>
              </>
            )}
            {data?.shipment?.shipment_status === "Cancelled" && (
              <>
                <br />
                <strong style={{ color: "red" }}>
                  Note: Shipment for this Order is Cancelled!
                </strong>
              </>
            )}
            {(data?.payment_method_title === "Cash On Delivery" ||
              data?.payment_method_title === "Cash On Pickup") &&
              StatusName === "FULFILLED" && (
                <>
                  <br />
                  <strong style={{ color: "red" }}>
                    Note: Receive the Pending Amount!
                  </strong>
                </>
              )}
          </p>
          <div
            style={{ display: "flex", justifyContent: "end" }}
            className="inner__detail_fulfilment_btn"
          >
            <Button onClick={handleSave} variant="contained" size="small">
              {loader ? <CircularLoader /> : "Confirm"}
            </Button>
            <Button
              onClick={handleCancel}
              variant="contained"
              color="error"
              size="small"
              style={{ marginLeft: "5px" }}
            >
              Decline
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
