import React, { useRef, useState, useEffect } from "react";
import "./Products.css";

// components imports
import ProductTable from "../../../Components/BoltTable/BoltTable";
import ImagesModal from "../Components/ImagesModal/ImagesModal";
import ProductQuantityModal from "../Components/ProductQuantityModal/ProductQuantityModal";
import ProductDimensionModal from "../Components/ProductDimensionModal/ProductDimensionModal";
import ProductIdentifierModal from "../Components/ProductIdentifierModal/ProductIdentifierModal";
import ProductDetailsModal from "../Components/ProductDetailsModal/ProductDetailsModal";
import DescriptionModal from "../Components/DescriptionModal/DescriptionModal";
import ImportModal from "../Components/ImportModal/Import";
import ProductHistoryModal from "../Components/HistoryModal/HistoryModal";
import PackagesDimensionsModal from "../Components/PackagesDimensions/PackagesDimensionsModal";
import ProductWeightModal from "../Components/ProductWeightModal/ProductWeightModal";
import Tooltip from "../../../Components/Tooltip/Tooltip";
import Confirm from "../../../Components/Popups/Confirm";

// apis imports
import {
  fetchAllProducts,
  deleteProduct,
} from "../../../services/product.service";
import { generateExcelFile } from "./productExport";
// library imports

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsWordpress } from "react-icons/bs";
import { FaEbay } from "react-icons/fa";
import { FaShopify } from "react-icons/fa";
import {
  AiOutlineAmazon,
  AiOutlineImport,
  AiOutlineBarcode,
} from "react-icons/ai";
import { MdOutlineEditCalendar } from "react-icons/md";
import { FcPlus } from "react-icons/fc";
import { MdEditCalendar, MdHistory, MdOutlineCancel } from "react-icons/md";
import { TbBrandWalmart } from "react-icons/tb";
import { PiExportLight, PiPackageLight } from "react-icons/pi";
import noImage from "../../../assets/images/noImage.png";
import LabelModal from "../Components/LabelModal/LabelModal";
import { Dimensions, Weight } from "./ProductService";

const marketplaceIcons = {
  bestbuy: <BsWordpress size={20} />,
  eBay: <FaEbay size={20} />,
  Woocommerce: <BsWordpress size={20} />,
  shopify: <FaShopify size={20} />,
  amazon: <AiOutlineAmazon size={20} />,
  walmart: <TbBrandWalmart size={20} />,
};

const ShipperProduct = ({ snack, setSnack }) => {
  const [Products, setProducts] = useState([]);
  const [Brands, setBrands] = useState([]);
  const [Suppliers, setSuppliers] = useState([]);
  const [MarketPlaces, setMarketPlaces] = useState([]);
  const [Import, setImport] = useState(false);
  const [showVariants, setShowVariants] = useState(false);
  const [imagesModal, setImagesModal] = useState();
  const [descriptionModal, setDescriptionModal] = useState();
  const [prodDetailsModal, setProdDetailsModal] = useState(false);
  const [prodHistoryModal, setProdHistoryModal] = useState(false);
  const [labelModal, setLabelModal] = useState(false);
  const [prodIdentifierModal, setProdIdentifierModal] = useState(false);
  const [prodDimensionModal, setProdDimensionModal] = useState(false);
  const [packagesDimensionModal, setPackagesDimensionModal] = useState(false);
  const [productWeightModal, setProductWeightModal] = useState(false);
  const [prodQuantityModal, setProdQuantityModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteLoader, setdeleteLoader] = useState(false);

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const ImageUrl = (row) => {
    const url =
      row?.media.find((media) => media.is_primary === "true")?.file ||
      row?.media[0]?.file;
    return url || noImage;
  };

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id ?? "-"}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Product ID",
      render: (row) => <>{row.code ?? "-"}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Image",
      render: (row, rowIndex, handleFieldChange) => (
        <img
          src={ImageUrl(row)}
          onClick={() => setImagesModal(row?.id)}
          onError={(e) => {
            e.target.src = noImage;
          }}
          loading="lazy"
        />
      ),
      fixed: "left",
    },
    {
      id: 3,
      label: "Name",
      render: (row, rowIndex, handleFieldChange) => <>{row.name ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Sku",
      // render: (row, rowIndex, handleFieldChange) => (
      //   <div
      //     className="product_identifier_parent_wrapper"
      //     onClick={() =>
      //       setProdIdentifierModal({ row, rowIndex, handleFieldChange })
      //     }
      //   >
      //     <div className="product_identifier_wrapper">
      //       {row?.product_skus.map((sku, index) => {
      //         return (
      //           <span onClick={(e) => e.stopPropagation()}>
      //             {" "}
      //             <a
      //               href={sku?.slug}
      //               target="_blank"
      //               className="marketplace_icon"
      //               key={index}
      //             >
      //               <span>{marketplaceIcons[sku?.market_place?.market]}</span>
      //             </a>
      //           </span>
      //         );
      //       })}
      //     </div>
      //   </div>
      // ),
      // render: (row, rowIndex, handleFieldChange) => (
      //   <>
      //     {row?.product_skus?.length > 0 ? (
      //       <>
      //         <div className="table_action__wrap">
      //           <Tooltip title="View Marketplaces Sku's">
      //             <div
      //               className="table_action__btn"
      //               onClick={() =>
      //                 setProdIdentifierModal({
      //                   row,
      //                   rowIndex,
      //                   handleFieldChange,
      //                 })
      //               }
      //             >
      //               <MdEditCalendar size={10} />
      //               Marketplaces
      //             </div>
      //           </Tooltip>
      //         </div>
      //       </>
      //     ) : null}
      //   </>
      // ),
      render: (row) => (
        <>
          {row?.children?.length == 0 ? <>{row?.product_sku ?? "-"}</> : null}
        </>
      ),
      width: "200px",
    },
    {
      id: 6,
      label: "Sale Price",
      render: (row) => (
        <>
          {row?.children?.length == 0 ? <>{row.sale_price ?? "-"}</> : <>-</>}
        </>
      ),
      width: "200px",
    },
    {
      id: 7,
      label: "Total Value",
      render: (row) => (
        <>
          {(
            row?.product_warehouse?.reduce(
              (total, warehouse) =>
                total + (parseInt(warehouse?.quantity, 10) || 0),
              0
            ) * (parseFloat(row?.sale_price) || 0)
          ).toFixed(1)}
        </>
      ),
      width: "200px",
    },
    {
      id: 8,
      label: "Product Brand",
      render: (row) => <>{row?.brand?.name || "-"}</>,
      width: "200px",
    },
    {
      id: 9,
      label: "Dimensions (L*W*H)",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          {row?.children?.length == 0 ? (
            <>
              <div
                onClick={() =>
                  setProdDimensionModal({ row, rowIndex, handleFieldChange })
                }
                style={{ cursor: "pointer" }}
              >
                <Dimensions
                  length={row?.length ?? 0}
                  width={row?.width ?? 0}
                  height={row?.height ?? 0}
                  unit={
                    row?.dimension_unit?.toLowerCase() === "in" ? "inch" : "cm"
                  }
                  space={true}
                />
              </div>
            </>
          ) : null}
        </>
      ),
      width: "200px",
    },
    {
      id: 10,
      label: "Packages",
      render: (row) => (
        <>
          {row?.children?.length == 0 ? (
            <>
              <div
                className="product_dimension_parent_wrapper"
                style={{ maxWidth: "200px" }}
              >
                <div
                  className="product__notification_circle_wrapper"
                  onClick={() => setPackagesDimensionModal(row)}
                >
                  <PiPackageLight size={10} />
                  {row?.package_details &&
                    Object?.values(row?.package_details)?.length > 0 && (
                      <div className="product__notification_circle">
                        {Object.values(row?.package_details)?.length}
                      </div>
                    )}
                </div>
              </div>
            </>
          ) : null}
        </>
      ),
      width: "200px",
    },
    {
      id: 11,
      label: "Weight",
      render: (row) => (
        <>
          {row?.children?.length == 0 ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setProductWeightModal(row)}
            >
              <Weight
                weight={row?.weight ?? 0}
                unit={row?.weight_unit?.toLowerCase() === "lb" ? "lb" : "kg"}
                space={true}
              />
            </div>
          ) : null}
        </>
      ),
      width: "200px",
    },
    {
      id: 12,
      label: "Quantity",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          {row?.children?.length == 0 ? (
            <div
              className="product_quantity_parent_wrapper"
              onClick={() =>
                setProdQuantityModal({ row, rowIndex, handleFieldChange })
              }
            >
              <div className="product__textalign_center">
                {row?.product_warehouse?.reduce(
                  (total, warehouse) =>
                    total + parseInt(warehouse.quantity, 10) || 0,
                  0
                )}
              </div>
            </div>
          ) : null}
        </>
      ),
      width: "200px",
    },
    {
      id: 13,
      label: "Action",
      field_name: "action",
      render: (row, rowIndex, handleFieldChange) => (
        <>
          <div className="table_action__wrap">
            <Tooltip title="View Product Details">
              <div
                className="table_action__btn"
                onClick={() =>
                  setProdDetailsModal({ row, rowIndex, handleFieldChange })
                }
              >
                <MdEditCalendar size={10} />
                VIEW
              </div>
            </Tooltip>
            <Tooltip title="Edit Product">
              <div
                className="table_action__btn"
                onClick={() => navigate("/edit-product", { state: row })}
              >
                <MdOutlineEditCalendar size={10} />
                Edit
              </div>
            </Tooltip>
            <Tooltip title="View Product History">
              <div
                className="table_action__btn"
                onClick={() =>
                  setProdHistoryModal({ row, rowIndex, handleFieldChange })
                }
              >
                <MdHistory size={10} />
                History
              </div>
            </Tooltip>
            <Tooltip title="Download Product Barcodes">
              <div
                className="table_action__btn"
                onClick={() =>
                  setLabelModal({ row, rowIndex, handleFieldChange })
                }
              >
                <AiOutlineBarcode size={10} />
                Barcode
              </div>
            </Tooltip>
            <Tooltip title="Delete Product">
              <div
                className="table_action__btn"
                onClick={() => setConfirmDelete(row)}
              >
                <MdOutlineCancel size={10} />
                Delete
              </div>
            </Tooltip>
          </div>
        </>
      ),
      width: "200px",
      fixed: "right",
    },
  ];

  const getAllProducts = async (page, perPage, filter) => {
    const response = await fetchAllProducts(page, perPage, filter);
    if (!response.error) {
      setProducts(response?.data?.result?.products);
      setBrands(response?.data?.result?.brands);
      setSuppliers(response?.data?.result?.user_suppliers);
      setMarketPlaces(response?.data?.result?.user_market_places);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  const handleDeleteRequest = async (data) => {
    if (data.id) {
      setdeleteLoader(true);
      const response = await deleteProduct(data.id);

      if (!response.error && response?.data?.success) {
        setProducts((prevProducts) => {
          return {
            ...prevProducts,
            data: prevProducts?.data?.filter((d) => d?.id != data?.id),
          };
        });
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Product deleted successfully",
            severity: "success",
          };
        });
        setdeleteLoader(false);
        setConfirmDelete(false);
      } else {
        setSnack((snack) => {
          return {
            ...snack,
            open: true,
            text: "Couldn't cancel this request!",
            severity: "error",
          };
        });
        setdeleteLoader(false);
      }
    }
  };

  const warehouses = user?.user_approved_warehouses;
  const insertIndex = columns.length - 1;
  warehouses?.forEach((warehouse, index) => {
    columns.splice(insertIndex, 0, {
      id: 1000 + index,
      label: warehouse?.warehouse?.name,
      render: (row, rowIndex, handleFieldChange) => {
        const matchedWarehouse = row?.product_warehouse?.find(
          (item) => item?.warehouse_id == warehouse?.warehouse_id
        );

        return (
          <>
            {matchedWarehouse && row?.children?.length == 0 ? (
              <>
                {
                  <div
                    className="product_quantity_parent_wrapper"
                    onClick={() => {
                      setProdQuantityModal({
                        row: {
                          ...row,
                          product_warehouse: row.product_warehouse.filter(
                            (w) => w.warehouse_id === warehouse.warehouse_id
                          ),
                        },
                        rowIndex,
                        handleFieldChange,
                      });
                    }}
                  >
                    <div className="product__textalign_center">
                      {matchedWarehouse?.quantity}
                    </div>
                  </div>
                }
              </>
            ) : null}
          </>
        );
      },
      width: "250px",
    });
  });

  return (
    <>
      <ProductQuantityModal
        open={prodQuantityModal}
        setOpen={setProdQuantityModal}
        setProdHistoryModal={setProdHistoryModal}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <LabelModal
        open={labelModal}
        setOpen={setLabelModal}
        setSnack={setSnack}
        labelData={{
          code: labelModal?.row?.code,
          name: labelModal?.row?.name,
          image: labelModal?.row?.media?.find((image) => image?.is_primary)
            ?.file,
        }}
        title="Product's Barcode"
        subtitle={`${labelModal?.row?.full_name ?? ""} (${
          labelModal?.row?.code ?? ""
        })`}
      />
      <ProductDimensionModal
        open={prodDimensionModal}
        setOpen={setProdDimensionModal}
        Products={Products}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <PackagesDimensionsModal
        open={packagesDimensionModal}
        setOpen={setPackagesDimensionModal}
        Products={Products}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <ProductWeightModal
        open={productWeightModal}
        setOpen={setProductWeightModal}
        Products={Products}
        setProducts={setProducts}
        setSnack={setSnack}
      />
      <ImportModal
        open={Import}
        setOpen={setImport}
        setSnack={setSnack}
        getAllProducts={getAllProducts}
      />
      <ProductIdentifierModal
        open={prodIdentifierModal}
        setOpen={setProdIdentifierModal}
        setSnack={setSnack}
        setProducts={setProducts}
      />
      <ProductDetailsModal
        open={prodDetailsModal}
        setOpen={setProdDetailsModal}
        setSnack={setSnack}
      />
      <ProductHistoryModal
        open={prodHistoryModal}
        setOpen={setProdHistoryModal}
      />
      <DescriptionModal
        open={descriptionModal}
        setOpen={setDescriptionModal}
        setSnack={setSnack}
      />
      <ImagesModal
        open={imagesModal}
        setOpen={setImagesModal}
        setSnack={setSnack}
        setProducts={setProducts}
      />
      <Confirm
        open={confirmDelete}
        setOpen={setConfirmDelete}
        handleConfirm={() => handleDeleteRequest(confirmDelete)}
        text="Do You Want To Delete This Product?"
        loader={deleteLoader}
      />
      <div className="products__page_header">
        <div className="products__page_headerlft">
          <span className="products__page_hdrheading">Products List</span>
        </div>

        <div className="products__page_headerrt_wrap">
          <div
            className="products__page_headerrt products__page_hdraddprod"
            onClick={() => setImport(true)}
          >
            <AiOutlineImport size={10} />
            <span>Import Data</span>
          </div>
          <div
            className="products__page_headerrt products__page_hdraddprod"
            onClick={() =>
              generateExcelFile(
                Products?.data,
                selectedRows,
                showVariants,
                setSnack,
                Suppliers,
                user.user_approved_warehouses,
                MarketPlaces
              )
            }
          >
            <PiExportLight size={10} />
            <span>Export Selected</span>
          </div>

          {Products?.data?.length > 0 && (
            <div className="products__page_headerrt products__page_hdraddprod">
              <input
                type="checkbox"
                id="show_variant_toggle"
                onClick={() => setShowVariants(!showVariants)}
                checked={showVariants}
              />
              <span>Show Variants</span>
            </div>
          )}
          <div className="products__page_headerrt">
            <Link
              to={
                user.role == "Shipper"
                  ? "/AddProduct"
                  : "/warehouse-AddNewProduct"
              }
              className="products__page_hdraddprod"
            >
              <FcPlus size={10} />
              <span>ADD PRODUCT</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="custom__table_size1 actionbtn__needtobe_fixwidth">
        <ProductTable
          message={"No Products Available To Display!"}
          searchPlaceHolder="Search Products"
          columns={columns}
          data={Products}
          showVariants={showVariants}
          getAllData={getAllProducts}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          selectable={true}
        />
      </div>
    </>
  );
};

export default ShipperProduct;
