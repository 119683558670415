import {
  MdDashboard,
  MdShoppingCart,
  MdManageAccounts,
  MdStore,
} from "react-icons/md";
import { BiPackage } from "react-icons/bi";
import { FaDigitalTachograph } from "react-icons/fa";
import WarehouseProfileModal from "./Components/ProfileModal/WarehouseProfileModal";
import logo from "../logo.png";
import defaultAvatar from "../../assets/images/profile-avatar.png";
import { useSelector } from "react-redux";
import CustomNavbar from "./Navbar";

const navItems = [
  {
    url: "/warehouse-dashboard",
    label: "Dashboard",
    icon: <MdDashboard size={20} className="m-auto" />,
  },
  {
    url: "/warehouse-orders",
    label: "Orders",
    icon: <MdShoppingCart size={20} className="m-auto" />,
  },
  {
    url: "/warehouse-clients",
    label: "Clients",
    icon: <MdManageAccounts size={20} className="m-auto" />,
  },
  {
    url: "/warehouse-products",
    label: "Products",
    icon: <BiPackage size={20} className="m-auto" />,
  },
  {
    url: "/warehouse-payments",
    label: "Finances",
    icon: <FaDigitalTachograph size={20} className="m-auto" />,
  },
  {
    url: "/storage-locations",
    label: "Storage",
    icon: <MdStore size={20} className="m-auto" />,
  },
];

const WarehouseNavBar = () => {
  const user = useSelector((state) => state.user);

  return (
    <CustomNavbar
      logo={logo}
      navItems={navItems}
      ProfileImage={user.profile_image}
      dateBadge={true}
      profileModal={({ onClose }) => (
        <WarehouseProfileModal
          onClose={onClose}
          style={{ top: "50px", right: "0" }}
        />
      )}
      defaultAvatar={defaultAvatar}
      UserProfileModal={WarehouseProfileModal}
      toastMessage="Successfully Logged Out!!"
    />
  );
};

export default WarehouseNavBar;
