import React, { useState, useEffect } from "react";
import "./grndetails.css";

//componenets
// apis imports
// library imports
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { BsTrash } from "react-icons/bs";
import Tooltips from "@mui/material/Tooltip";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import TextField from "@mui/material/TextField";
import noImage from "../../../../assets/images/noImage.png";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { AiOutlineBarcode } from "react-icons/ai";
import LabelModal from "../../../Products/Components/LabelModal/LabelModal";

function LinearProgressWithLabel(props) {
  return (
    <div>
      <LinearProgress
        variant="determinate"
        {...props}
        value={(props.received_quantity / props.total) * 100}
        style={{ width: "100px", margin: "0 auto" }}
        color={props.total == props.received_quantity ? "success" : "primary"}
      />
      {props?.received_quantity} / {props?.total}
    </div>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function ProductList(props) {
  const {
    SelectedProducts,
    setSelectedProducts,
    deleteProduct,
    additionalCost,
    subTotal,
    handelChange,
    formData,
    modeType,
    setfullview,
    highlightedRow,
    setHighlightedRow,
    setSnack,
  } = props;
  const user = useSelector((state) => state.user);
  const ImageUrl = (row) => {
    const url = row?.media[0]?.file;
    return url || noImage;
  };

  const [labelModal, setLabelModal] = useState(false);

  const handlescrollpackage = (data) => {
    const productId = data.product_id;

    const existingProductIndex = SelectedProducts?.findIndex(
      (product) => product.product_id === productId
    );

    if (existingProductIndex !== -1) {
      const updatedProducts = [...SelectedProducts];
      updatedProducts[existingProductIndex].quantity =
        Number(updatedProducts[existingProductIndex].quantity) + 1;
      setSelectedProducts(updatedProducts);
    } else {
      const newData = { ...data, quantity: 1 };
      setSelectedProducts([...SelectedProducts, newData]);
    }
  };

  const handleAdd = (index) => {
    handlescrollpackage(SelectedProducts[index]);
  };

  const handleSubtract = (index) => {
    const updatedProducts = [...SelectedProducts];
    if (updatedProducts[index].quantity > 1) {
      updatedProducts[index].quantity -= 1;
    } else {
      updatedProducts.splice(index, 1);
    }
    setSelectedProducts(updatedProducts);
  };

  const handleQuantityChange = (type, value, index) => {
    const updatedProducts = [...SelectedProducts];
    updatedProducts[index][type] = value;
    setSelectedProducts(updatedProducts);
  };

  const handelAcceptQuantity = (item, index) => {
    if (item?.received_quantity === item?.quantity) {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IoIosCheckmarkCircle
            style={{ color: "#24A149", marginRight: "1px" }}
            size={13}
          />
        </div>
      );
    } else {
      return (
        <Select
          value={item?.new_received_quantity}
          onChange={(e) => {
            handleQuantityChange(
              "new_received_quantity",
              e.target.value,
              index
            );
          }}
          style={{
            minWidth: "60px",
            maxHeight: "25px",
            fontSize: "12px",
          }}
        >
          {(item?.quantity
            ? Array.from(
                { length: item.quantity - item.received_quantity + 1 },
                (_, index) => item.quantity - item.received_quantity - index
              ).filter((quantity) => quantity >= 0)
            : []
          ).map((quantity, index) => (
            <MenuItem key={index} value={quantity}>
              {quantity}
            </MenuItem>
          ))}
        </Select>
      );
    }
  };

  useEffect(() => {
    if (highlightedRow) {
      const isMatch = SelectedProducts.some(
        (product) => product.code === highlightedRow
      );
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: isMatch
            ? "Product Matched Successfully"
            : "Product Is Not Available In The List",
          severity: "info",
        };
      });
      if (!isMatch) {
        setHighlightedRow(null);
      }
    }
  }, [highlightedRow]);

  return (
    <>
      <div
        className={
          user.role !== "warehouseUser"
            ? "card bg-white shadow stockreciveform__table_wrapper shipper__grn_wrap_has_shipper"
            : "card bg-white shadow stockreciveform__table_wrapper"
        }
        style={{ borderRadius: "15px", padding: "15px", marginTop: "10px" }}
      >
        <table className="stockreciveform__table stockreciveform__detail_table">
          <thead className="stockreciveform__grndetails_body_header">
            <th className="stockreciveform__detail_table_tablehd">#</th>
            <th className="stockreciveform__detail_table_tablehd">Name</th>
            <th className="stockreciveform__detail_table_tablehd">Sku</th>
            <th className="stockreciveform__detail_table_tablehd">
              Dim (L*W*H)
            </th>
            <th className="stockreciveform__detail_table_tablehd">Weight</th>
            <th className="stockreciveform__detail_table_tablehd">
              Total Weight
            </th>
            {user.role != "warehouseUser" && (
              <>
                <th className="stockreciveform__detail_table_tablehd">
                  Sale Price
                </th>
                <th className="stockreciveform__detail_table_tablehd">
                  Total Value
                </th>
              </>
            )}
            <th className="stockreciveform__detail_table_tablehd">Quantity</th>
            {user.role === "warehouseUser" && modeType !== "view" && (
              <>
                <th className="stockreciveform__detail_table_tablehd">
                  Accept
                </th>
                <th className="stockreciveform__detail_table_tablehd">
                  Reject
                </th>
              </>
            )}
            <th className="stockreciveform__detail_table_tablehd">Status</th>

            {/* <th className="stockreciveform__detail_table_tablehd">Unit Price</th>
            <th className="stockreciveform__detail_table_tablehd">Total</th> */}

            {modeType !== "add" && (
              <th className="stockreciveform__detail_table_tablehd">
                Warehouse Note
              </th>
            )}
            <th className="stockreciveform__detail_table_tablehd">
              Shipper Note
            </th>
            {modeType === "view" && (
              <th className="stockreciveform__detail_table_tablehd">Barcode</th>
            )}
            {modeType != "view" && user.role != "warehouseUser" && (
              <th className="stockreciveform__detail_table_tablehd">Action</th>
            )}
          </thead>
          <tbody>
            {SelectedProducts.length > 0 ? (
              SelectedProducts?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      cursor: "pointer",
                      fontWeight: highlightedRow === item?.code ? "bold" : "",
                      background:
                        highlightedRow === item?.code ? "#ecf4ff" : "",
                      marginBottom: "10px",
                    }}
                    // onClick={() => {
                    //   if (modeType !== "view") {
                    //     setHighlightedRow(item?.code);
                    //   } else {
                    //     return;
                    //   }
                    // }}
                    className="stockreciveform__grndetails_body_wrap "
                  >
                    <td className="stockreciveform__detail_table_tableitem stockreciveform__shown_mobile stockreciveform__img_wrap">
                      <img
                        src={ImageUrl(item)}
                        loading="lazy"
                        onError={(e) => {
                          e.target.src = noImage;
                        }}
                        alt=""
                        className="product__table_mainimg"
                        onClick={() => {
                          setfullview(ImageUrl(item) || "");
                        }}
                      />
                    </td>
                    <td className="stockreciveform__detail_table_tableitem font_size  stockreciveform__shown_mobile stockreciveform__name_wrap">
                      {item?.name ?? "-"}
                    </td>
                    <td className="stockreciveform__detail_table_tableitem font_size stockreciveform__shown_mobile stockreciveform__sku_wrap">
                      {item?.code ?? "-"}
                    </td>
                    <td className="stockreciveform__detail_table_tableitem font_size">
                      {item?.length ?? "-"} *{item?.width ?? "-"} *
                      {item?.height ?? "-"}
                    </td>
                    <td className="stockreciveform__detail_table_tableitem font_size">
                      {item?.weight ?? "-"}
                      {item?.weight_unit ?? ""}
                    </td>{" "}
                    <td className="stockreciveform__detail_table_tableitem font_size">
                      {item?.weight * item?.quantity ?? "-"}
                      {item?.weight_unit ?? ""}
                    </td>
                    {user.role != "warehouseUser" && (
                      <>
                        <td className="stockreciveform__detail_table_tableitem font_size">
                          <div>{parseFloat(item?.sale_price ?? 0)}</div>
                        </td>
                        <td className="stockreciveform__detail_table_tableitem font_size">
                          <div>
                            {(
                              parseFloat(item?.sale_price ?? 0) * item?.quantity
                            )?.toFixed(1)}
                          </div>
                        </td>
                      </>
                    )}
                    <td
                      className="stockreciveform__detail_table_tableitem font_size stockreciveform__shown_mobile stockreciveform__qty_wrap"
                      style={{
                        width:
                          modeType === "view" || user.role === "warehouseUser"
                            ? ""
                            : "160px",
                      }}
                    >
                      {modeType === "view" || user.role === "warehouseUser" ? (
                        <div>{item?.quantity}</div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <Tooltips title="Remove Quantity" placement="top">
                            <a
                              type="button"
                              onClick={() => handleSubtract(index)}
                            >
                              <IoIosRemove
                                size={25}
                                style={{
                                  border: "2px solid #E2EDFB",
                                  borderRadius: "25%",
                                  marginRight: "5px",
                                }}
                                className="p-1 overlaybtn"
                              />
                            </a>
                          </Tooltips>

                          <input
                            type="number"
                            value={item?.quantity}
                            placeholder="Quantity"
                            onChange={(e) =>
                              handleQuantityChange(
                                "quantity",
                                e.target.value,
                                index
                              )
                            }
                            className="stock_received_accept_reject_input form-control no_arrows_buttons"
                            style={{
                              width: "50%",
                              borderRightWidth:
                                highlightedRow === item?.code ? "2px" : "",
                            }}
                          />

                          <Tooltips title="Add Quantity" placement="top">
                            <a type="button" onClick={() => handleAdd(index)}>
                              <IoIosAdd
                                size={25}
                                style={{
                                  border: false
                                    ? "1px solid red"
                                    : "2px solid #E2EDFB",
                                  borderRadius: "25%",
                                  marginLeft: "5px",
                                }}
                                className="p-1 overlaybtn"
                              />
                            </a>
                          </Tooltips>
                        </div>
                      )}
                    </td>
                    {/* accept  */}
                    {user.role === "warehouseUser" && modeType !== "view" && (
                      <>
                        {/* <td className="stockreciveform__detail_table_tableitem font_size">
                          {item?.received_quantity ?? 0}
                        </td> */}
                        <td className="stockreciveform__detail_table_tableitem font_size stockreciveform__shown_mobile stockreciveform__accept_wrap">
                          {handelAcceptQuantity(item, index)}
                        </td>

                        <td className="stockreciveform__detail_table_tableitem font_size">
                          {parseInt(item?.quantity ?? 0) -
                            (parseInt(item?.received_quantity ?? 0) +
                              parseInt(item?.new_received_quantity ?? 0))}
                        </td>
                      </>
                    )}
                    {/* reject  */}
                    <td className="stockreciveform__detail_table_tableitem font_size stockreciveform__shown_mobile stockreciveform__status_wrap">
                      <LinearProgressWithLabel
                        received_quantity={
                          parseInt(item?.received_quantity ?? 0) +
                          parseInt(item?.new_received_quantity ?? 0)
                        }
                        total={item?.quantity}
                        style={{ width: "100px" }}
                      />
                    </td>
                    {/* <td className="stockreciveform__detail_table_tableitem font_size">
                      {item?.sale_price ?? "N/A"}
                    </td>
                    <td className="stockreciveform__detail_table_tableitem font_size">
                      {item?.sale_price * item?.quantity} $
                    </td> */}
                    {user.role === "warehouseUser" && (
                      <>
                        {modeType === "view" ? (
                          <td className="stockreciveform__detail_table_tableitem font_size stockreciveform__shown_mobile stockreciveform__w_notes_wrap">
                            {item?.warehouse_notes ?? "-"}
                          </td>
                        ) : (
                          <td className="stockreciveform__detail_table_tableitem font_size stockreciveform__shown_mobile stockreciveform__w_notes_wrap">
                            <input
                              type="text"
                              value={item?.warehouse_notes}
                              name="warehouse_notes"
                              placeholder="Note"
                              onChange={(e) =>
                                handleQuantityChange(
                                  "warehouse_notes",
                                  e.target.value,
                                  index
                                )
                              }
                              className="stock_received_accept_reject_input form-control"
                              style={{
                                width: "70%",
                                borderRightWidth:
                                  highlightedRow === item?.code ? "2px" : "",
                              }}
                            />
                          </td>
                        )}

                        <td className="stockreciveform__detail_table_tableitem font_size stockreciveform__shown_mobile stockreciveform__shipper_wrap">
                          {item?.shipper_notes ?? "-"}
                        </td>
                      </>
                    )}
                    {user.role != "warehouseUser" && (
                      <>
                        {modeType != "add" && (
                          <td className="stockreciveform__detail_table_tableitem font_size">
                            {item?.warehouse_notes ?? "-"}
                          </td>
                        )}
                        <td
                          className="stockreciveform__detail_table_tableitem font_size stockreciveform__shown_mobile"
                          style={{ width: "160px" }}
                        >
                          {modeType === "view" ? (
                            <div>{item?.shipper_notes ?? "-"}</div>
                          ) : (
                            <input
                              type="text"
                              value={item?.shipper_notes}
                              name="shipper_notes"
                              placeholder="Note"
                              onChange={(e) =>
                                handleQuantityChange(
                                  "shipper_notes",
                                  e.target.value,
                                  index
                                )
                              }
                              className="stock_received_accept_reject_input form-control"
                              style={{
                                borderRightWidth:
                                  highlightedRow === item?.code ? "2px" : "",
                              }}
                            />
                          )}
                        </td>
                      </>
                    )}
                    {modeType === "view" && (
                      <th className="stockreciveform__detail_table_tableitem font_size">
                        <div
                          className="flex flex-col items-center text-xxs text-blue-600 cursor-pointer hover:underline"
                          onClick={() => setLabelModal(item)}
                        >
                          <AiOutlineBarcode size={10} />
                          <span>Barcode</span>
                        </div>
                      </th>
                    )}
                    {modeType != "view" && user.role != "warehouseUser" && (
                      <td
                        className="stockreciveform__detail_table_tableitem BsTrash_td"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Tooltips title="Delete Product" placement="top">
                          <a
                            type="button"
                            onClick={() => deleteProduct(index, item)}
                          >
                            <BsTrash
                              size={35}
                              style={{
                                border: "2px solid #E2EDFB",
                                borderRadius: "25%",
                              }}
                              className="p-2 overlaybtn"
                            />
                          </a>
                        </Tooltips>
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan="11"
                  className="stockreciveform__detail_table_tableitem font_size"
                  style={{
                    textAlign: "center",
                    height: "100px",
                    borderTop: "0.5px solid lightgray",
                    marginTop: "20px",
                  }}
                >
                  No Product Selected
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Row style={{ marginTop: "10px" }}>
        <Col md={6}>
          <div
            className="card bg-white shadow"
            style={{ borderRadius: "15px", padding: "15px", height: "190px" }}
          >
            <div
              style={{
                marginBottom: "4px",
                borderBottom:
                  modeType === "view" || user.role === "warehouseUser"
                    ? "0.5px solid lightgray"
                    : "",
              }}
            >
              <b>Additional Details</b>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              {modeType === "view" || user.role === "warehouseUser" ? (
                <div
                  style={{
                    fontSize: "13px",
                    height: "110px",
                    overflow: "auto",
                  }}
                >
                  <b>Note To Warehouse</b>
                  <div>{formData.description ?? "-"}</div>
                </div>
              ) : (
                <TextField
                  size="small"
                  multiline
                  rows={4}
                  maxRows={4}
                  fullWidth
                  id="outlined-basic"
                  label="Note To Warehouse"
                  name="description"
                  value={formData.description}
                  onChange={(e) => handelChange(e)}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    input: { font: "menu" },
                  }}
                />
              )}
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div
            className="card bg-white shadow stockreciveform__cost_summary_card"
            style={{ borderRadius: "15px", padding: "15px", height: "250px" }}
          >
            {/* Product Breakdown */}
            {user.role !== "warehouseUser" && (
              <>
                <div style={{ marginBottom: "4px" }}>
                  <b>Products Details</b>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <div style={{ fontSize: "13px" }}>Total Quantity</div>
                  </div>
                  <div style={{ fontSize: "13px" }}>
                    {" "}
                    {SelectedProducts?.reduce(
                      (accumulator, currentValue) =>
                        Number(accumulator) + Number(currentValue?.quantity),
                      0
                    )}
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <div style={{ fontSize: "13px" }}>Total Value</div>
                  </div>
                  <div style={{ fontSize: "13px" }}>
                    {SelectedProducts?.reduce(
                      (total, product) =>
                        total +
                        (parseInt(product?.quantity, 10) || 0) *
                          (parseFloat(product?.sale_price) || 0),
                      0
                    ).toFixed(1)}
                  </div>
                </div>
              </>
            )}
            {/* Product Breakdown */}

            <div style={{ marginBottom: "4px" }}>
              <b>Cost Summary</b>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div style={{ fontSize: "13px" }}>Taxes Included</div>
              </div>
              <div style={{ fontSize: "13px" }}>N/A</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div style={{ fontSize: "13px" }}>SubTotal</div>
              </div>
              <div style={{ fontSize: "13px" }}>N/A</div>
            </div>
            <div style={{ marginBottom: "4px" }}>
              <b>Cost Adjustment</b>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div style={{ fontSize: "13px" }}>Shipping</div>
              </div>
              <div style={{ fontSize: "13px" }}>N/A</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <b>Total</b>
              </div>
              <div>
                <b> N/A</b>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <LabelModal
        open={labelModal}
        setOpen={setLabelModal}
        labelData={{
          code: labelModal?.code,
          name: labelModal?.name,
          image: labelModal?.media?.find((image) => image?.is_primary)?.file,
        }}
        title="Product's Barcode"
        subtitle={`${labelModal?.full_name ?? ""} (${labelModal?.code ?? ""})`}
      />
    </>
  );
}
