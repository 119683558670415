import React, { useState } from "react";
import "./messages.css";

// components imports
import MessagesTable from "../../../../../Components/BoltTable/BoltTable";
// apis imports
import { fetchUserMessages } from "../../../../../services/admin.services";
// library imports

const Messages = ({ setSnack }) => {
  const [messages, setMessages] = useState([]);

  const columns = [
    {
      id: 0,
      label: "#",
      render: (row) => <>{row?.id}</>,
      width: "200px",
    },
    {
      id: 1,
      label: "Name",
      render: (row) => <>{row?.name}</>,
      width: "200px",
    },
    {
      id: 2,
      label: "Company",
      render: (row) => <>{row?.company ?? "-"}</>,
      width: "200px",
    },
    {
      id: 3,
      label: "Email",
      render: (row) => <>{row?.email ?? "-"}</>,
      width: "200px",
    },
    {
      id: 4,
      label: "Phone",
      render: (row) => <>{row?.phone ?? "-"}</>,
      width: "200px",
    },
    {
      id: 5,
      label: "Message",
      render: (row) => <>{row?.question ?? "-"}</>,
      width: "300px",
    },
    {
      id: 6,
      label: "Created At",
      render: (row) => <>{row?.created_at?.slice(0, 10) ?? "-"}</>,
      width: "300px",
    },
  ];

  const getMessages = async (page, perPage, filter) => {
    const response = await fetchUserMessages(page, perPage, filter);

    if (!response.error) {
      setMessages(response?.data?.result);
    } else {
      setSnack((snack) => {
        return {
          ...snack,
          open: true,
          text: "Something went wrong",
          severity: "error",
        };
      });
    }
  };

  return (
    <>
      <div className="messages_header">
        <div>
          <span className="messages_hdrheading">Messages List</span>
        </div>
      </div>

      <div className="messages__main_wrap">
        <div className="messages__table_list">
          <div>
            <MessagesTable
              message={"No Messages Available To Display!"}
              searchPlaceHolder="Search Messages"
              columns={columns}
              data={messages}
              getAllData={getMessages}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Messages;
