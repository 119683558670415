import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        loading: false,
        isDisabled: false,
        notifications: [],
        theme: 'light',
    },
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setDisabled(state, action) {
            state.isDisabled = action.payload;
        },
        addNotification(state, action) {
            state.notifications.push(action.payload);
        },
        removeNotification(state, action) {
            state.notifications = state.notifications.filter(
                (notification) => notification.id !== action.payload
            );
        },
        setTheme(state, action) {
            state.theme = action.payload;
        },
    },
});

export const {
    setLoading,
    setDisabled,
    addNotification,
    removeNotification,
    setTheme,
} = uiSlice.actions;

export default uiSlice.reducer;
